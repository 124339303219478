import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import i18n from "i18next";
import { Button } from "antd";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { rootReducerType } from "redux/combineReducers";
import useWindowDimensions, { decodeJWT } from "common/functions";
import { useGetPortalNameQuery, useSavePortalNameMutation } from "services/profileService";
import {ReactComponent as CancelButton} from "assets/cancel-button.svg";
import {ReactComponent as CustomiseButton} from "assets/customise-button.svg";
import "./Container.less";
import { emailToUsername } from "common/utils";
import Notification from "../NotificationMessage/Notification";

const sanitizeConf = {
  allowedTags: [],
  allowedAttributes: { a: ["href"] }
};

interface IContainer {
  backButton?: boolean;
  editButton?:boolean;
  actionRight?: boolean;
  renderCondition?: boolean;
  loadingPageName: string;
  subTitle?: React.ReactNode;
  backButtonAction?: () => void;
  editButtonAction?: () => void;
  children: React.ReactNode;
  childrenExpanded?: React.ReactNode;
  expanded?:boolean;
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  actions?: React.ReactNode;
  actionsRight?: React.ReactNode;
  styleNoFlex?: boolean;
}

const Container: FC<IContainer> = ({
  backButton = true,
  editButton = false,
  expanded,
  setExpanded,
  actionRight= false,
  renderCondition = true,
  loadingPageName,
  subTitle,
  backButtonAction,
  children,
  childrenExpanded,
  actions,
  actionsRight,
  styleNoFlex
}) => {

  const {width} = useWindowDimensions();

  const [edit, setEdit] = useState(false);

  const { isLoggedIn } = useSelector(
    (state: rootReducerType) => state.authenticationReducer
  );


  const {data: title} = useGetPortalNameQuery(decodeJWT(isLoggedIn).email);

  const [savePortalName, {isSuccess}] = useSavePortalNameMutation();

  const [tempTitle, setTempTitle] = useState(title);

  const handleChange = (e) => {
    setTempTitle(sanitizeHtml(e.target.value,sanitizeConf));
  };

  useEffect(()=> setTempTitle(title),[title]);

  useEffect(() => {
  if(isSuccess)
    Notification({ type: "success", message: i18n.t("profile.toastTitle"), description: i18n.t("profile.toastPortalSave")});
  },[isSuccess]);

  const BackContainer = () => (
    <div className="container">
      <Button
        icon={<ArrowLeftOutlined />}
        size="middle"
        style={{
          visibility: backButtonAction ? "unset" : "hidden",
          margin: "6px 8px",
          borderRadius: 5,
          backgroundColor:"rgba(0, 0, 0, 0.04)"
        }}
        onClick={backButtonAction}
        className="back-button"
      />
      <div className="container-content">
        <div className="container-header">
          <div className="container-title-section">
            <div className="container-title">
              <ContentEditable
                className="editable"
                tagName="pre"
                html={`<div>${tempTitle}</div>`}
                disabled={!edit}
                onChange={handleChange}
              />
              {editButton && ( <Button
                className="container-edit-button"
                onClick={()=>{
                  if(edit) {
                    savePortalName({userName: emailToUsername(decodeJWT(isLoggedIn).email), portalName: tempTitle});
                  }
                  setEdit(!edit);
                }}
                size="small"
              >
              {edit ? i18n.t("container.save") : i18n.t("container.edit")}
              </Button>
              )}
            </div>
            <div style={{marginLeft: "auto", marginRight: 0}}>{actionsRight}</div>
            <div className="container-actionsContainer">{actions}</div>
          </div>
        </div>
        <div className="container-subTitle">{subTitle}</div>
        { styleNoFlex ? <div className="container-main-section-no-flex">{children}</div> : <div className="container-main-section">{children}</div> }
      </div>
    </div>
  );

  const SimpleContainer = () => (
    <div className="container-without-back-butt">
      {expanded && width>768?(
        <>
          <div style={{width:"80%"}} className="container-content">
            <div className="container-header">
              <div className="container-title-section">
                <div className="container-title">
                  <ContentEditable
                    className="editable"
                    tagName="pre"
                    html={`<div>${tempTitle}</div>`}
                    disabled={!edit}
                    onChange={handleChange}
                  />
                  {editButton && ( <Button
                    className="container-edit-button"
                    onClick={()=>{
                      if(edit) {
                        savePortalName({userName: emailToUsername(decodeJWT(isLoggedIn).email), portalName: tempTitle});
                      }
                      setEdit(!edit);
                    }}
                    size="small"
                  >
                    {edit ? i18n.t("container.save") : i18n.t("container.edit")}
                  </Button>
                  )}
                </div>
                <div className="container-actionsContainer">{setExpanded && (<CancelButton style={{cursor:"pointer", height:"50%"}} onClick={()=>{setExpanded(!expanded);}}/>)}</div>
              </div>
            </div>
            <div className="container-subTitle">{subTitle}</div>
            <div className="container-main-section">{children}</div>
          </div>
          <div style={{width:"19%"}} className="container-expanded">{childrenExpanded}</div>
        </>):
        <div className="container-content">
          <div className="container-header">
            <div className="container-title-section">
              <div className="container-title">
                <ContentEditable
                  className="editable"
                  tagName="pre"
                  html={`<div>${tempTitle}</div>`}
                  disabled={!edit}
                  onChange={handleChange}
                />
                {editButton && ( <Button
                  className="container-edit-button"
                  onClick={()=>{
                    if(edit) {
                      savePortalName({userName: emailToUsername(decodeJWT(isLoggedIn).email), portalName: tempTitle});
                    }
                    setEdit(!edit);
                  }}
                  size="small"
                >
                  {edit ? i18n.t("container.save") : i18n.t("container.edit")}
                </Button>
                )}
              </div>
              <div style={{marginLeft: "auto", marginRight: 0}}>{actionsRight}</div>
              <div className="container-actionsContainer">{setExpanded && (<CustomiseButton style={{cursor:"pointer", height:"50%"}} onClick={()=>{setExpanded(!expanded);}}/>)}</div>
            </div>
            <div className="container-subTitle">{subTitle}</div>
          </div>
          { styleNoFlex ? <div className="container-main-section-no-flex">{children}</div> : <div className="container-main-section">{children}</div> }
        </div>}
    </div>
  );

  return renderCondition ? (
    backButton || actionRight ? BackContainer() : SimpleContainer()) :
    <LoadingScreen pageName={loadingPageName} />;

};

export default Container;
