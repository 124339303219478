import React from "react";
import i18n from "internationalization/i18n";
import {ReactComponent as CSVExport} from "assets/csv-format.svg";
import {ReactComponent as PDFExport} from "assets/pdf-format.svg";
import "./ExportFormat.less";
import useWindowDimensions from "common/functions";

type Props = {
    formatClass: { pdf:string, csv:string }
    setFormatClass: React.Dispatch<React.SetStateAction<{ pdf:string, csv:string }>>
    setSelectedFormat: React.Dispatch<React.SetStateAction<string>>
};

const ExportFormat: React.FC<Props> = ({ formatClass, setFormatClass, setSelectedFormat }) => {

    const { width } = useWindowDimensions();

    const changeFormat = (format:string) => {
        switch(format){
          case "pdf":
            setFormatClass({pdf:"export-format-active", csv:"export-format-inactive"});
            setSelectedFormat(format);
            break;
          case "csv":
            setFormatClass({pdf:"export-format-inactive", csv:"export-format-active"});
            setSelectedFormat(format);
            break;
        }
      };

    const getExportFormatDestkop = () => (
        <div className="export-modal-format">
            <h4>{i18n.t("exportModal.format")}</h4>
            <div className="export-section-container">
                <div className="export-formats-container">
                <div className={formatClass.pdf} onClick={()=>changeFormat("pdf")}>
                    <PDFExport/>
                    <span>{i18n.t("exportModal.pdfFormat")}</span>
                </div>
                <div className={formatClass.csv} onClick={()=>changeFormat("csv")}>
                    <CSVExport/>
                    <span>{i18n.t("exportModal.csvFormat")}</span>
                </div>
                </div>
            </div>
        </div>
    );

    const getExportFormatMobile = () => (
        <div className="export-modal-format">
            <h2>{i18n.t("exportModal.format")}</h2>
            <div className="export-section-container">
                <div className="export-formats-container">
                <div className={formatClass.pdf} onClick={()=>changeFormat("pdf")}>
                    <PDFExport/>
                    <span>{i18n.t("exportModal.pdfFormat")}</span>
                </div>
                <div className={formatClass.csv} onClick={()=>changeFormat("csv")}>
                    <CSVExport/>
                    <span>{i18n.t("exportModal.csvFormat")}</span>
                </div>
                </div>
            </div>
        </div>
    );
  return <>
  { width > 768 ? getExportFormatDestkop() : getExportFormatMobile() }
  </>;
};

export default ExportFormat;