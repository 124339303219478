
import { IMarker } from "redux/utils/interfaces";
import { reduxActionType, stationsReducerType } from "redux/utils/types";

const initialState = {
	stations: [] as IMarker[],
};

export const stationsReducer = (
	state = initialState,
	action: reduxActionType
): stationsReducerType => {
	switch (action.type) {
	case "SET_MAP_DATA": {
		return {
			...action.payload,			
		};
	}
	default:
		return state;
	}
};
