import { Table } from "antd";
import { computeRowsAndCols } from "pages/stations/utils/functions";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ComparisonResponse } from "services/utils/interfaces";

type Props = {
    data: ComparisonResponse
};

const CompareTable:React.FC<Props> = ({data}):React.ReactElement => {
	const navigate=useNavigate();

	const {columns,rows} = computeRowsAndCols(data,navigate);

	return (
		<div style={{height:"100%",width:"90vw"}}>
			<Table columns={columns} dataSource={rows} size='small' scroll={{ x: 500 }} pagination={false} bordered/>
		</div>
	);
};

export default CompareTable;