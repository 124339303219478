import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { emailToUsername } from "common/utils";
import Pool from "../userPool";
import { RootState } from "../redux/store";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const awsAuthentication = async (email: string, Password: string) =>
	await new Promise((resolve, reject) => {
		const user = new AmazonCognitoIdentity.CognitoUser({
			Username: emailToUsername(email),
			Pool
		});

		const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
			Username: emailToUsername(email),
			Password
		});

		user.authenticateUser(authDetails, {
			onSuccess: data => {
				resolve(data);
			},
			onFailure: err => {
				reject(err);
			},
			newPasswordRequired: data => {
				resolve(data);
			}
		});
	});

export const forgotPassword = async (email: string) =>
	await new Promise((resolve, reject) => {
		const user = new AmazonCognitoIdentity.CognitoUser({
			Username: emailToUsername(email),
			Pool
		});
		user.forgotPassword({
			onSuccess: data => {
				resolve(data);
			},
			onFailure: err => {
				reject(err);
			}
		});
	});

export const confirmPassword = async (email: string, code: string, password: string) =>
	await new Promise((resolve, reject) => {
		const user = new AmazonCognitoIdentity.CognitoUser({
			Username: emailToUsername(email),
			Pool
		});

		user.confirmPassword(code, password, {
			onSuccess: data => {
				resolve(data);
			},
			onFailure: err => {
				reject(err);
			}
		}, { verificationCode: code });
	});

export const signUp = async (email: string, code: string, password: string) =>
	await new Promise((resolve, reject) => {

		const attributeList: Array<AmazonCognitoIdentity.CognitoUserAttribute> = [];

		const dataEmail = {
			Name: "email",
			Value: email
		};

		const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

		attributeList.push(attributeEmail);

		Pool.signUp(emailToUsername(email), password, attributeList, [] as AmazonCognitoIdentity.CognitoUserAttribute[], function (
			err,
			result
		) {
			if (err) {
				reject(err);
			}
			resolve(result);
		}, { verificationCode: code });
	});

export const verifyEmail = async (email: string, code: string) =>
	await new Promise((resolve, reject) => {
		const userData = {
			Username: emailToUsername(email),
			Pool: Pool
		};

		const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
		cognitoUser.confirmRegistration(code, true, function (err, result) {
			if (err) {
				reject(err);
			}
			resolve(result);
		});
	});

export const getSession = async () =>
	await new Promise((resolve, reject) => {
		const user = Pool.getCurrentUser();
		if (user) {
			user.getSession(async (err: Error | null, session: AmazonCognitoIdentity.CognitoUserSession | null) => {
				if (err) {
					reject();
				} else {

					resolve({
						user,
						...session
					});
				}
			});
		} else {
			reject();
		}
	});

export const logout = () => {
	const user = Pool.getCurrentUser();
	if (user) {
		user.signOut();
	}
};

export const tokenRefresh = async (api: BaseQueryApi) =>
	await new Promise((resolve, reject) => {
		const user = Pool.getCurrentUser();
		if (user) {
			const userData = {
				Username: emailToUsername((api.getState() as RootState).authenticationReducer.email),
				Pool: Pool
			};

			const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
			cognitoUser.getSession(async (err: Error | null, session: AmazonCognitoIdentity.CognitoUserSession | null) => {
				if (err) {
					reject(err);
				} else {
					const token = session?.getRefreshToken();
					if (token) {
						Pool.getCurrentUser()?.refreshSession(token, (err, session) => {
							if (err) {
								reject(err);
							} else {
								resolve({ data: session.getIdToken().getJwtToken() });
							}
						});
					} else {
						reject("Token not found");
					}
				}
			});
		} else {
			reject("User not found");
		}
	});

export const changePassword = async (email: string, currentPassword: string, newPassword: string) =>
	await new Promise((resolve, reject) => {
		const user = Pool.getCurrentUser();
		if (user) {

			const user = new AmazonCognitoIdentity.CognitoUser({
				Username: emailToUsername(email),
				Pool
			});

			const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
				Username: emailToUsername(email),
				Password: currentPassword
			});

			user.authenticateUser(authDetails, {
				onSuccess: async () => {
					user.changePassword(currentPassword, newPassword, (err, result) => {
						if (err) {
							reject(err);
						} else {
							resolve({ result });
						}
					});
				},

				onFailure: err => {
					reject(err);
				}

			});

		} else {
			reject();
		}
	});
