/* eslint-disable @typescript-eslint/no-explicit-any */
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Form, Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { awsAuthentication } from "services/authenticationService";
import FloatInput from "common/components/FloatingInput";
import {
	errorMessages,
	warningMessages,
	initialErrorState,
} from "../../utils/constants";
import { newError, renderAlert } from "../../utils/functions";
import "./Login.less";
import { setLogin } from "redux/authentication/actions";
import { useTranslation } from "react-i18next";
import { updateFormType } from "common/functions";

const LogIn: React.FC = () => {

	const { t } = useTranslation();
	const [errorState, setErrorState] = useState(initialErrorState);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<>
			<Form
				size="large"
				name="user_login"
				className="login-form"
				layout="vertical"
				onFinish={(values) => {
					awsAuthentication(values.email, values.password)
						.then((result) => {
							if (result instanceof CognitoUserSession) {
								dispatch(
									setLogin({
										isLoggedIn: result.getIdToken().getJwtToken(),
										email: values.email,
									})
								);
								navigate("dashboard");
							} else {
								newError(errorMessages.InvalidCredentials, setErrorState);
							}
						})
						.catch(() =>
							newError(errorMessages.InvalidCredentials, setErrorState)
						);
				}}
				onFinishFailed={(values) => {
					if (values.errorFields.length > 1) {
						newError(warningMessages.LoginFields, setErrorState);
					} else if (values.errorFields[0].name[0] === "email") {
						newError(warningMessages.NoEmail, setErrorState);
					} else if (values.errorFields[0].name[0] === "password") {
						newError(warningMessages.NoPassword, setErrorState);
					}
				}}
			>
				{errorState.error_action === undefined ? renderAlert(errorState) : null}

				<Form.Item name="email" rules={[{ type: "email", message: "" }, { required: true, message: "" }]}>
					<FloatInput label="E-mail" placeholder={t("logIn.email")} name="email" hasFeeback />
				</Form.Item>
				<Form.Item name="password" rules={[{ required: true, message: "" }]}>
					<FloatInput
						label="Password"
						placeholder={t("logIn.password")}
						name="password"
						passField={true}
					></FloatInput>
				</Form.Item>
				<div className="login-trouble" onClick={() => { updateFormType("send-reset-password"); }}>
					{t("logIn.troubleLoggingIn")}
				</div>
				<Button type="primary" id="login" htmlType="submit">
					{t("logIn.logIn")}
				</Button>
			</Form>
		</>
	);
};

export default LogIn;
