import { stationsReducer } from "./stations/reducer";
import storageSession from "redux-persist/lib/storage/session";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { authenticationReducer } from "./authentication/reducer";
import { profileApi } from "services/profileService";
import { dashboardApi } from "services/dashboardService";
import { stationsApi } from "../services/stationsService";
import { chargersApi } from "../services/chargersService";
import { supportApi } from "../services/supportService";
import { exportApi } from "services/exportService";
import { transactionsApi } from "services/transactionsService";

const persistConfig = {
	key: "root",
	storage: storageSession,
	whitelist: [
		"authenticationReducer",
		"stationsReducer",
		profileApi.reducerPath,
		dashboardApi.reducerPath,
		stationsApi.reducerPath,
		supportApi.reducerPath,
		chargersApi.reducerPath,
		exportApi.reducerPath,
		transactionsApi.reducerPath,
	],
};

const rootReducer = combineReducers({
	authenticationReducer,
	stationsReducer,
	[profileApi.reducerPath]: profileApi.reducer,
	[dashboardApi.reducerPath]: dashboardApi.reducer,
	[stationsApi.reducerPath]: stationsApi.reducer,
	[chargersApi.reducerPath]: chargersApi.reducer,
	[supportApi.reducerPath]: supportApi.reducer,
	[exportApi.reducerPath]: exportApi.reducer,
	[transactionsApi.reducerPath]: transactionsApi.reducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export type rootReducerType = ReturnType<typeof rootReducer>;
