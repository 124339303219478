import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "assets/logo.png";
import { setCurrentFormStep } from "redux/authentication/actions";
import "./Logo.less";

const Logo: React.FC = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const logoClick = () => {
		dispatch(
			setCurrentFormStep( {
				current_form: "sign-in"
			})
		);
		navigate("/");
	};

	return (
		<section className="logo-container" onClick={() => logoClick()}>
			<img className='logo-swift' src={logo} alt={t("loadingScreen.logoNotFound")} />
		</section>
	);
};

export default Logo;
