import { createApi } from "@reduxjs/toolkit/query/react";
import { cacheDurationSeconds } from "common/constants";
import { baseQueryWithReauth } from "./utils/constants";
import { ExportParams, ExportReponse } from "./utils/interfaces";

const getGenericURLParams = (args: ExportParams) =>
	`exportType=${args.exportType}&format=${args.format}&startDate=${args.startDate}&endDate=${args.endDate}&exportItem=${args.exportItem}`;

export const exportApi = createApi({
	reducerPath: "export",
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: cacheDurationSeconds,
	endpoints: (builder) => ({
		getExportFile: builder.query<ExportReponse, ExportParams>({
			query: (args) => `export-data?${getGenericURLParams(args)}`,
		}),
	}),
});

export const {
	useGetExportFileQuery
} =
	exportApi;
