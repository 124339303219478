/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "services/authenticationService";
import FloatInput from "common/components/FloatingInput";
import { warningMessages, initialErrorState } from "../../utils/constants";
import { newError, renderAlert } from "../../utils/functions";
import { updateFormType } from "common/functions";
import "./SendResetPassword.less";

const SendResetPassword: React.FC = () => {
	const { t } = useTranslation();
	const [errorState, setErrorState] = useState(initialErrorState);

	return (
		<Form
			size="large"
			name="user_reset_pass"
			className="reset-pass-form"
			layout="vertical"
			onFinish={(values) => {
				forgotPassword(values["reset-email"])
					.then()
					.catch((err) => newError({ error_type: "warning", error_message: err }, setErrorState));
				updateFormType("sign-in");
			}}
			onFinishFailed={() => {
				newError(warningMessages.NoEmail, setErrorState);
			}}
		>
			{errorState.error_action === undefined ? renderAlert(errorState) : null}
			<Form.Item
				name="reset-email"
				rules={[{ type: "email", message: "" }, { required: true, message: "" }]}
			>
				<FloatInput label="E-mail" placeholder={t("resetPassword.email")} name="email" />
			</Form.Item>
			<div className="reset-pass-cust-service">
				{t("resetPassword.forgotEmail")}
				<a href="mailto:info@egridsys.com">{t("resetPassword.contactService")}</a>
			</div>

			<Button type="primary" id="submit" htmlType="submit">
				{t("resetPassword.submit")}
			</Button>
			<Button type="default" id="sign-in" onClick={() => updateFormType("sign-in")}>
				{t("resetPassword.signIn")}
			</Button>
		</Form>
	);
};

export default SendResetPassword;
