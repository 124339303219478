import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StationsList from "./components/stationList/StationsList";
import Container from "common/components/Container/Container";
import i18n from "internationalization/i18n";
import StationMap from "./components/stationMap/StationMap";
import { stationsApi, useGetStationsQuery } from "services/stationsService";
import { decodeJWT } from "common/functions";
import { rootReducerType } from "redux/combineReducers";
import { setMapData } from "redux/stations/actions";
import { stationsGeoLocation } from "./utils/functions";
import "./Stations.less";
import { emailToUsername } from "common/utils";

type Props = {
  shouldRefresh: boolean;
};

const Stations: React.FC<Props> = ({ shouldRefresh }): React.ReactElement => {
	const dispatch = useDispatch();

	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const params = {userName: emailToUsername(decodeJWT(isLoggedIn).email)};

	const { data} = useGetStationsQuery(params);

	const { stations } = useSelector(
		(state: rootReducerType) => state.stationsReducer
	);

	useEffect(() => {
		if (data) dispatch(setMapData(stationsGeoLocation(data)));
	}, [data, dispatch]);

	useEffect(() => {
		dispatch(
			stationsApi.endpoints.getStations.initiate(
				params,
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [shouldRefresh]);

	return (
		<Container
			backButton={false}
			loadingPageName="Stations"
			subTitle={i18n.t("dashboard.subtitle")}
			renderCondition={true}
		>
			<section className="stations-container">
				{stations?
					<>
						<StationsList data={stations} />
						<StationMap data={stations} />
					</>
					:null}

			</section>
		</Container>
	);
};

export default Stations;
