import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps } from "antd";
import i18n from "internationalization/i18n";
import GrayButton from "common/components/StyledButtons/GrayButton/GrayButton";
import { useGetStationsQuery } from "services/stationsService";
import { rootReducerType } from "redux/combineReducers";
import { emailToUsername } from "common/utils";
import useWindowDimensions, { decodeJWT } from "common/functions";
import ExportType from "./ExportType/ExportType";
import ExportStations from "./ExportStations/ExportStations";
import ExportPeriod from "./ExportPeriod/ExportPeriod";
import ExportFormat from "./ExportFormat/ExportFormat";
import DarkBlueButton from "common/components/StyledButtons/DarkBlueButton/DarkBlueButton";
import { exportApi, useGetExportFileQuery } from "services/exportService";
import ExportSuccess from "./ExportSuccess/ExportSuccess";
import "./ExportModal.less";
import moment from "moment";
import { OneWeekInMilliseconds } from "common/constants";
import Notification from "../NotificationMessage/Notification";

const { Step } = Steps;

type Props = {
	closeModal: React.Dispatch<React.SetStateAction<boolean>>
};

const ExportModal: React.FC<Props> = ({ closeModal }) => {

	const dispatch = useDispatch();

	const { width } = useWindowDimensions();
	const [skip, setSkip] = useState<boolean>(true);

	const [currentStep, setCurrentStep] = useState<number>(0);

	const [exportTypeSelected, setExportTypeSelected] = useState<string>("");
	const [exportTypeClass, setExportTypeClass] = useState({ all: "radio-container", station: "radio-container" });

	const [isAvailableStationsVisible, setIsAvailableStationsVisible] = useState<boolean>(false);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [selectedStation, setSelectedStation] = useState<string>("");
	const [selectedStationClass, setSelectedStationClass] = useState<number>(0);

	const [exportDate, setExportDate] = useState({
		startDate: moment.utc(
			new Date(Date.now() - OneWeekInMilliseconds)
				.toISOString()
				.slice(0, 10),
			"YYYY-MM-DD"
		).format(),
		endDate: moment.utc(new Date()).format()
	});

	const [selectedFormat, setSelectedFormat] = useState<string>("");
	const [formatClass, setFormatClass] = useState({ pdf: "export-format-inactive", csv: "export-format-inactive" });

	const [downloadURL, setDownloadURL] = useState<string>("");

	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const params = { userName: emailToUsername(decodeJWT(isLoggedIn).email) };

	const { data: stations } = useGetStationsQuery(params);

	const { data: exportData } = useGetExportFileQuery({
		exportType: exportTypeSelected,
		format: selectedFormat,
		startDate: exportDate.startDate,
		endDate: exportDate.endDate,
		exportItem: exportTypeSelected === "all" ? "" : selectedStation,
	}, { skip });

	useEffect(() => {
		const close = (e) => {
			if (e.key === "Escape") {
				closeModal(false);
			}
		};
		window.addEventListener("keydown", close);
		return () => window.removeEventListener("keydown", close);
	}, []);

	const changeExportType = (type: string) => {
		switch (type) {
			case "all":
				setExportTypeClass({ all: "radio-container-selected", station: "radio-container" });
				setExportTypeSelected("allStations");
				setIsAvailableStationsVisible(false);
				break;
			case "station":
				setExportTypeClass({ all: "radio-container", station: "radio-container-selected" });
				setExportTypeSelected(type);
				setIsAvailableStationsVisible(true);
				break;
		}
	};

	useEffect(() => {
		if (exportTypeSelected && selectedFormat && exportDate.startDate && exportDate.endDate && !skip) {
			setSkip(false);
			dispatch(
				exportApi.endpoints.getExportFile.initiate(
					{
						exportType: exportTypeSelected,
						format: selectedFormat,
						startDate: exportDate.startDate,
						endDate: exportDate.endDate,
						exportItem: exportTypeSelected === "all" ? "" : selectedStation,
					},
					{ subscribe: false, forceRefetch: true }
				)
			);
		}
	}, [skip]);

	useEffect(() => {
		if (typeof exportData?.success === undefined || !exportData) return;

		if (exportData?.success) {
			setDownloadURL(exportData?.url);
		} else {
			setSkip(true)
			Notification({
				type: "warning",
				message: i18n.t("charger.warningModalTitle"),
				description: exportData?.message || i18n.t("exportModal.failTitle")
			});
		}
	}, [exportData]);

	const generateExport = () => {
		setSkip(false);
	};

	const prevStep = () => {
		return currentStep !== 0 ? false : true;
	};

	const nextStep = () => {
		return isAvailableStationsVisible ? currentStep !== 3 ? false : true : currentStep !== 2 ? false : true;
	};

	const SubmitButton = () => {
		if (isAvailableStationsVisible) {
			if (exportTypeSelected && selectedStation && exportDate.endDate && exportDate.startDate && selectedFormat)
				return <DarkBlueButton name={i18n.t("exportModal.export")} onClick={() => generateExport()} />;
		}
		else {
			if (exportTypeSelected && exportDate && exportDate.endDate && exportDate.startDate && selectedFormat)
				return <DarkBlueButton name={i18n.t("exportModal.export")} onClick={() => generateExport()} />;
		}
		return <GrayButton name={i18n.t("exportModal.export")} disabled={true} />;
	};

	const getDesktopExport = () => (
		<div className="export-modal-desktop">
			<div className="export-modal-close" >
				<span>ESC key to exit</span>
				<span onClick={() => closeModal(false)}>X</span>
			</div>
			<section className="export-modal-container">
				{downloadURL ? <ExportSuccess closeModal={closeModal} exportLink={downloadURL} format={selectedFormat} /> :
					<>
						<section className="export-modal-title-section">
							<div className="export-title">{i18n.t("exportModal.title")}</div>
							<div className="export-subtitle">{i18n.t("exportModal.subtitle")}</div>
						</section>
						<section className="export-modal-content">
							<ExportType changeExportType={changeExportType} exportTypeClass={exportTypeClass} />
							{isAvailableStationsVisible ? <ExportStations data={stations} setSelectedStation={setSelectedStation} setSearchQuery={setSearchQuery} searchQuery={searchQuery} selectedClass={selectedStationClass} setSelectedClass={setSelectedStationClass} /> : null}
							<ExportPeriod setExportDate={setExportDate} />
							<ExportFormat setFormatClass={setFormatClass} formatClass={formatClass} setSelectedFormat={setSelectedFormat} />
						</section>
						<section className="export-modal-actions">
							<SubmitButton />
						</section>
					</>
				}
			</section>
		</div>
	);

	const getMobileExport = () => (
		<>
			<div className="export-modal-close" >
				<span onClick={() => closeModal(false)}>X</span>
			</div>
			<div className="export-modal-mobile">
				{downloadURL ? <ExportSuccess closeModal={closeModal} exportLink={downloadURL} format={selectedFormat} /> :
					<>
						<div className="export-modal-container">
							<div className="export-modal-content">
								{<>
									{(() => {
										if (isAvailableStationsVisible)
											switch (currentStep) {
												case 0: return <ExportType changeExportType={changeExportType} exportTypeClass={exportTypeClass} />;
												case 1: return <ExportStations data={stations} setSelectedStation={setSelectedStation} setSearchQuery={setSearchQuery} searchQuery={searchQuery} selectedClass={selectedStationClass} setSelectedClass={setSelectedStationClass} />;
												case 2: return <ExportPeriod setExportDate={setExportDate} />;
												case 3: return <ExportFormat setFormatClass={setFormatClass} formatClass={formatClass} setSelectedFormat={setSelectedFormat} />;
												default: return <></>;
											}
										else switch (currentStep) {
											case 0: return <ExportType changeExportType={changeExportType} exportTypeClass={exportTypeClass} />;
											case 1: return <ExportPeriod setExportDate={setExportDate} />;
											case 2: return <ExportFormat setFormatClass={setFormatClass} formatClass={formatClass} setSelectedFormat={setSelectedFormat} />;
											default: return <></>;
										}
									})()}
								</>
								}
							</div>
							<div className="export-modal-stepper">
								<Steps direction="vertical" size="small" current={currentStep}>
									<Step title={i18n.t("exportModal.exportType")} onClick={() => setCurrentStep(0)} />
									{isAvailableStationsVisible ? <Step title={i18n.t("exportModal.availableStations")} onClick={() => setCurrentStep(1)} /> : null}
									<Step title={i18n.t("exportModal.period")} onClick={() => isAvailableStationsVisible ? setCurrentStep(2) : setCurrentStep(1)} />
									<Step title={i18n.t("exportModal.format")} onClick={() => isAvailableStationsVisible ? setCurrentStep(3) : setCurrentStep(2)} />
								</Steps>
							</div>
						</div>
						<div className="export-modal-actions">
							<GrayButton name={i18n.t("exportModal.previousStep")} disabled={prevStep()} onClick={() => currentStep !== 0 ? setCurrentStep(currStep => currStep - 1) : null} />
							<SubmitButton />
							<GrayButton name={i18n.t("exportModal.nextStep")} disabled={nextStep()} onClick={() => isAvailableStationsVisible ? currentStep !== 3 ? setCurrentStep(currStep => currStep + 1) : null : currentStep !== 2 ? setCurrentStep(currentStep + 1) : null} />
						</div>
					</>}
			</div>
		</>
	);

	return (
		width > 767 ? getDesktopExport() : getMobileExport()
	);
};

export default ExportModal;
