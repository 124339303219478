/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import { formatData } from "./utils/functions";
import { PowerResponse } from "services/utils/interfaces";
import { powerResult } from "./utils/types";
import { useDispatch } from "react-redux";
import { stationsApi, useGetPowerForStationQuery } from "services/stationsService";
import { emailToUsername } from "common/utils";
import { dashboardApi, useGetPowerDetailsQuery } from "services/dashboardService";

type Props = {
  params: {
    userName: any;
    startDate: string;
    endDate: string;
    locationId?: string;
  };
  shouldRefresh?: boolean;
};

const Power = ({ params, shouldRefresh }: Props) => {
	const [data, setData] = useState<any[]>([]);
	const dispatch = useDispatch();

	const { locationId } = params;

	const powerDashboard = useGetPowerDetailsQuery(params, { skip: locationId ? true : false }).data as PowerResponse;
	const powerStation = useGetPowerForStationQuery(
		{ ...params, userName: emailToUsername(params.userName), locationId: locationId || "" },
		{ skip: locationId ? false : true }).data as PowerResponse;

	useEffect(() => {
		if (locationId) {
			if (powerStation) setData(formatData(powerStation, "power") as powerResult[]);
		}
		else {
			if (powerDashboard) setData(formatData(powerDashboard, "power") as powerResult[]);
		}
	}, [ powerDashboard, powerStation, locationId ]);

	useEffect(() => {
			if(locationId)
				dispatch(
					stationsApi.endpoints.getPowerForStation.initiate(
						{
							userName: params.userName,
							locationId: locationId || "",
							startDate: params.startDate,
							endDate: params.endDate
						},
						{ subscribe: false, forceRefetch: true }
				)
				);
			else dispatch(
					dashboardApi.endpoints.getPowerDetails.initiate(
						{
							userName: params.userName,
							startDate: params.startDate,
							endDate: params.endDate
						},
						{ subscribe: false, forceRefetch: true}
					)
			);
	}, [shouldRefresh, dispatch, params, locationId]);

	return (
		<Line
			{...{
				data: data,
				xField: "time",
				yField: "power",
			}}
		/>
	);
};

export default Power;
