import React from "react";
import "./TabNotifications.less";
import {ReactComponent as HourglassSVG} from "assets/coming-soon.svg";
import i18n from "../../../../internationalization/i18n";
import "./TabNotifications.less";

const TabNotifications = () => {
	return (
		<>
		<div className="tab-notifications">
			<div className="message">
				{i18n.t("notifications.comingSoon")}
			</div>
			<HourglassSVG style={{opacity: "0.7", height: "80%", margin: "auto"}} />
		</div>
		</>
	);
};

export default TabNotifications;
