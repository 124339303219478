import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { persistedReducer } from "./combineReducers";
import { persistStore } from "redux-persist";
import { rtkQueryErrorLogger } from "./errorLogger";
import { stationsApi } from "../services/stationsService";
import { profileApi } from "../services/profileService";
import { dashboardApi } from "../services/dashboardService";
import { chargersApi } from "../services/chargersService";
import { exportApi } from "services/exportService";
import { transactionsApi } from "../services/transactionsService";

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false })
			.concat([
				dashboardApi.middleware,
				chargersApi.middleware,
				transactionsApi.middleware,
				profileApi.middleware,
				stationsApi.middleware,
				exportApi.middleware,
				rtkQueryErrorLogger
			]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
