import React from "react";
import SendResetPassword from "./forms/SendResetPassword/SendResetPassword";
import { formTypes } from "./utils/constants";
import LogIn from "./forms/LogIn/LogIn";
import { useSelector } from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import AuthContainer from "./components/AuthContainer/AuthContainer";

const Authentication: React.FC = () => {
	const { current_form } = useSelector((state: rootReducerType) => state.authenticationReducer);

	const renderForm = () => {
		switch (formTypes[current_form].type) {
			case "sign-in":
				return <LogIn />;
			case "send-reset-password":
				return <SendResetPassword />;
			default:
				return null;
		}
	};

	return (
		formTypes[current_form]
			? <AuthContainer
				title={formTypes[current_form].title}
				subtitle={formTypes[current_form].subtitle}
			>
				{renderForm()}
			</AuthContainer>
			: null

	);
};

export default Authentication;
