/* eslint-disable @typescript-eslint/no-explicit-any */
import { RootState } from "redux/store";
import { logout, tokenRefresh } from "../authenticationService";

import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { setLogin } from "../../redux/authentication/actions";

export const baseQueryWithAuth = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).authenticationReducer.isLoggedIn;
		if (token) {
			headers.set("Authorization", token);
		}
		headers.set("Accept", "application/json");
		headers.set("Accept-Encoding", "gzip, deflate, br");
		headers.set("Content-Type", "application/json");
		const host = process.env.REACT_APP_BASE_URL;
		if (host) {
			headers.set("Host", host.substring(8));
		}
		return headers;
	},
});

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	let result = await baseQueryWithAuth(args, api, extraOptions);

	if (result.error && result.error.status === "FETCH_ERROR") {

		const refreshResult = await doRefresh(api);
		if (refreshResult.error || !refreshResult.data) {
			api.dispatch(logout());
		} else {
			api.dispatch(
				setLogin({
					isLoggedIn: refreshResult.data,
					email: (api.getState() as RootState).authenticationReducer.email
				})
			);
			result = await baseQueryWithAuth(args, api, extraOptions);
		}
	}
	return result;
};


// eslint-disable-next-line @typescript-eslint/ban-types
let refreshCallStack: Array<[Function, Function]> = [], isRequestInProgress = false;

function doRefresh(api: BaseQueryApi): Promise<any> {
	if (isRequestInProgress) {
		return new Promise((resolve, reject) => refreshCallStack.push([resolve, reject]));
	}
	isRequestInProgress = true;

	return tokenRefresh(api)
		.then(data => {
			refreshCallStack.forEach(([resolve]) => resolve(data));
			return Promise.resolve(data);
		})
		.catch(err => {
			refreshCallStack.forEach(([reject]) => reject(err));
			return Promise.reject(err);
		})
		.finally(() => {
			isRequestInProgress = false;
			refreshCallStack = [];
		});
}
