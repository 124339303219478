import React,{ useRef } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { IMarker } from "redux/utils/interfaces";
import { useSize } from "react-hook-size";
import { useMap } from "./hooks/useMap";
import Pin from "../mapPin/Pin";
import "./Map.less";

const MAP_CONFIG = {
	maxZoom: 20,
	mapStyle: "mapbox://styles/mapbox/streets-v11",
	mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
	zoomEnabled: true,
};

type Props = {
  markers: IMarker[];
};

const Map = ({ markers }: Props) => {
	const mapRef = useRef(null);
	const mapContainerRef = useRef(null);

	const { width, height } = useSize(mapContainerRef);

	const { viewport, onViewportChange } = useMap({
		width: width || 400,
		height: height || 400,
		markers,
	});

	return (
		<div ref={mapContainerRef} className="map">
			<ReactMapGL
				ref={mapRef}
				{...MAP_CONFIG}
				{...viewport}
				onViewportChange={onViewportChange}
			>
				{markers && markers.length
					? markers.map(({ id, color, ...marker }) => {
						return (
							<Marker key={id} {...marker} offsetLeft={-17.5} offsetTop={-38}>
								<Pin color={color} id={id} />
							</Marker>
						);
					})
					: null}
			</ReactMapGL>
		</div>
	);
};

export default Map;
