import React, { useState } from "react";
import { Table } from "antd";
import { computeRowsAndCols } from "../../../pages/chargers/utils/functions";
import { useNavigate } from "react-router-dom";
import { Chargers } from "pages/chargers/Chargers";

interface ITable {
	data: Chargers
	selectedFilter?: string;
	setNewSort: (value: string) => void;
	shouldReset: (value: boolean) => void;
	reset: boolean;
	offset: number;
	setOffset: (value: number) => void;
	limit: number;
	length: number;
}

const ChargersTable: React.FC<ITable> = ({
	offset,
	setOffset,
	data,
	setNewSort,
	shouldReset,
	reset,
	length,
	limit
}) => {

	const navigate = useNavigate();

	const [isAsc, setIsAsc] = useState({ sessions: false, energyInKWH: false, revenue: false });
	const [isDesc, setIsDesc] = useState({ sessions: false, energyInKWH: false, revenue: false });

	const { columns, rows } = computeRowsAndCols(data,
		navigate,
		(value) => setNewSort(value),
		shouldReset,
		reset,
		isAsc,
		isDesc,
		(value) => setIsAsc(value),
		(value) => setIsDesc(value)
	);

	return (
		<Table columns={columns} dataSource={rows} size='small'
			scroll={{ x: 500 }}
			pagination={{
				total: length,
				current: offset + 1,
				onChange: (page) => setOffset(page - 1),
				defaultPageSize: Number(limit),
				showSizeChanger: false
			}}
			bordered />
	);
};

export default ChargersTable;
