import React, {useState} from "react";
import "./TabPasswordAndSecurity.less";
import {Alert, Form} from "antd";
import {newError, renderAlert} from "../../../authentication/utils/functions";
import { initialErrorState, warningMessages } from "../../../authentication/utils/constants";
import FloatInput from "../../../../common/components/FloatingInput";
import i18n from "../../../../internationalization/i18n";
import DarkBlueButton
	from "../../../../common/components/StyledButtons/DarkBlueButton/DarkBlueButton";
import {useSelector} from "react-redux";
import {rootReducerType} from "../../../../redux/combineReducers";
import { changePassword } from "services/authenticationService";
import {decodeJWT} from "../../../../common/functions";
import { passRegex } from "common/constants";

const TabPasswordAndSecurity = () => {

	const {isLoggedIn} = useSelector((state: rootReducerType) => state.authenticationReducer);
	const [errorState, setErrorState] = useState(initialErrorState);
	const [saved, setSaved] = useState(false);

	return (
		<section className="reset-password">
			<Form
				size="large"
				name="reset_password"
				className="reset-password-form"
				layout="vertical"
				onFinish={(values) => {
					if (values.newPassword !== values.confirmPassword) {
						newError(warningMessages.PasswordsNoMatch, setErrorState);
					}
					else if(!passRegex.test(values.newPassword)) {
						newError(warningMessages.PasswordFormatIncorect, setErrorState);
					}
					else {
						changePassword(decodeJWT(isLoggedIn).email, values.oldPassword, values.newPassword)
							.then(() => {
								setSaved(true);
							})
							.catch(() => newError(warningMessages.OldPasswordIncorrect, setErrorState));
					}
				}}
				onFinishFailed={(values) => {
					if (values.errorFields.length > 1) {
						newError(warningMessages.ResetPasswordFields, setErrorState);
					} else if (values.errorFields[0].name[0] === "oldPassword") {
						newError(warningMessages.NoOldPassword, setErrorState);
					} else if (values.errorFields[0].name[0] === "newPassword") {
						newError(warningMessages.NoNewPassword, setErrorState);
					} else if (values.errorFields[0].name[0] === "confirmPassword") {
						newError(warningMessages.NoConfirmationPassword, setErrorState);
					}
				}}
			>
				<div className="reset-password-main-section">
					<span className="reset-password-container" />
					<div className="reset-password-form-items">
						{errorState.error_action === undefined && !saved ? renderAlert(errorState) : null}
						{saved ?
							<Alert
								message={i18n.t("passwordSecurity.passwordChanged")}
								type="success"
								style={{
									marginBottom: "2vh",
									height: "fit-content",
									borderRadius: "5px",
									fontWeight: "500",
								}}
							/> : null}
						<Form.Item
							name="oldPassword"
							rules={[
								{
									required: true,
									message: "",
								},
							]}
						>
							<FloatInput
								label={i18n.t("passwordSecurity.oldPassword")}
								placeholder={i18n.t("passwordSecurity.oldPassword")}
								name="oldPassword"
								passField
							/>
						</Form.Item>
						<Form.Item
							name="newPassword"
							rules={[
								{
									required: true,
									message: "",
								},
							]}
						>
							<FloatInput
								label={i18n.t("passwordSecurity.newPassword")}
								placeholder={i18n.t("passwordSecurity.newPassword")}
								name="newPassword"
								passField
							/>
						</Form.Item>

						<Form.Item
							name="confirmPassword"
							rules={[
								{
									required: true,
									message: "",
								},
							]}
						>
							<FloatInput
								label={i18n.t("passwordSecurity.confirmPassword")}
								placeholder={i18n.t("passwordSecurity.confirmPassword")}
								name="confirmPassword"
								passField
							/>
						</Form.Item>
						<div className="pass-rules">{ i18n.t("constants.passFormatHint") }</div>
						<DarkBlueButton name={i18n.t("passwordSecurity.resetPassword")} htmlType="submit" style={{margin: "auto"}}/>
					</div>
					<span className='reset-password-container' />
				</div>
			</Form>
		</section>
	);
};

export default TabPasswordAndSecurity;
