import React from "react";
import { Card } from "antd";
import "./InfoCard.less";

const { Meta } = Card;

type Props = {
	style?: {
		size?: "default" | "small";
		hoverable?: boolean;
		width?: string;
		height?: string;
		bordered?: boolean;
		borderRadius?: string;
	};

	title?: string;
	description: string;
	loading?: boolean;
	simpleCard?: boolean;
};

const InfoCard: React.FC<Props> = ({
	title,
	description,
	loading,
	style,
	simpleCard
}) => {
	return (
		simpleCard
		? <Card
			style={{ height: "100%", width: "100%" }}
			bodyStyle={{ padding: "12px" }}
			{...style}
			loading={loading}
			className="antd-custom-card"
		>{description}</Card> :
			<Card bodyStyle={{ padding: "12px" }} {...style} loading={loading} className="antd-custom-card">
				<Meta title={title} description={description} />
			</Card>

	);
};

export default InfoCard;
