import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./styles/App.less";
import { routes } from "common/routes";
import { rootReducerType } from "./redux/combineReducers";
import Header from "./common/components/Header/Header";
import "./styles/App.less";
import { useSelector } from "react-redux";
import "@fontsource/montserrat";

function App() {
	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const [refresh, setRefresh] = useState(false);

	const [tabProfile, setTabProfile] = useState<string>("1");

	return (
		<div className="app-container">
			<Routes>
				{routes
					.filter((route) => !route?.disabled)
					.map((route, index) =>
						route.private && isLoggedIn ? (
							<Route
								key={index}
								path={route.path}
								element={
									<>
										<Header
											setRefreshButton={() => setRefresh(!refresh)}
											shouldRefresh={refresh}
											setTabProfile={setTabProfile}
										/>
										<route.element
											shouldRefresh={refresh}
											tabProfile={tabProfile}
											setTabProfile={setTabProfile}
										/>
									</>
								}
							/>
						) : route.private && !isLoggedIn ? (
							<Navigate to="/" />
						) : (
							<Route
								key={index}
								path={route.path}
								element={<route.element />}
							/>
						)
					)}
			</Routes>
		</div>
	);
}

export default App;
