/* eslint-disable @typescript-eslint/no-explicit-any */
import { List, Popover } from "antd";
import React, { useState } from "react";
import "./SubHeader.less";

interface ISubHeader {
	items: any[];
	icon: any
	setTabIndex?: (key: string[]) => void 
}

const SubHeader: React.FC<ISubHeader> = ({ items, icon, setTabIndex }): React.ReactElement => {
	const [openPopover, setOpenPopover] = useState(false);
	const Icon = icon;

	const getPathname = () => window.location.pathname
		.split("/")
		.filter(path => path)[0].trim();

	const getList = (items: any[]) => {
		return <List
			dataSource={items}
			renderItem={item => (
				<List.Item
					onClick={() => {
						item.triggerClick();
						if (setTabIndex) {
							setTabIndex([item.key]);
						}
						setOpenPopover(false);
					}}
					className={item.name.toLowerCase() === getPathname() ? "active-item" : undefined}
				>
					{item.name} {item?.icon}
				</List.Item>
			)}
		/>;
	};

	return (
		<Popover
			visible={openPopover}
			onVisibleChange={setOpenPopover}
			content={getList(items)}
			trigger='click'
			className='subHeader'
			overlayClassName='popover-container'
		>
			<Icon />
		</Popover>
	);
};

export default SubHeader;