/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button } from "antd";
import { confirmPassword } from "services/authenticationService";
import FloatInput from "common/components/FloatingInput";
import {
	errorMessages,
	warningMessages,
	initialErrorState,
	formTypes,
} from "../../utils/constants";
import { newError, renderAlert } from "../../utils/functions";
import { setForm } from "redux/authentication/actions";
import { rootReducerType } from "redux/combineReducers";
import { useTranslation } from "react-i18next";
import "./ResetPassword.less";
import { passRegex } from "common/constants";
import i18n from "internationalization/i18n";
import AuthContainer from "pages/authentication/components/AuthContainer/AuthContainer";
import { updateFormType } from "common/functions";

const ResetPassword: React.FC = () => {
	const { t } = useTranslation();
	const [errorState, setErrorState] = useState(initialErrorState);
	const { email, otp } = useSelector((state: rootReducerType) => state.authenticationReducer);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<AuthContainer
			title={t("passwordSecurity.resetPassword")}
			subtitle={t("constants.verificationCodeAndEmail")}
		>
		<Form
			size="large"
			name="reset-password-form"
			className="reset-password-form"
			layout="vertical"
			onFinish={(values) => {
				if (!passRegex.test(values["password"])) {
					return newError(
						warningMessages.PasswordFormatIncorect,
						setErrorState,
					);
				}
				else confirmPassword(email, otp, values["password"])
					.then(() => {
						dispatch(
							setForm({
								new_password: values["password"],
							})
						);
						navigate("/sign-in");
					})
					.catch((error) => {
						switch (error.code) {
							case "CodeMismatchException": {
								return newError(
									warningMessages.CodeMismatchException,
									setErrorState,
									"Try again"
								);
							}
							case "ExpiredCodeException":
								return newError(
									errorMessages.ExpiredCodeException,
									setErrorState,
									"Generate Code"
								);
							case "InternalErrorException":
								return newError(
									errorMessages.InternalErrorException,
									setErrorState
								);
							case "LimitExceededException":
								return newError(
									errorMessages.LimitExceededException,
									setErrorState
								);
							case "UserLambdaValidationException":
								return newError(
									errorMessages.LambdaValidation,
									setErrorState,
									"Try again"
								);
							case "UsernameExistsException":
								return newError(
									errorMessages.UsernameExists,
									setErrorState,
									"Try again"
								);
						}
					});
			}}
			onFinishFailed={(values) => {
				if (values.errorFields.length > 1) {
					newError(warningMessages.VerifyFields, setErrorState);
				} else if (values.errorFields[0].name[0] === "email") {
					newError(warningMessages.NoEmail, setErrorState);
				} else if (values.errorFields[0].name[0] === "verif-code") {
					newError(warningMessages.NoVerifCode, setErrorState);
				} else if (
					values.values["password"] === undefined &&
					values.values["confirm-password"] === undefined
				) {
					newError(warningMessages.ConfirmPassFields, setErrorState);
				} else if (values.values["password"] === "") {
					newError(warningMessages.NoPassword, setErrorState);
				} else if (values.values["confirm-password"] === "") {
					newError(warningMessages.NoConfirmPass, setErrorState);
				} else if (
					values.values["password"] !== values.values["confirm-password"]
				) {
					newError(warningMessages.DiffPass, setErrorState);
				}
			}}
		>
			{errorState.error_action === "Try again"
				? renderAlert(errorState, formTypes["reset-password"], updateFormType)
				: errorState.error_action === "Generate Code"
					? renderAlert(errorState, formTypes["reset-password"], updateFormType)
					: renderAlert(errorState)}
			<Form.Item name="email" rules={[{ type: "email", message: "" }, { required: true, message: "" }]}>
				<FloatInput
					label={t("E-mail")}
					placeholder={t("E-mail")}
					name="email"
					onChange={(event) => dispatch(setForm({ email: event.target.value }))}
				/>
			</Form.Item>
			<Form.Item name="access_code" rules={[{ required: true, message: "" }]}>
				<FloatInput
					label="Access code"
					placeholder={t("verifyEmail.accessCode")}
					name="access_code"
					onChange={(event) => dispatch(setForm({ otp: event.target.value }))}
				/>
			</Form.Item>
			<Form.Item name="password" rules={[{ required: true, message: "" }]}>
				<FloatInput
					label={t("passwordSecurity.newPassword")}
					placeholder={t("passwordSecurity.newPassword")}
					name="password"
					passField={true}
				/>
			</Form.Item>
			<Form.Item
				name="confirm-password"
				rules={[
					{ required: true, message: "" },
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value || getFieldValue("password") === value) {
								return Promise.resolve();
							}
							return Promise.reject();
						},
					}),
				]}
			>
				<FloatInput
					label={t("passwordSecurity.confirmPassword")}
					placeholder={t("passwordSecurity.confirmPassword")}
					name="confirm_password"
					passField={true}
				/>
			</Form.Item>
			<div className="pass-rules">{i18n.t("constants.passFormatHint")}</div>
			<Button type="primary" id="reset-password" htmlType="submit">
				{t("passwordSecurity.resetPassword")}
			</Button>
		</Form>
		</AuthContainer>
	);
};

export default ResetPassword;
