import React, { useState } from "react";
import { Modal, Select } from "antd";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import i18n from "i18next";

const { Option } = Select;

type SelectType = {
	option: string,
};

interface ISelect {
	items: SelectType[];
	selectedFilter?: string;
	selectedFilterValue?: string;
	setNewFilter: (value: string) => void;
	setNewFilterValue: (value: string) => void;
	statusData: string[];
	stationData: string[];
	typeData: string[];
	reset: boolean;
	setReset: (value: boolean) => void;
}

const SelectFilter: React.FC<ISelect> = ({
	selectedFilter,
	selectedFilterValue,
	items,
	setNewFilter,
	setNewFilterValue,
	statusData,
	stationData,
	typeData,
	reset,
	setReset
}) => {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [data, setData] = useState<string[]>([]);

	const [selectedItem, setSelectedItem] = useState<string>(selectedFilterValue || "");
	const [filterValue, setFilterValue] = useState<string>(selectedFilter || "");

	const [value, setValue] = useState<string | null>(null);

	function handleSelect(value: string) {
		setData(() => value === i18n.t("chargers.status") && statusData.length > 0
			? statusData
			: (value === i18n.t("chargers.station")
				? stationData
				: (value === i18n.t("chargers.type")
					? typeData
					: []
				)));
		setValue(value);
		showModal();
		setReset(false);
	}

	const showModal = () => { setIsModalVisible(true); };

	const handleOk = () => {
		setNewFilter(value  || "");
		setNewFilterValue(filterValue);
		setIsModalVisible(false);
	};

	const handleCheck = (event: RadioChangeEvent) => {
		const filterValue = event.target.value;

		setSelectedItem(filterValue);
		setFilterValue(filterValue);
	};

	const handleCancel = () => {
		setFilterValue(selectedFilter || "");
		setSelectedItem(selectedFilterValue || "");
		setIsModalVisible(false);
	};

	const getSelectItems = () => items.map((item, index) =>
		<Option
			value={item.option}
			key={index}
			onClick={showModal}
		>
			{item.option}
		</Option>
	);

	return (
		<>
			<Select
				suffixIcon={<CaretDownOutlined />}
				onSelect={handleSelect}
				bordered={false}
				defaultValue={i18n.t("chargers.filterBy")}
				autoClearSearchValue={true}
				value={reset || !value ? i18n.t("chargers.filterBy") : value}
			>
				{getSelectItems()}
			</Select>
			<Modal title={value} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
				<Radio.Group onChange={handleCheck} value={selectedItem}>
					{data ? data.map((option, index) => {
						return (
							<Radio
								key={index}
								style={{ display: "flex", marginBottom: 8 }}
								value={option}
							>
								{option}
							</Radio>
						);
					})
						: null
					}
				</Radio.Group>
			</Modal>
		</>
	);
};

export default SelectFilter;
