/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { notification } from "antd";

type Props = {
  type: string;
  message: string;
  description: string;
};

notification.config({
	maxCount: 1
});

const Notification = ({ type, message, description }: Props) => {
	notification[type]({ message, description, placement: "top" });
};

export default Notification;
