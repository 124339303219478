import React, { useEffect, useState } from "react";
import Container from "common/components/Container/Container";
import i18n from "internationalization/i18n";
import { useDispatch, useSelector } from "react-redux";
import { rootReducerType } from "../../redux/combineReducers";
import useWindowDimensions, { counterCommonItemsArrays, decodeJWT, getDataToDisplay } from "../../common/functions";
import { emailToUsername, getUpperCase } from "../../common/utils";
import {
	transactionsApi,
	useGetTransactionsQuery
} from "../../services/transactionsService";
import { Button, DatePicker, Space } from "antd";
import SelectFilter from "../../common/components/Select/Select";
import { useGetStationsQuery } from "../../services/stationsService";
import TransactionsTable
	from "../../common/components/TransactionsTable/TransactionsTable";
import moment from "moment";
import { formatISO8601 } from "common/constants";
import { TransactionItem } from "services/utils/interfaces";

const { RangePicker } = DatePicker;

type Props = {
	shouldRefresh: boolean,
};

type FilterDates = {
	startTime?: string,
	endTime?: string,
};

export type Transactions = Array<TransactionItem>;

const Transactions: React.FC<Props> = ({ shouldRefresh }): React.ReactElement => {

	const { isLoggedIn } = useSelector((state: rootReducerType) => state.authenticationReducer);

	const dispatch = useDispatch();

	const getFilterBy = (filter: string | null): string | undefined =>
		filter && [i18n.t("transactions.station"), i18n.t("transactions.type")].includes(filter)
			? filter
			: undefined;

	const getFilterQueryString = (key: string | null, filterValue: string | null) =>
		key && filterValue
			? key?.toLowerCase() + "_is_" + filterValue
			: undefined;

	const getKey = (filterBy?: string): string | null =>
		filterBy
			? (filterBy === i18n.t("transactions.station") ? "stationId"
				: (filterBy === i18n.t("transactions.type")
					? "type"
					: null))
			: null;

	const getDates = (startTime: string | null, endTime: string | null): FilterDates => {
		let startDate: string | undefined;
		let endDate: string | undefined;
		if (startTime && endTime) {
			startDate = moment(startTime).startOf("day").format();
			endDate = moment(endTime).endOf("day").format();
		}
		return { startTime: startDate, endTime: endDate };
	};

	const [limit] = useState(10);
	const [offset, setOffset] = useState<number>(0);
	const [previousOffset, setPreviousOffset] = useState<number>(offset);

	const [startTime, setStartTime] = useState<string | null>(null);
	const [endTime, setEndTime] = useState<string | null>(null);
	const [sort, setSort] = useState<string | null>(null);
	const [filter, setFilter] = useState<string | null>(null);
	const [filterValue, setFilterValue] = useState<string | null>(null);
	const [reset, setReset] = useState(false);
	const [dateRange, setDateRange] = useState<Array<moment.Moment> | undefined>(undefined);
	const [isOpenRangePicker, setIsOpenRangePicker] = useState<boolean>(false);

	const [transactions, setTransactions] = useState<Transactions>([]);

	const selectItems = [{ option: i18n.t("transactions.station") }, { option: i18n.t("transactions.type") }];
	const typeData = [i18n.t("transactions.level2"), i18n.t("transactions.dcfc")];
	const stations = useGetStationsQuery({
		userName: emailToUsername(decodeJWT(isLoggedIn).email)
	}).data;
	const stationData = stations ? Object.keys(stations) : [];

	const { data, refetch } = useGetTransactionsQuery({
		userName: emailToUsername(decodeJWT(isLoggedIn).email),
		limit: String(limit),
		offset: String(offset < 0 ? 0 : offset),
		sortBy: sort ? sort : undefined,
		filterBy: getFilterQueryString(getKey(getFilterBy(filter)), filterValue),
		...getDates(startTime, endTime)
	});
	const { width } = useWindowDimensions();
	const dateFormat = "MMM D, YYYY";

	const setRange = (values) => {
		setDateRange(values);
	};

	useEffect(() => {
		if (offset < previousOffset) return;
		if (offset === previousOffset) {
			const tempOffset = offset < 0 ? 0 : offset;
			setOffset(tempOffset);
			setPreviousOffset(tempOffset);
			const filterBy = getFilterBy(filter);
			const key = getKey(filterBy);
			dispatch(
				transactionsApi.endpoints.getTransactions.initiate(
					{
						userName: emailToUsername(decodeJWT(isLoggedIn).email),
						limit: String(limit),
						offset: String(tempOffset),
						sortBy: sort ? sort : undefined,
						filterBy: getFilterQueryString(key, filterValue),
						...getDates(startTime, endTime)
					},
					{ subscribe: false, forceRefetch: true }
				)
			);
		}

		setReset(false);
	}, [offset]);

	useEffect(() => {
		const tempOffset = offset <= 0 ? offset - 1 : 0;
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);
	}, [startTime, endTime, filterValue, sort]);

	useEffect(() => {
		if (!data?.items || reset) return;

		const tempOffset = offset < 0 ? 0 : offset;
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);

		const counterCommonItems = counterCommonItemsArrays(data.items, transactions);

		const newItems = JSON.parse(JSON.stringify(
			offset <= 0
				? [...data.items]
				: (counterCommonItems === 0
					? [...transactions, ...data.items]
					: [...transactions]
				)));
		setTransactions(newItems);
		setPreviousOffset(offset);
	}, [JSON.stringify(data?.items), offset, reset]);

	useEffect(() => {
		if (reset) {
			setReset(false);
			refetch();
		}
	}, [refetch, reset]);

	useEffect(() => {
		refetch();
	}, [shouldRefresh]);

	useEffect(() => {
		if (!isOpenRangePicker) {
			setStartTime(dateRange ? moment(dateRange?.[0])?.format(dateFormat) : null);
			setEndTime(dateRange ? moment(dateRange?.[1])?.format(dateFormat) : null);
		}
	}, [isOpenRangePicker]);

	const handleReset = () => {
		const tempOffset = offset <= 0 ? offset - 1 : 0;
		setReset(true);
		setFilter(null);
		setFilterValue(null);
		setSort(null);
		setEndTime(null);
		setStartTime(null);
		setDateRange(undefined);
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);
	};

	return (width > 768 ?
		(<Container
			backButton={false}
			loadingPageName={i18n.t("transactions.pageName")}
			subTitle={i18n.t("transactions.subtitle")}
			renderCondition={data ? true : false}
			actionsRight={
				<>
					<Space style={{ marginTop: "16px" }} direction="vertical" size={12}>
						<RangePicker
							onChange={setRange}
							format={dateFormat}
							open={isOpenRangePicker}
							value={reset || !dateRange ? undefined : [dateRange && moment(dateRange[0]), dateRange && moment(dateRange[1])]}
							onOpenChange={setIsOpenRangePicker}
						/>
					</Space>
					<SelectFilter
						selectedFilter={filter || undefined}
						selectedFilterValue={filterValue || undefined}
						setNewFilter={(value) => setFilter(value)}
						setNewFilterValue={(value) => setFilterValue(value)}
						items={selectItems}
						statusData={[]}
						typeData={typeData}
						stationData={stationData}
						reset={reset}
						setReset={setReset}
					/>
					<Button onClick={handleReset} className="dark-button">{i18n.t("transactions.resetFilters")}</Button>
				</>
			}
		>
			{data ?
				(<div style={{ height: "100%", width: "100%" }} className="table">
					<TransactionsTable
						limit={limit}
						offset={offset < 0 ? 0 : offset}
						setOffset={setOffset}
						length={transactions.length + (transactions.length < limit ? 0 : 1)}
						data={getDataToDisplay(transactions, limit, offset)}
						setNewSort={(value) => setSort(value)}
						shouldReset={(value) => setReset(value)}
						reset={reset}
					/>
				</div>) :
				null}
		</Container>) : (<Container
			backButton={false}
			loadingPageName={i18n.t("transactions.pageName")}
			subTitle={i18n.t("transactions.subtitle")}
			renderCondition={data ? true : false}
			styleNoFlex={true}
		>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Button onClick={handleReset} className="dark-button">Reset filters</Button>
				<div style={{ marginTop: "auto", marginBottom: "auto" }}>
					<SelectFilter
						selectedFilter={filter || undefined}
						selectedFilterValue={filterValue || undefined}
						setNewFilter={(value) => setFilter(value)}
						setNewFilterValue={(value) => setFilterValue(value)}
						items={selectItems}
						statusData={[]}
						typeData={typeData}
						stationData={stationData}
						reset={reset}
						setReset={setReset}
					/>
				</div>
			</div>
			<Space style={{ marginBottom: "16px" }} direction="vertical" size={12}>
				<RangePicker onChange={setRange} format={dateFormat} value={reset || !dateRange ? undefined : [dateRange && moment(dateRange[0]), dateRange && moment(dateRange[1])]} />
			</Space>

			{data ?
				(<div style={{ height: "100%", width: "100%" }} className="table">
					<TransactionsTable
						limit={limit}
						offset={offset < 0 ? 0 : offset}
						setOffset={setOffset}
						length={transactions.length + (data?.items.length < limit ? 0 : 1)}
						data={getDataToDisplay(transactions, limit, offset)}
						setNewSort={(value) => setSort(value)}
						shouldReset={(value) => setReset(value)}
						reset={reset}
					/>
				</div>) :
				null}
		</Container>)
	);
};

export default Transactions;
