import { errorMessages, initialErrorState, warningMessages } from "pages/authentication/utils/constants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "common/components/Logo/Logo";
import i18n from "internationalization/i18n";
import { Form, Button } from "antd";
import { newError, renderAlert } from "../../utils/functions";
import FloatInput from "common/components/FloatingInput";
import "./VerifyEmail.less";
import { verifyEmail } from "services/authenticationService";
import { useNavigate } from "react-router-dom";
import { setCurrentFormStep } from "redux/authentication/actions";
import { useDispatch } from "react-redux";

type Props = {
	updateFormType: React.Dispatch<React.SetStateAction<{ current_form: string }>>;
};

const VerifyEmail: React.FC<Props> = () => {

	const { t } = useTranslation();
	const [errorState, setErrorState] = useState(initialErrorState);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const buttonNavigateTo = (form: string) => {
		dispatch(setCurrentFormStep({ current_form: form }));
		navigate("/");
	};

	return (
		<section className="verify-email-container">
			<img alt="logo" src={require("../../../../assets/login-image.png")} />
			<Logo />

			<section className="verify-email-details-container">

				<section className="verify-email-details">
					<div className="title">{i18n.t("verifyEmail.formTitle")}</div>
					<div className="subtitle">{i18n.t("verifyEmail.formSubtitle")}</div>
					<Form
						size="large"
						name="verify-email"
						className="verify-email-form"
						layout="vertical"
						onFinish={(values) => {
							verifyEmail(values.email, values.verification_code)
								.then(() => navigate("/success"))
								.catch((err) => {
									switch (err.code) {
										case "ExpiredCodeException": {
											return newError(errorMessages.ExpiredCode, setErrorState);
										}
										case "CodeMismatchException": {
											return newError(errorMessages.InvalidCode, setErrorState);
										}
									}
								});
						}
						}
						onFinishFailed={(values) => {
							if (values.errorFields.length > 1) {
								newError(warningMessages.VerificationFields, setErrorState);
							} else if (values.errorFields[0].name[0] === "email") {
								newError(warningMessages.NoEmail, setErrorState);
							} else if (values.errorFields[0].name[0] === "verification_code") {
								newError(warningMessages.NoVerifCode, setErrorState);
							}
						}}
					>
						{errorState.error_action === undefined ? renderAlert(errorState) : null}

						<Form.Item
							name="email"
							rules={[{ type: "email", message: "" }, { required: true, message: "", },]}
						>
							<FloatInput
								label="E-mail"
								placeholder={t("verifyEmail.email")}
								name="email"
								hasFeeback
							/>
						</Form.Item>
						<Form.Item
							name="verification_code"
							rules={[{ required: true, message: "", },]}
						>
							<FloatInput
								label="Verification Code"
								placeholder={t("verifyEmail.verificationCode")}
								name="verification_code"
							></FloatInput>
						</Form.Item>
						<Button type="primary" id="verify-email" htmlType="submit">
							{t("verifyEmail.verifyAccount")}
						</Button>
						<Button type="default" id="verif-login" onClick={() => buttonNavigateTo("sign-in")}>
							{t("verifyEmail.signIn")}
						</Button>
					</Form>
					<div className="credits">© 2024 By Swift Charge Inc.</div>
				</section>
			</section>
		</section>
	);
};

export default VerifyEmail;
