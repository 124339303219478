import i18n from "internationalization/i18n";
import moment from "moment";

export const tabPanelsProfile = [
	{ name: i18n.t("profile.profileTab"), key: "1" },
	{ name: i18n.t("profile.notificationTab"), key: "2" },
	{ name: i18n.t("profile.passSecurityTab"), key: "3" },
];

export const chartTabPanelsDetails = [
	{
		name: i18n.t("detailsCharts.transactions"),
		key: "1",
	},
	{
		name: i18n.t("detailsCharts.utilization"),
		key: "2",
	},
	{
		name: i18n.t("detailsCharts.power"),
		key: "3",
	}
];

const defaultLegend: {
	layout: "horizontal";
	position: "bottom";
} = {
	layout: "horizontal",
	position: "bottom",
};

export const transEnergyExtraConfig = {
	yAxis: {
		"energy kWh": {
			label: {
				style: {
					fill: "#6395F9"
				}
			}
		},
		"transactions": {
			label: {
				style: {
					fill: "#5AD8A6"
				}
			}
		}
	},
	meta: {
		transactions: {
			range: [0.1, 1]
		}
	},
};

export const chartConfig = {
	"dual-axis": {
		"column-line": {
			legend: defaultLegend,
			data: [[], []],
			xField: "",
			yField: "",
			geometryOptions: [
				{
					geometry: "column",
				},
				{
					geometry: "line",
					lineStyle: {
						lineWidth: 2,
					},
				},
			],
		},
	},
	"vertical-bar": {
		legend: defaultLegend,
		data: [],
		xField: "time",
		yField: "sessions",
		columnWidthRatio: 0.6,
		yAxis: {
			label: {
				style: {
					fill: "#6395F9",
				}
			}
		}
	},
};

export const OneWeekInMilliseconds = 6 * 24 * 60 * 60 * 1000;

export const defaultDate = [
	moment(
		new Date(Date.now() - OneWeekInMilliseconds),
		moment.defaultFormatUtc
	),
	moment(new Date(), moment.defaultFormatUtc),
];


export const formatISO8601 = "YYYY-MM-DD[T]hh:mm:ssZ";

//Regex for password with at least 8 characters, 1 lower, 1 upper, 1 number and 1 special character
export const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^w\s])[^\s]{8,}$/;

export const cacheDurationSeconds = 60 * 5;
