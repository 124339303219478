import React from "react";
import {
	ChargerInfoResponse
} from "services/utils/interfaces";
import "./InfoSection.less";
import { Tag, Collapse } from "antd";
import useWindowDimensions from "../../../../common/functions";
import i18n from "i18next";

type Props = {
	data: ChargerInfoResponse,
	shouldRefresh: boolean,
	chargerId: string,
	status: string
};

const { Panel } = Collapse;

const InfoSection:React.FC<Props> = ({
	data,
	status
}):React.ReactElement => {

	const getStatus = () => {
		let color = "#CECECE";
		if (status === "Fault") {
			color = "#FF7866";
		} else if (status === "Available") {
			color = "#9DCC78";
		} else if (status === "Unavailable") {
			color = "#FED077";
		} else if (status === "In use") {
			color = "#E088FF";
		}
		return <Tag className="tag" color={color} key={status}>{status.toUpperCase()}
			</Tag>;
	};

	const {width} = useWindowDimensions();
	const dataUpdated = {};
	Object.keys(data).forEach(key => {
		if (key === "vendor") {
			dataUpdated[i18n.t("charger.vendor")] = data[key];
		} else if (key === "maxPower") {
			dataUpdated[i18n.t("charger.maxPower")] = data[key] + " " + data["maxPowerUnit"];
		} else if (key === "serialNum") {
			dataUpdated[i18n.t("charger.serialNum")] = data[key];
		} else if (key === "model") {
			dataUpdated[i18n.t("charger.model")] = data[key];
		} else if (key === "Type") {
			dataUpdated[i18n.t("charger.Type")] = data[key];
		} else if (key === "lastHeartBeat") {
			dataUpdated[i18n.t("charger.lastHeartBeat")] = data[key];
		}
	});

	return (
		<>
			{
				width > 768 && data ? (
					<>
						<section className="charger-info-header">
							<div className="charger-Id">
								{data.chargerId}
							</div>
							<div className="charger-status">
								{getStatus()}
							</div>
						</section>

						<section className="get-charger-info-content">
							{Object.keys(dataUpdated).map(key => {
									return (<section key={key} className="info-card">
										<div className="key">
											{key}
										</div>
										<div className="property">
											{dataUpdated[key]}
										</div>
									</section>);
							})}
						</section>
					</>
				) : (data ?  (
					<>
						<section className="charger-info-header">
							<div className="charger-Id">
								{data.chargerId}
							</div>
							<div className="charger-status">
								{getStatus()}
							</div>
						</section>

						<Collapse defaultActiveKey={["1"]} >
							{Object.keys(dataUpdated).map(key => {
								return (
									<Panel header={key} key={key}>
										<p>{dataUpdated[key]}</p>
									</Panel>
							);
							})}
						</Collapse>
					</>
				) : null)
			}
		</>
	);
};

export default InfoSection;
