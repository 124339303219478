/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import Container from "common/components/Container/Container";
import i18n from "internationalization/i18n";
import { useGetChargersQuery } from "services/chargersService";
import { useDispatch, useSelector } from "react-redux";
import { rootReducerType } from "../../redux/combineReducers";
import useWindowDimensions, { counterCommonItemsArrays, decodeJWT, getDataToDisplay } from "../../common/functions";
import { Button } from "antd";
import "./Chargers.less";
import SelectFilter from "../../common/components/Select/Select";
import ChargersTable from "../../common/components/ChargersTable/ChargersTable";
import { chargersApi } from "../../services/chargersService";
import { useGetStationsQuery } from "../../services/stationsService";
import { emailToUsername, getUpperCase } from "../../common/utils";
import { ChargerItem } from "services/utils/interfaces";

type Props = {
	shouldRefresh: boolean,
};

export type Chargers = Array<ChargerItem>;

const Chargers: React.FC<Props> = ({ shouldRefresh }): React.ReactElement => {

	const getFilterBy = (): string | undefined =>
		filter && [i18n.t("chargers.station"), i18n.t("chargers.type"), i18n.t("chargers.status")].includes(filter) ? filter : undefined;

	const getFilterQueryString = (
		key: string | null,
		filterBy: string | undefined,
		filterValue: string | null
	) =>
		filterBy && filterValue
			? key?.toLowerCase() + "_is_" + filterValue
			: undefined;

	const getKey = (filterBy?: string): string | null =>
		filterBy
			? (filterBy === "Station" ? "stationId" : filterBy.toLowerCase())
			: null;

	const getSortBy = (sort: string | null): string | undefined => sort ? sort : undefined;

	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const [limit] = useState(10);
	const [offset, setOffset] = useState<number>(0);
	const [previousOffset, setPreviousOffset] = useState<number>(offset);

	const [chargers, setChargers] = useState<Chargers>([]);

	const statusData = [
		i18n.t("chargers.available"),
		i18n.t("chargers.inUse"),
		i18n.t("chargers.unavailable"),
		i18n.t("chargers.offline"),
		i18n.t("chargers.faulted")
	];
	const typeData = [i18n.t("chargers.level2"), i18n.t("chargers.dcfc")];
	const stations = useGetStationsQuery({
		userName: emailToUsername(decodeJWT(isLoggedIn).email)
	}).data;
	const stationData = stations ? Object.keys(stations) : [];

	const dispatch = useDispatch();

	const [filter, setFilter] = useState<string | null>(null);
	const [filterValue, setFilterValue] = useState<string | null>(null);
	const [sort, setSort] = useState<string | null>(null);
	const [reset, setReset] = useState(false);

	const { data, refetch } = useGetChargersQuery({
		userName: emailToUsername(decodeJWT(isLoggedIn).email),
		limit: String(limit),
		offset: String(offset < 0 ? 0 : offset),
		sortBy: getSortBy(sort),
		filterBy: getFilterQueryString(getKey(getFilterBy()), getFilterBy(), filterValue),
	});

	useEffect(() => {
		if (offset < previousOffset) return;
		if (offset === previousOffset) {
			const tempOffset = offset < 0 ? 0 : offset;
			dispatch(
				chargersApi.endpoints.getChargers.initiate(
					{
						userName: emailToUsername(decodeJWT(isLoggedIn).email),
						limit: String(limit),
						offset: String(tempOffset),
						sortBy: getSortBy(sort),
						filterBy: getFilterQueryString(getKey(getFilterBy()), getFilterBy(), filterValue),
					},
					{ subscribe: false, forceRefetch: true }
				)
			);
		}
		setReset(false);
	}, [offset]);

	useEffect(() => {
		const tempOffset = offset <= 0 ? offset - 1 : 0;
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);
	}, [filterValue, sort]);

	useEffect(() => {
		if (!data?.items || reset) return;
		const tempOffset = offset < 0 ? 0 : offset;
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);

		const counterCommonItems = counterCommonItemsArrays(data.items, chargers);
		const newItems = JSON.parse(JSON.stringify(
			offset <= 0
				? [...data.items]
				: (counterCommonItems === 0
					? [...chargers, ...data.items]
					: [...chargers]
				)));
		setChargers(newItems);
		setPreviousOffset(offset);
	}, [JSON.stringify(data?.items), offset, reset]);

	useEffect(() => {
		if (reset) {
			setReset(false);
			refetch();
		}
	}, [refetch, reset]);

	useEffect(() => {
		refetch();
	}, [shouldRefresh]);

	const selectItems = [{ option: "Station" }, { option: "Type" }, { option: "Status" }];

	const handleReset = () => {
		const tempOffset = offset <= 0 ? offset - 1 : 0;
		setReset(true);
		setFilter(null);
		setFilterValue(null);
		setSort(null);
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);
	};

	const { width } = useWindowDimensions();

	return (
		width > 768 ? (
			<Container
				backButton={false}
				loadingPageName={i18n.t("chargers.pageName")}
				subTitle={i18n.t("chargers.subtitle")}
				renderCondition={data ? true : false}
				actionsRight={<>
					<SelectFilter
						selectedFilter={filter || undefined}
						selectedFilterValue={filterValue || undefined}
						setNewFilter={(value) => setFilter(value)}
						setNewFilterValue={(value) => setFilterValue(value)}
						items={selectItems}
						statusData={statusData}
						typeData={typeData}
						stationData={stationData}
						reset={reset}
						setReset={setReset}
					/>
					<Button onClick={handleReset} className="dark-button">Reset filters</Button>
				</>}
			>
				{data ?
					(<div style={{ height: "100%", width: "100%" }} className="table">
						<ChargersTable
							limit={limit}
							offset={offset < 0 ? 0 : offset}
							setOffset={setOffset}
							data={getDataToDisplay(chargers, limit, offset)}
							setNewSort={(value) => setSort(value)}
							shouldReset={(value) => setReset(value)}
							reset={reset}
							length={chargers.length + (chargers.length < limit ? 0 : 1)}
						/>
					</div>) :
					null}
			</Container>
		) : (
			<div style={{ paddingLeft: "5px", display: "block" }}>
				<Container
					backButton={false}
					loadingPageName={i18n.t("chargers.pageName")}
					subTitle={i18n.t("chargers.subtitle")}
					renderCondition={data ? true : false}
				>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<Button onClick={handleReset} className="dark-button">Reset filters</Button>
						<div style={{ marginTop: "auto", marginBottom: "auto" }}>
							<SelectFilter
								selectedFilter={filter || undefined}
								selectedFilterValue={filterValue || undefined}
								setNewFilter={(value) => setFilter(value)}
								setNewFilterValue={(value) => setFilterValue(value)}
								items={selectItems}
								statusData={statusData}
								typeData={typeData}
								stationData={stationData}
								reset={reset}
								setReset={setReset}
							/>
						</div>
					</div>
				</Container>
				{data ?
					(
						<div style={{ height: "100%", width: "90vw", marginTop: "-10px" }}>
							<ChargersTable
								limit={limit}
								offset={offset < 0 ? 0 : offset}
								setOffset={setOffset}
								data={getDataToDisplay(chargers, limit, offset)}
								setNewSort={(value) => setSort(value)}
								shouldReset={(value) => setReset(value)}
								reset={reset}
								length={chargers.length + (chargers.length < limit ? 0 : 1)}
							/>
						</div>) :
					null}
			</div>)

	);
};

export default Chargers;
