import useWindowDimensions from "common/functions";
import i18n from "internationalization/i18n";
import React from "react";
import { StationsResponse } from "services/utils/interfaces";
import "./ExportStations.less";

type Props = {
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>
    data?: StationsResponse;
    setSelectedStation: React.Dispatch<React.SetStateAction<string>>
    selectedClass : number;
    setSelectedClass : React.Dispatch<React.SetStateAction<number>>
};

const ExportStations : React.FC<Props> = ({searchQuery, setSearchQuery, data, setSelectedStation, selectedClass, setSelectedClass}) => {
  
    const { width } = useWindowDimensions();

    const getExportStationsDesktop = () => (
        <div className="export-available-stations">
            <h4>{i18n.t("exportModal.availableStations")}</h4>
            <div className="export-section-container">
                <input className="export-station-search" placeholder="Search station" onChange={event => setSearchQuery(event.target.value)} />
                    {
                    data ? <div className="available-stations-list"> 
                    { Object.keys(data).filter(station => {
                    if (searchQuery === "") {
                        return station;   
                    } else if (station.toLowerCase().indexOf(searchQuery.toLowerCase()) === 0) {
                        return station;
                    }}).map((key,index) => {
                    if(index === selectedClass) return (<div className="selected-station-container" key={key}>
                        <label htmlFor={`radio-station-${index}`} onClick={() => {setSelectedStation(key); setSelectedClass(index);}}>{key}</label>
                        <input id={`radio-station-${index}`} name="station" type="radio"/>
                    </div>);
                    else return (<div className="available-station-container" key={key}>
                        <label htmlFor={`radio-station-${index}`} onClick={() => {setSelectedStation(key); setSelectedClass(index);}}>{key}</label>
                        <input id={`radio-station-${index}`} name="station" type="radio"/>
                    </div>);
                    })
                    }   
            </div>:null
                }
            </div>
        </div>);

const getExportStationsMobile = () => (
    <div className="export-available-stations">
        <h2>{i18n.t("exportModal.availableStations")}</h2>
        <div className="export-section-container">
            <input className="export-station-search" placeholder="Search station" value={searchQuery} onChange={event => setSearchQuery(event.target.value)} />
                {
                data?<div className="available-stations-list"> 
                {Object.keys(data).filter(station => {
                if (searchQuery === "") {
                    return station;   
                } else if (station.toLowerCase().indexOf(searchQuery.toLowerCase()) === 0) {
                    return station;
                }}).map((key,index) => {
                if(index === selectedClass) return (<div className="selected-station-container" key={key}>
                    <label htmlFor={`radio-station-${index}`} onClick={() => {setSelectedStation(key); setSelectedClass(index);}}>{key}</label>
                    <input id={`radio-station-${index}`} name="station" type="radio"/>
                </div>);
                else return (<div className="available-station-container" key={key}>
                    <label htmlFor={`radio-station-${index}`} onClick={() => {setSelectedStation(key); setSelectedClass(index);}}>{key}</label>
                    <input id={`radio-station-${index}`} name="station" type="radio"/>
                </div>);
                }) 
                }
        </div>:null
        }
        </div>
    </div>);

    return <>
        { width>767?getExportStationsDesktop():getExportStationsMobile()}          
        </>;
};

export default ExportStations;