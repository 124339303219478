/* eslint-disable @typescript-eslint/no-explicit-any */
import { reduxActionType } from "../utils/types";

export const setLogin = (payload: any): reduxActionType => {
	return {
		type: "SET_LOGIN",
		payload,
	};
};

export const setForm = (payload: any): reduxActionType => {
	return {
		type: "SET_FORM_DATA",
		payload,
	};
};

export const setCurrentFormStep = (payload: { current_form: string }): reduxActionType => {
	return {
		type: "SET_FORM_STEP",
		payload
	};
};

export const setLogout = (): reduxActionType => {
	return {
		type: "SET_LOGOUT"
	};
};
