import { createApi } from "@reduxjs/toolkit/query/react";
import { cacheDurationSeconds } from "common/constants";
import { IProfile, IPortalName } from "redux/utils/interfaces";
import { baseQueryWithReauth } from "./utils/constants";

export const profileApi = createApi({
	reducerPath: "profile",
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: cacheDurationSeconds,
	tagTypes: ["Portal", "Profile-Data"],
	endpoints: (builder) => ({
		getOperatorProfile: builder.query({
			query: (userName: string) => `get-operator-profile?userName=${userName}`,
			providesTags: ["Profile-Data"]
		}),
		saveOperatorProfile: builder.mutation<void, IProfile>({
			query: (profile) => ({
				url: "save-operator-profile",
				method: "PUT",
				body: profile,
			}),
			invalidatesTags: ["Profile-Data"]
		}),
		getPortalName: builder.query({
			query: (data) => {
				const { userName } = data;
				return {
					url: `get-portal-name?userName=${userName}`,
					method: "GET"
				};
			},
			providesTags: ["Portal"]
		}),
		savePortalName: builder.mutation<void, IPortalName>({
			query: (data) => {
				const { userName, portalName } = data;
				return {
					url: `save-portal-name?userName=${userName}`,
					method: "PUT",
					body: { portalName: portalName }
				};
			},
			invalidatesTags: ["Portal"]
		})
	}),
});

export const {
	useGetOperatorProfileQuery,
	useSaveOperatorProfileMutation,
	useGetPortalNameQuery,
	useSavePortalNameMutation
} =
	profileApi;
