/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { DualAxes } from "@ant-design/plots";
import {
	dashboardApi,
	useGetEnergyDetailsQuery,
	useGetTransactionDetailsQuery,
} from "services/dashboardService";
import { combineTransDetails } from "./utils/functions";
import { chartConfig, transEnergyExtraConfig } from "../../constants";
import { useDispatch } from "react-redux";
import { stationsApi, useGetEnergyForStationQuery, useGetTransactionForStationQuery } from "services/stationsService";
import { emailToUsername } from "common/utils";

type Props = {
  params: {
    userName: any;
    startDate: string;
    endDate: string;
    locationId?: string;
  };
  shouldRefresh?: boolean;
};

const TransEnergy = ({ params, shouldRefresh }: Props) => {

	const [data, setData] = useState<any[]>([]);
	const dispatch = useDispatch();
	const { userName, locationId, endDate } = params;
	const energyDashboard = useGetEnergyDetailsQuery(params, { skip: locationId ? true : false }).data;
	const transactionsDashboard = useGetTransactionDetailsQuery(params, { skip: locationId ? true : false }).data;

	const energyStation = useGetEnergyForStationQuery(
		{ ...params, userName: emailToUsername(userName), locationId: locationId || "" },
		{ skip: locationId ? false : true }
	).data;
	const transactionsStation = useGetTransactionForStationQuery(
		{ ...params, userName: emailToUsername(userName), locationId: locationId || "" },
		{ skip: locationId ? false : true }
	).data;

	useEffect(() => {
		if (locationId) {
			if (energyStation && transactionsStation)
				setData(combineTransDetails({ transactions: transactionsStation, energy: energyStation }));
		} else {
			if (energyDashboard && transactionsDashboard)
				setData(combineTransDetails({ transactions: transactionsDashboard, energy: energyDashboard }));
		}
	}, [energyDashboard, transactionsDashboard, energyStation, transactionsStation, locationId]);

	useEffect(() => {
		if (locationId) {
			dispatch(
				stationsApi.endpoints.getEnergyForStation.initiate(
					{
						userName: params.userName,
						locationId: locationId || "",
						startDate: params.startDate,
						endDate: params.endDate
					},
					{ subscribe: false, forceRefetch: true }
				)
			);
			dispatch(
				stationsApi.endpoints.getTransactionForStation.initiate(
					{
						userName: params.userName,
						locationId: locationId || "",
						startDate: params.startDate,
						endDate: params.endDate
					},
					{ subscribe: false, forceRefetch: true }
				)
			);
		} else {

			dispatch(
				dashboardApi.endpoints.getEnergyDetails.initiate(
					{ userName: params.userName, startDate: params.startDate, endDate: params.endDate },
					{ subscribe: false, forceRefetch: true }
				)
			);
			dispatch(
				dashboardApi.endpoints.getTransactionDetails.initiate(
					{ userName: params.userName, startDate: params.startDate, endDate: params.endDate },
					{ subscribe: false, forceRefetch: true }
				)
			);
		}
	}, [shouldRefresh, dispatch, userName, endDate, locationId]);

	return (
		<DualAxes
			{...{
				...chartConfig["dual-axis"]["column-line"],
				...transEnergyExtraConfig,
				meta: {
					transactions: {
						range: [data.find(({ transactions }) => transactions === 0) ? 0 : 0.1, 1]
					}
				},
				data: [data, data],
				xField: "time",
				yField: ["energy kWh", "transactions"],
			}}
		/>
	);
};

export default TransEnergy;
