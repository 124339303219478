/* eslint-disable @typescript-eslint/no-explicit-any */
import jwt_decode from "jwt-decode";
import { useState, useEffect } from "react";
import { setCurrentFormStep } from "redux/authentication/actions";
import { store } from "redux/store";

export const decodeJWT = (webToken: string) => {
	const token: any = jwt_decode(webToken);
	return token;
};

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState<{ width: number, height: number }>(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export const getDataToDisplay = <T>(items: T[], limit: number, offset: number): T[] => {
	const startIndex = limit * offset;
	const endIndex = startIndex + limit;
	return items.slice(startIndex, endIndex);
};

export const counterCommonItemsArrays = (firstArray, secondArray) => {
	return firstArray.reduce((totalCounter, element) => {
		const valueExists = secondArray.find(el => JSON.stringify(el) === JSON.stringify(element));
		return valueExists ? totalCounter + 1 : totalCounter;
	}, 0);
};

export const updateFormType = (type: string) => {
	store.dispatch(setCurrentFormStep({current_form: type}));
};
