export const headerInitial = [
	{ description:"Total Revenue",subDescription:""},
	{ description:"Connectors",subDescription:"Level 2"},
	{ description:"Connectors",subDescription:"DCFC"},
	{ description:"Sessions",subDescription:""},
	{ description:"Sessions / Connector",subDescription:""},
	{ description:"Energy",subDescription:""},
	{ description:"Energy / Session",subDescription:""},
	{ description:"Users",subDescription:""},
	{ description:"GHG Reduction",subDescription:""}];

export const grayBackground = {
	backgroundColor:"#fafafa"
};

