/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import navbar_logo from "assets/logo.png";
import DarkBlueButton from "common/components/StyledButtons/DarkBlueButton/DarkBlueButton";
import GrayButton from "common/components/StyledButtons/GrayButton/GrayButton";
import {
	MailOutlined,
	MenuOutlined,
	QuestionCircleOutlined,
	SettingOutlined,
	SyncOutlined,
	ToTopOutlined,
	UserOutlined
} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {rootReducerType} from "redux/combineReducers";
import "./Header.less";
import ProfilePopup from "common/components/ProfilePopup/ProfilePopup";
import {getMenuItems} from "common/utils";
import {useTranslation} from "react-i18next";
import {Menu} from "antd";
import SubHeader from "./components/SubHeader";
import ExportModal from "../ExportModal/ExportModal";

type Props = {
	setRefreshButton: () => void;
	shouldRefresh?: boolean;
	setTabProfile: (value: string) => void;
};

const setCurrentTab = ( setTabIndex :React.Dispatch<React.SetStateAction<string[] | undefined>> ) => {

	const path = window.location.pathname;
	if (path.startsWith("/dashboard"))
		{
			setTabIndex(["1"]);
		}

	else if(path.startsWith("/stations/"))
		{
			setTabIndex(["2"]);
		}

	else if(path.startsWith("/chargers"))
		{
			setTabIndex(["3"]);
		}

	else if(path.startsWith("/transactions"))
		{
			setTabIndex(["4"]);
		}

	else {
		setTabIndex(undefined);
	}

};


const Header = ({setRefreshButton, shouldRefresh, setTabProfile}: Props) => {

	const location = useLocation();

	const handleRefetch = () => {
		setRefreshButton();
	};

	const [isVisibleExportModal,setIsVisibleExportModal] = useState(false);

	const [isVisibleProfilePopup, setIsVisibleProfilePopup] = useState<boolean>(false);
	const [tabIndex, setTabIndex] = useState<string[] | undefined>(["1"]);
	const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

	const navigate = useNavigate();

	const {t} = useTranslation();
	const {isLoggedIn} = useSelector((state: rootReducerType) => state.authenticationReducer);


	const handleSupportClick = () => {
		setTabIndex(undefined);
		navigate("/support");
	};

	const handleEditProfileClick = () => {
		setTabProfile("2");
		navigate("/profile");
	};

	const menuItemsHeader = [
		{name: t("header.dashboard"), triggerClick: () => navigate("/dashboard"), key: "1"},
		{name: t("header.stations"), triggerClick: () => navigate("/stations/"), key: "2"},
		{name: t("header.chargers"), triggerClick: () => navigate("/chargers"), key: "3"},
		// { name: "Users", triggerClick: () => navigate("/users"), key: "4" },
		{name: t("header.transactions"), triggerClick: () => navigate("/transactions"), key: "4"},
		// { name: "Pricing", triggerClick: () => navigate("/pricing"), key: "6" },
	];

	const tabPanelsHeaderButtons = [
		{name: t("header.refresh"), key: "7", icon: <SyncOutlined/>, triggerClick: () => null},
		{name: t("header.export"), key: "8", icon: <ToTopOutlined/>, triggerClick: () => setIsVisibleExportModal(true)},
		{name: t("header.support"), key: "9", icon: <QuestionCircleOutlined/>, triggerClick: () => null},
		{name: t("header.email"), key: "10", icon: <MailOutlined/>, triggerClick: () => null},
		{name: t("header.profile"), key: "11", icon: <UserOutlined/>, triggerClick: () => setIsVisibleProfilePopup(true)},
	];

	useEffect(() => {
		window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
	}, []);

	useEffect(() => setCurrentTab(setTabIndex),[location]);


	const handleChangeMenuItem = (key: string, menuItems: any[]) => {
		const panelDetails = menuItems.find(item => item.key === key);
		if (panelDetails && panelDetails?.triggerClick) {
			setTabIndex([key] || undefined);
			panelDetails.triggerClick();
		}
	};

	const getDesktopHeader = () => (
		<>
			<Menu
				onClick={(event) => handleChangeMenuItem(event.key, menuItemsHeader)}
				selectedKeys={tabIndex}
				mode='horizontal'
				disabledOverflow={true}
				className='desktopHeader'
			>
				{getMenuItems(menuItemsHeader)}
			</Menu>
			<div className='buttons-container'>
				<DarkBlueButton style={{marginLeft: "auto"}} name={t("header.refresh")} onClick={handleRefetch}/>
				<GrayButton icon={<ToTopOutlined/>} name={t("header.export")} onClick={()=> setIsVisibleExportModal(visible => !visible)}/>
				{location.pathname === "/support" ?
					<GrayButton icon={<QuestionCircleOutlined/>} style={{color: "#2a54c5"}} name={t("header.support")} onClick={handleSupportClick}/> :
					<GrayButton icon={<QuestionCircleOutlined/>}  name={t("header.support")} onClick={handleSupportClick}/>
				}
				<GrayButton icon={<MailOutlined/>} className='emailButton' onClick={handleEditProfileClick}/>
				<ProfilePopup
					isVisible={isVisibleProfilePopup}
					handleChangeVisibility={(visibility: boolean) =>
						setIsVisibleProfilePopup(visibility)
					}
					setTabIndex={setTabIndex}
					shouldRefresh={shouldRefresh}
					setTabProfile={setTabProfile}
				/>
			</div>
			{isVisibleExportModal?<ExportModal closeModal={setIsVisibleExportModal}/>:null}
		</>
	);

	const getMobileHeader = () => (
		<>
		<div className='subHeader-container'>
			<SubHeader
				icon={MenuOutlined}
				items={menuItemsHeader}
				setTabIndex={(key: string[] | undefined) => setTabIndex(key)}
			/>
			<SubHeader icon={SettingOutlined} items={tabPanelsHeaderButtons}/>

			<ProfilePopup
				isVisible={isVisibleProfilePopup}
				handleChangeVisibility={(visibility: boolean) =>
					setIsVisibleProfilePopup(visibility)
				}
				setTabIndex={setTabIndex}
				showIcon={false}
				setTabProfile={setTabProfile}
			/>
		</div>
		</>
	);

	const handleClickLogo = () => {
		setTabIndex(["1"]);
		navigate("/dashboard");
	};

	return (
		<>
		{isLoggedIn &&
		<div className='header'>
			<img src={navbar_logo} alt='No logo found' onClick={handleClickLogo}/>
			{screenWidth > 768 ? getDesktopHeader() : getMobileHeader()}
		</div>}
		{isVisibleExportModal && screenWidth< 768 ? <ExportModal closeModal={setIsVisibleExportModal}/>: null}
		</>
	);
};

export default Header;
