import { NavigateFunction } from "react-router-dom";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { ChargerRowTransactions, Row, RowTransactions } from "./types";
import React from "react";
import { Tag } from "antd";
import i18n from "i18next";
import { Transactions as TypeTransactionsPage } from "pages/transactions/Transactions";
import { Chargers } from "../Chargers";
import { ChargerTransactions } from "../Charger";

type Sort = {
	sessions: boolean,
	energyInKWH: boolean,
	revenue:boolean
};

type SortTransactions = {
	startTime: boolean,
	endTime: boolean,
	length: boolean,
	energy: boolean,
};

type Transactions = {
	startTime: boolean,
	endTime: boolean,
	energy: boolean,
	revenue: boolean,
};

export const computeRowsAndCols = (
	data: Chargers,
	navigate: NavigateFunction,
	handleSort:(value: string) => void,
	shouldReset: (value: boolean) => void,
	reset: boolean,
	isAsc: Sort,
	isDesc: Sort,
	setIsAsc: (values: () =>Sort) => void,
	setIsDesc: (values: () =>Sort) => void) => {

	if (!data.length) {
		return {
			columns: [],
			rows: []
		};
	}

	const handleFilterChange = (field: string) => {
		if (!isAsc[field]) {
			setIsAsc(() => {
				const setSort = { sessions: false, energyInKWH: false, revenue: false };
				setSort[field] = true;
				return { ...setSort };
			});
			handleSort(field + "_asc");
			setIsDesc(() => {
				return { sessions: false, energyInKWH: false, revenue: false };
			});
		} else if (!isDesc[field] && isAsc[field]) {
			setIsAsc(() => {
				return { sessions: false, energyInKWH: false, revenue: false };
			});
			setIsDesc(() => {
				const setSort = {sessions: false, energyInKWH: false, revenue: false};
				setSort[field] = true;
				return { ...setSort };
			});
			handleSort(field + "_desc");
			shouldReset(false);
		}
	};

	const columns = [
		{
			title: <div className="table-header">{i18n.t("chargers.chargerId")}</div>,
			dataIndex: "chargerId",
			key: "chargerId",
			align: "center" as const,
			render: (index) => {
				if (data[index]) {
					return <a onClick={() =>
						navigate(`${data[index].chargerId}`, {
							state: {
								locationIdStationId: data[index]["locationId.stationId"],
								status: data[index].status
							}
						})}
					>
						{data[index].chargerId}
					</a>;
				}
				return <a>{data[index].chargerId}</a>;
			},
		},
		{
			title: <div className="table-header">{i18n.t("chargers.station")}</div>,
			dataIndex: "stationId",
			key: "stationId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("chargers.location")}</div>,
			dataIndex: "locationId",
			key: "stationId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("chargers.status")}</div>,
			key: "status",
			dataIndex: "status",
			align: "center" as const,
			render: (tag) =>
			{
				let color = "#CECECE";
				if (tag === "Fault") {
					color = "#FF7866";
				} else if (tag === "Available") {
					color = "#9DCC78";
				} else if (tag === "Unavailable") {
					color = "#FED077";
				} else if (tag === "In use") {
					color = "#E088FF";
				}
				return <Tag color={color} key={tag}>{tag.toUpperCase()}</Tag>;
			}
		},
		{
			title: <div className="table-header">{i18n.t("chargers.type")}</div>,
			dataIndex: "type",
			key: "type",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("chargers.sessions")} { reset ?
				<CaretDownOutlined onClick={() => {
					handleFilterChange("sessions");
				}}/> : (!isDesc.sessions  ?
					<CaretDownOutlined onClick={() => {
						handleFilterChange("sessions");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleFilterChange("sessions");
					}} />)}
			</div>,
			dataIndex: "sessions",
			key: "sessions",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("chargers.energy")} {reset ?
				<CaretDownOutlined onClick={() => {
					handleFilterChange("energyInKWH");
				}}/> : (!isDesc.energyInKWH  ?
					<CaretDownOutlined onClick={() => {
						handleFilterChange("energyInKWH");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleFilterChange("energyInKWH");
					}} />)}
			</div>,
			dataIndex: "energyInKWH",
			key: "energyInKWH",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("chargers.revenue")} {reset ?
				<CaretDownOutlined onClick={() => {
					handleFilterChange("revenue");
				}}/> : (!isDesc.revenue  ?
					<CaretDownOutlined onClick={() => {
						handleFilterChange("revenue");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleFilterChange("revenue");
					}} />)}
			</div>,
			dataIndex: "revenue",
			key: "revenue",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("chargers.pricingPlan")}</div>,
			dataIndex: "pricingPlan",
			key: "pricingPlan",
			align: "center" as const,
		},
	];

	const rows:Row[] = [];
	Object.keys(data).forEach((key, index) => {
		const values = data[key];
		rows.push({
			key: (rows.length + 1).toString(),
			chargerId: String(index),
			stationId: values.stationId,
			locationId: values.locationId,
			status: values.status,
			type: values.type,
			energyInKWH: values.energyInKWH,
			revenue: values.revenue,
			pricingPlan: values.pricingPlan,
			sessions: values.sessions
		});
	});

	return {
		columns: columns,
		rows: rows
	};
};

export const computeRowsAndColsForTransactions = (
	data: ChargerTransactions,
	handleSort:(value: string) => void,
	isAsc: SortTransactions,
	isDesc: SortTransactions,
	setIsAsc: (values: () => SortTransactions) => void,
	setIsDesc: (values: () => SortTransactions) => void,
	) => {

	if (!data.length) {
		return {
			columns: [],
			rows: []
		};
	}

	const handleSortChange = (field: string) => {
		if (!isAsc[field]) {
			setIsAsc(() => {
				const setSort = {startTime: false,
					endTime: false,
					length: false,
					energy: false,
				};
				setSort[field] = true;
				return { ...setSort };
			});
			handleSort(field + "_asc");
			setIsDesc(() => {
				return {
					startTime: false,
					endTime: false,
					length: false,
					energy: false,
				};
			});
		} else if (!isDesc[field] && isAsc[field]) {
			setIsAsc(() => {
				return {
					startTime: false,
					endTime: false,
					length: false,
					energy: false,
				};
			});
			setIsDesc(() => {
				const setSort = {
					startTime: false,
					endTime: false,
					length: false,
					energy: false,
				};
				setSort[field] = true;
				return { ...setSort };
			});
			handleSort(field + "_desc");
		}
	};

	const columns = [
		{
			title: <div className="table-header">{i18n.t("charger.transactionId")}</div>,
			dataIndex: "transactionId",
			key: "transactionId",
			align: "center" as const,
		},

		{
			title: <div className="table-header">{i18n.t("charger.connectorId")}</div>,
			dataIndex: "connectorId",
			key: "connectorId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("charger.userId")}</div>,
			dataIndex: "userId",
			key: "userId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("charger.startTime")}{!isDesc.startTime  ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("startTime");
				}}/> :
				<CaretUpOutlined onClick={() => {
					handleSortChange("startTime");
				}} />}
			</div>,
			dataIndex: "startTime",
			key: "startTime",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("charger.endTime")}{!isDesc.endTime  ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("endTime");
				}}/> :
				<CaretUpOutlined onClick={() => {
					handleSortChange("endTime");
				}} />}
			</div>,
			key: "endTime",
			dataIndex: "endTime",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("charger.length")}{!isDesc.length  ?
					<CaretDownOutlined onClick={() => {
						handleSortChange("length");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleSortChange("length");
					}} />}
			</div>,
			dataIndex: "length",
			key: "length",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("charger.energy")}{!isDesc.energy  ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("energy");
				}}/> :
				<CaretUpOutlined onClick={() => {
					handleSortChange("energy");
				}} />}
			</div>,
			dataIndex: "energy",
			key: "energy",
			align: "center" as const,
		}
	];

	const rows:ChargerRowTransactions[] = [];
	Object.keys(data).forEach(key => {
		const values = data[key];
		rows.push({
			key: (rows.length + 1).toString(),
			transactionId: values.transactionId,
			connectorId: values.connectorId,
			userId: values.userId,
			startTime: values.startTime,
			endTime: values.endTime,
			length: (values.length).toString() + " " + values.lengthUnit,
			energy: (values.energy).toString() + " " + values.energyUnit,
		});
	});

	return {
		columns: columns,
		rows: rows
	};
};

export const computeRowsAndColsForTransactionsPage = (
	data: TypeTransactionsPage,
	navigate: NavigateFunction,
	handleSort:(value: string) => void,
	shouldReset: (value: boolean) => void,
	reset: boolean,
	isAsc: Transactions,
	isDesc: Transactions,
	setIsAsc: (values: () => Transactions) => void,
	setIsDesc: (values: () => Transactions) => void) => {

	if (data.length === 0) {
		return {
			columns: [],
			rows: []
		};
	}

	const handleSortChange = (field: string) => {
		if (!isAsc[field]) {
			setIsAsc(() => {
				const setSort = {
					startTime: false,
					endTime: false,
					energy: false,
					revenue: false,
				};
				setSort[field] = true;
				return { ...setSort };
			});
			handleSort(field + "_asc");
			setIsDesc(() => {
				return {
					startTime: false,
					endTime: false,
					energy: false,
					revenue: false,
				};
			});
		} else if (!isDesc[field] && isAsc[field]) {
			setIsAsc(() => {
				return {
					startTime: false,
					endTime: false,
					energy: false,
					revenue: false,
				};
			});
			setIsDesc(() => {
				const setSort = {
					startTime: false,
					endTime: false,
					energy: false,
					revenue: false,
				};
				setSort[field] = true;
				return { ...setSort };
			});
			handleSort(field + "_desc");
		}
		shouldReset(false);
	};

	const columns = [
		{
			title: <div className="table-header">{i18n.t("transactions.transactionId")}</div>,
			dataIndex: "transactionId",
			key: "transactionId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.userId")}</div>,
			dataIndex: "userId",
			key: "userId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.station")}</div>,
			dataIndex: "station",
			key: "station",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.chargerId")}</div>,
			dataIndex: "chargerId",
			key: "chargerId",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.type")}</div>,
			dataIndex: "type",
			key: "type",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.startTime")} { reset ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("startTime");
				}}/> : (!isDesc.startTime  ?
					<CaretDownOutlined onClick={() => {
						handleSortChange("startTime");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleSortChange("startTime");
					}} />)}
			</div>,
			dataIndex: "startTime",
			key: "startTime",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.endTime")} { reset ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("endTime");
				}}/> : (!isDesc.endTime  ?
					<CaretDownOutlined onClick={() => {
						handleSortChange("endTime");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleSortChange("endTime");
					}} />)}
			</div>,
			key: "endTime",
			dataIndex: "endTime",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.energy")} { reset ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("energy");
				}}/> : (!isDesc.energy  ?
					<CaretDownOutlined onClick={() => {
						handleSortChange("energy");
					}}/> :
					<CaretUpOutlined onClick={() => {
						handleSortChange("energy");
					}} />)}
			</div>,
			key: "energy",
			dataIndex: "energy",
			align: "center" as const,
		},

		{
			title: <div className="table-header">{i18n.t("transactions.revenue")}{!isDesc.revenue  ?
				<CaretDownOutlined onClick={() => {
					handleSortChange("revenue");
				}}/> :
				<CaretUpOutlined onClick={() => {
					handleSortChange("revenue");
				}} />}
			</div>,
			key: "revenue",
			dataIndex: "revenue",
			align: "center" as const,
		},
		{
			title: <div className="table-header">{i18n.t("transactions.pricingPlan")}</div>,
			dataIndex: "pricingPlan",
			key: "pricingPlan",
			align: "center" as const,
		}
	];

	const rows:RowTransactions[] = [];
	Object.keys(data).forEach(key => {
		const values = data[key];
		rows.push({
			key: (rows.length + 1).toString(),
			transactionId: values.transactionId,
			userId: values.userId,
			station: values.station,
			chargerId: values.chargerId,
			type: values.type,
			startTime: values.startTime,
			endTime: values.endTime,
			energy: (values.energy).toString() + " " + values.energyUnit,
			revenue: values.revenue,
			pricingPlan: values.pricingPlan
		});
	});

	return {
		columns: columns,
		rows: rows
	};
};

export const addZerosToStringNumber = (number: string) => {
	const arrNumber = number.split("");
	while(arrNumber.length < 4) {
		arrNumber.unshift("0");
	}
	return arrNumber.reduce((previous, current) => previous + current, "");
};
