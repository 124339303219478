import React from "react";
import "./SmallGrayButton.less";
import { Button, ButtonProps } from "antd";

const SmallGrayButton: React.FC<ButtonProps> = (props) => {
	return (
		<Button onClick={props.onClick} className={`small-gray-button ${props.className}`} style={props.style} size={props.size}>
			{props.name}
		</Button>
	);
};

export default SmallGrayButton;
