import { createApi } from "@reduxjs/toolkit/query/react";
import { ComparisonResponse, EnergyResponse, GenericResponse, GHGResponse, PowerResponse, StationParams, StationsParams, StationsResponse, SummaryResponse } from "./utils/interfaces";
import { baseQueryWithReauth } from "./utils/constants";
import { cacheDurationSeconds } from "common/constants";


const getGenericURLParams = (args: StationParams) =>
	`userName=${args.userName}&locationId.stationId=${args.locationId}`;

const getExtendedURLParams = (args: StationParams) =>
	`${getGenericURLParams(args)}&startDate=${args.startDate}&endDate=${args.endDate
	}`;

export const stationsApi = createApi({
	reducerPath: "stations",
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: cacheDurationSeconds,
	endpoints: (builder) => ({
		getStations: builder.query<StationsResponse, StationsParams>({
			query: (args) => {
				const { userName } = args;
				return {
					url: `get-stations?userName=${userName}`,
				};
			},
		}),
		getConnectorSummaryForStation: builder.query<
			GenericResponse,
			StationParams
		>({
			query: (args) => ({ url: `get-connector-summary-for-station?${getGenericURLParams(args)}`, }),
		}),
		getTransactionForStation: builder.query<GenericResponse, StationParams>({
			query: (args) => ({
				url: `get-transaction-for-station?${getExtendedURLParams(args)}`,
			}),
		}),
		getEnergyForStation: builder.query<EnergyResponse, StationParams>({
			query: (args) => ({
				url: `get-energy-for-station?${getExtendedURLParams(args)}`,
			}),
		}),
		getUtilizationForStation: builder.query<GenericResponse, StationParams>({
			query: (args) => ({
				url: `get-utilization-for-station?${getExtendedURLParams(args)}`,
			}),
		}),
		getRevenueForStation: builder.query<GenericResponse, StationParams>({
			query: (args) => ({
				url: `get-revenue-for-station?${getExtendedURLParams(args)}`,
			}),
		}),
		getGHGReductionForStation: builder.query<GHGResponse, StationParams>({
			query: (args) => ({
				url: `get-ghg-reduction-for-station?${getExtendedURLParams(args)}`,
			}),
		}),
		getPowerForStation: builder.query<PowerResponse, StationParams>({
			query: (args) => ({
				url: `get-power-for-station?${getExtendedURLParams(args)}`,
			}),
		}),
		getSummaryForStation: builder.query<SummaryResponse, StationParams>({
			query: (args) => ({
				url: `get-summary-stats-for-station?${getGenericURLParams(args)}`,
			}),
		}),
		getStationsComparison: builder.query<ComparisonResponse, { stations: string }>({
			query: (args) => ({ url: `compare-stations?stations=${args.stations}` })
		})
	})
});

export const {
	useGetStationsQuery,
	useGetConnectorSummaryForStationQuery,
	useGetTransactionForStationQuery,
	useGetEnergyForStationQuery,
	useGetUtilizationForStationQuery,
	useGetRevenueForStationQuery,
	useGetGHGReductionForStationQuery,
	useGetSummaryForStationQuery,
	useGetStationsComparisonQuery,
	useGetPowerForStationQuery
} = stationsApi;
