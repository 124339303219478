/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

const FloatInput = (props: any) => {
	const { t } = useTranslation();
	const [focus, setFocus] = useState(false);
	const { label, value, placeholder = props.label, type, required, passField } = props;

	const isOccupied = focus || (value && value.length !== 0);

	const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

	const requiredMark = required ? <span className="text-danger">*</span> : null;

	return (
		<div
			className="float-label"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
		>
			{passField ? (
				<Input.Password
					onChange={props.onChange}
					type={type}
					defaultValue={value}
					style={{ borderRadius: "4px" }}
				/>
			) : (
				<Input
					onChange={props.onChange}
					type={type}
					defaultValue={value}
					style={{ borderRadius: "4px" }}
				/>
			)}
			<label className={labelClass}>
				{isOccupied ? label : t(placeholder)} {requiredMark}
			</label>
		</div>
	);
};

export default FloatInput;
