import React, { FC, ReactElement } from "react";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import logo from "assets/logo.png";
import "./LoadingScreen.less";
import {useTranslation} from "react-i18next";

const LoadingScreen: FC<{ pageName: string }> = ({ pageName }): ReactElement => {
	const { t } = useTranslation();
	return (
		<div className='loadingScreen'>
			<div className='loadingScreenSubcontainer'>
				<img src={logo} alt={t("loadingScreen.logoNotFound")} />
				<Spin
					indicator={<Loading3QuartersOutlined style={{ fontSize: 48, color: "white" }} spin />}
					style={{ marginBottom: 18 }}
				/>
				<p>{t("loadingScreen.loading")} {pageName} ...</p>
			</div>
		</div>
	);
};

export default LoadingScreen;
