import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GreenTick } from "../../../../assets/green-tick.svg";
import Logo from "../../../../common/components/Logo/Logo";
import { setCurrentFormStep } from "../../../../redux/authentication/actions";
import "./Success.less";
import { useTranslation } from "react-i18next";

const Success = () => {

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const finishRegistration = () => {
		dispatch(
			setCurrentFormStep({
				current_form: "sign-in"
			})
		);
		navigate("/");
	};

	return (
		<section className="success-container">
			<Logo />
			<img
				style={{ width: "60vw", height: "80vh" }}
				src={require("../../../../assets/account-created.png")}
				id="background"
				alt="img not found"
			/>
			<section className="success-details">
				<GreenTick />
				<div className="title">
					{t("success.accountCreated")}
				</div>
				<Button type="primary" onClick={() => finishRegistration()}>
					{t("success.start")}
				</Button>
			</section>
		</section>
	);
};

export default Success;
