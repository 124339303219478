import useWindowDimensions, { decodeJWT } from "common/functions";
import React, { useEffect } from "react";
import Connectors from "../ConnectorsPieChart/Connectors";
import i18n from "internationalization/i18n";
import Details from "../DetailsCharts/Details";
import InfoCard from "pages/dashboard/components/InfoCard/InfoCard";
import {SummaryResponse } from "services/utils/interfaces";
import {ReactComponent as MinusButton} from "assets/minus-button.svg";
import { uniqueId } from "lodash";
import { Button } from "antd";
import { dashboardApi, useSaveStatsMutation } from "services/dashboardService";
import { useDispatch, useSelector } from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import "./ContainerCharts.less";
import { emailToUsername } from "common/utils";
import Notification from "../NotificationMessage/Notification";


interface IContainerCharts {
	summary: { type: string; value: number; }[]
	summaryStats?: SummaryResponse;
	expanded?:boolean;
	locationId?: string;
	shouldRefresh?: boolean;
	activeStats?: string[];
	inactiveStats?: string[];
	setActiveStats?: React.Dispatch<React.SetStateAction<string[]|undefined>>;
	setInactiveStats?: React.Dispatch<React.SetStateAction<string[]|undefined>>;
	setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContainerCharts: React.FC<IContainerCharts> = ({ summary, summaryStats, locationId, shouldRefresh, expanded, setExpanded, activeStats, setActiveStats, inactiveStats, setInactiveStats}) => {
	
	const { width } = useWindowDimensions();
	const dispatch = useDispatch();
	const [saveStats, { isSuccess }] = useSaveStatsMutation();
	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const params = {
		userName: emailToUsername(decodeJWT(isLoggedIn).email),
		activeStats: activeStats,
		inactiveStats: inactiveStats
	};

	useEffect(() => {
	if(isSuccess)
		Notification({ type: "success", message: i18n.t("dashboard.toastTitle"), description: i18n.t("dashboard.toastCustomisationSave")});
	},[isSuccess]);

	const removeStat = (index:number) => {
		if(setActiveStats && setInactiveStats && activeStats && inactiveStats) {
			setActiveStats(activeStats.filter((_,i) => i !=index));
			setInactiveStats([...inactiveStats,activeStats[index]]);
		}
	};

	const addStat = (index:number) => {
		if(inactiveStats && setInactiveStats)
			setInactiveStats(inactiveStats.filter((_,i) => i !=index));
		if(activeStats && inactiveStats && setActiveStats)
			setActiveStats([...activeStats,inactiveStats[index]]);
	};

	const desktopCards = () => (<>
		{
			expanded && activeStats && setActiveStats? activeStats.map((stat,index)=> {
				return (
					<div key={uniqueId()} className="summary-card-container">
						<div className="action-image" onClick={()=> removeStat(index)}>
							<MinusButton fill="white"/>
						</div>					
						<InfoCard
							description={stat}
							simpleCard={true}
						/>
					</div>
				);}) :
				summaryStats ? Object.entries(summaryStats).map(([key, value]) => {
					if (key !== undefined && key !== null && value !== undefined && value !== null)	
						return <InfoCard
							title={key.toString()}
							description={value.toString()}
							key={key.toString()}
						/>;
					else return null;
				})
					: null}
	</>
	);

	const mobileCards = () => (
		<>	
			{expanded && activeStats && setActiveStats ? 
				<>
					<Button size="small" onClick={ () => {
						saveStats(params);
						dispatch(
							dashboardApi.endpoints.getSummaryStats.initiate({
								userName: params.userName,
						},
						{ subscribe: false, forceRefetch: true }));
						if(setExpanded) setExpanded(!expanded);
				}}> {i18n.t("dashboard.save")}</Button>

					<div className="customise-stats-section">
						<div className="customise-active-stats-section">
							<h3>{i18n.t("dashboard.activeStats")}</h3>
							{ activeStats.map((stat,index)=> {
								return (
									<div key={uniqueId()} className="summary-card-container" style={{
										height: "12vh",gap:"2vh",width:"100%"}}>
										<div className="action-image" onClick={()=> removeStat(index)}>
											<MinusButton fill="white"/>
										</div>					
										<InfoCard
											description={stat}
											simpleCard={true}
										/>
									</div>
								);})}
						</div>
						<div className="customise-inactive-stats-section">
							<h3>{i18n.t("dashboard.inactiveStats")}</h3>
							{ inactiveStats && inactiveStats.map((stat,index)=> {
								return (
									<div key={uniqueId()} className="summary-card-container" style={{
										height: "12vh",width:"100%"}}>
										<div className="action-image" onClick={()=> addStat(index)}>
											<MinusButton fill="white"/>
										</div>					
										<InfoCard
											description={stat}
											simpleCard={true}
										/>
									</div>
								);})}
						</div>	
					</div>
				</>:
				summaryStats ? Object.entries(summaryStats).map(([key, value]) => {
					if (key && value)
						return (					
							<InfoCard
								title={key}
								description={value}
								key={key}
							/>
						);
					else return null;
				})
					: null}
		</>);

	return (<section className="main-container">
		<section className="section-graphs">
			<Connectors connectorsData={summary} title={i18n.t("dashboard.ConnectorsTitle")} />
			<Details width={width} locationId={locationId} shouldRefresh={shouldRefresh}/>
		</section>
		<section className="section-cards">
			{width > 720
				? desktopCards()
				: mobileCards()}
		</section>
	</section>);
};

export default ContainerCharts;