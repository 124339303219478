import React from "react";
import { Breadcrumb as AntBreadcrumb } from "antd";
import { useNavigate } from "react-router-dom";
import "./Breadcrumb.less";

type BreadcrumbItemType = {
	label: string,
	route?: string;
};

interface IBreadrumb {
	items: BreadcrumbItemType[];
}

const Breadcrumb: React.FC<IBreadrumb> = ({ items }) => {

	const navigate = useNavigate();

	const getBreadcrumItems = () => items.map((item, index) =>
		<AntBreadcrumb.Item
			className={index === items.length - 1 ? "last-item" : "item"}
			onClick={item.route ? () => navigate(item.route || "/") : undefined}
			key={item.label}
		>
			{item.label}
		</AntBreadcrumb.Item>
	);

	return (
		<AntBreadcrumb separator={<div className='separator'></div>}>
			{getBreadcrumItems()}
		</AntBreadcrumb>
	);
};

export default Breadcrumb;