import React, { useEffect } from "react";
import "./SendMessageFrom.less";
import { Form, Input} from "antd";
import FloatInput from "../../../../common/components/FloatingInput";
import DarkBlueButton from "../../../../common/components/StyledButtons/DarkBlueButton/DarkBlueButton";
import { Select } from "antd";
import i18n from "../../../../internationalization/i18n";
import { useSaveFromDataMutation } from "../../../../services/supportService";
import {useSelector} from "react-redux";
import {rootReducerType} from "../../../../redux/combineReducers";
import {decodeJWT} from "../../../../common/functions";
import Notification from "common/components/NotificationMessage/Notification";

const { Option } = Select;
const { TextArea } = Input;

const SendMessageForm: React.FC = () => {

	const options = [
		i18n.t("sendMessageForm.other"),
		i18n.t("sendMessageForm.account"),
		i18n.t("sendMessageForm.charger"),
		i18n.t("sendMessageForm.management"),
		i18n.t("sendMessageForm.dashboardIssues"),
		i18n.t("sendMessageForm.maintenanceRequest")
	];

	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const [saveFormData, { isSuccess }] = useSaveFromDataMutation();

	useEffect(() => {
	if(isSuccess)
		Notification({ type: "success", message: i18n.t("sendMessageForm.toastTitle"), description: i18n.t("sendMessageForm.toastSendMessage")});
	}
	,[isSuccess]);

	const [form] = Form.useForm();

	return (
		<section className="send-message-container">
			<div className="form">
				<div className="header-form">
					{i18n.t("sendMessageForm.sendMessage")}
				</div>
				<div className="content-form">
					<Form
						form={form}
						size="large"
						name="send_message"
						layout="vertical"
						onFinish={(values) => {
							values.userName = decodeJWT(isLoggedIn).email;
							saveFormData(values);
							form.resetFields();
						}}>
						<Form.Item name="messageType">
							<Select defaultValue="Other" className="dropdown">
								{options ? options.map((option, index) => {
									return (
										<Option
											key={index.toString()}
											value={option}
										>{option}</Option>
									);
								}) : null}
							</Select>
						</Form.Item>
						<Form.Item name="subject" rules={[{ required: true, message: i18n.t("sendMessageForm.required") }]}>
							<FloatInput
								label={i18n.t("sendMessageForm.subject")}
								placeholder={i18n.t("sendMessageForm.subject")}
								name="subject"
							/>
						</Form.Item>
						<Form.Item name="messageContent"  rules={[{ required: true, message: i18n.t("sendMessageForm.required") }]}>
							<TextArea rows={4} placeholder={i18n.t("sendMessageForm.yourMessage")} />
						</Form.Item>
						<DarkBlueButton
							style={{float: "right"}}
							htmlType='submit'
							name={i18n.t("sendMessageForm.save")}
						/>
					</Form>
				</div>
			</div>
		</section>
	);
};

export default SendMessageForm;
