import { createApi } from "@reduxjs/toolkit/query/react";
import { cacheDurationSeconds } from "common/constants";
import { baseQueryWithReauth } from "./utils/constants";
import {
	TransactionsParams,
	TransactionsResponse,
} from "./utils/interfaces";
import moment from "moment";

const getUrlParams = (args) => {
	const allowedParams = ["userName", "endTime", "limit", "startTime", "offset", "sortBy", "filterBy"];
	const queryParams: string[] = [];
	Object.keys(args).forEach(key => {
		if (allowedParams.includes(key) && args[key]) {
			queryParams.push(`${key}=` + args[key]);
		}
	});
	return queryParams.join("&");
};

export const transactionsApi = createApi({
	reducerPath: "transactions",
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: cacheDurationSeconds,
	endpoints: (builder) => ({
		getTransactions: builder.query<TransactionsResponse, TransactionsParams>({
			query: (args) => ({
				url: `get-transactions?${getUrlParams(args)}&currentTime=${moment(new Date()).format()}`,
			}),
		})
	}),
});

export const {
	useGetTransactionsQuery,
} = transactionsApi;
