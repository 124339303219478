import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import Container from "common/components/Container/Container";
import {
	dashboardApi,
	useGetActiveStatsQuery,
	useGetConnectorSummaryQuery,
	useGetInactiveStatsQuery,
	useGetSummaryStatsQuery,
} from "services/dashboardService";
import { decodeJWT } from "common/functions";
import i18n from "internationalization/i18n";
import { formatData } from "common/components/ConnectorsPieChart/utils/functions";
import ContainerCharts from "common/components/ContainerCharts/ContainerCharts";
import CustomiseDashboard from "common/components/CustomiseDashboard/CustomiseDashboard";
import { emailToUsername } from "common/utils";

type Props = {
  shouldRefresh?: boolean;
};
const Dashboard: React.FC<Props> = ({ shouldRefresh }): React.ReactElement => {
	const skip = [false, true];
	const [expanded,setExpanded] = useState(false);

	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);

	const [params,setParams] = useState({
		userName: emailToUsername(decodeJWT(isLoggedIn).email),
	});

	const dispatch = useDispatch();
	
	const {data: summaryStats} = useGetSummaryStatsQuery(params);
	const {data: summ} = useGetConnectorSummaryQuery(params, { skip: skip[0] });
	const {data: activeStats} = useGetActiveStatsQuery(params);
	const {data: inactiveStats} = useGetInactiveStatsQuery(params);

	const [active,setActive] = useState(activeStats);
	const [inactive,setInactive] = useState(inactiveStats);

	useEffect(() => {
		setParams({userName: params.userName});
	}, [isLoggedIn]);

	useEffect(() => {
		dispatch(
			dashboardApi.endpoints.getConnectorSummary.initiate(
				{
					userName: params.userName,
				},
				{ subscribe: false, forceRefetch: true }
			)
		);
		dispatch(
			dashboardApi.endpoints.getActiveStats.initiate(
				{
					userName: params.userName,
				},
				{ subscribe: false, forceRefetch: true }
			)
		);
		dispatch(
			dashboardApi.endpoints.getInactiveStats.initiate(
				{
					userName: params.userName,
				},
				{ subscribe: false, forceRefetch: true }
			)
		);
		dispatch(
			dashboardApi.endpoints.getSummaryStats.initiate(
				{
					userName: params.userName,
				},
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [shouldRefresh, dispatch]);

	useEffect(()=> setActive(activeStats),[activeStats]);
	useEffect(()=> setInactive(inactiveStats),[inactiveStats]);

	const summary = formatData(summ);

	return (
		<Container
			backButton={false}
			loadingPageName="Dashboard"
			subTitle={i18n.t("dashboard.subtitle")}
			renderCondition={summaryStats ? true : false}
			childrenExpanded={<CustomiseDashboard activeStats={active} inactiveStats={inactive} setInactiveStats={setInactive} setActiveStats={setActive} expanded={expanded} setExpanded={setExpanded}/>}
			setExpanded={setExpanded}
			expanded={expanded}
		>
			<ContainerCharts
				summary={summary}
				summaryStats={summaryStats}
				shouldRefresh={shouldRefresh}
				activeStats={active}
				inactiveStats={inactive}
				setInactiveStats={setInactive}
				setActiveStats={setActive}
				expanded={expanded}
				setExpanded={setExpanded}
			/>
		</Container>
	);
};

export default Dashboard;
