import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import Container from "common/components/Container/Container";
import { decodeJWT } from "common/functions";
import { SummaryResponse } from "services/utils/interfaces";
import i18n from "internationalization/i18n";
import { formatData } from "common/components/ConnectorsPieChart/utils/functions";
import ContainerCharts from "common/components/ContainerCharts/ContainerCharts";
import { useNavigate, useParams } from "react-router-dom";
import { stationsApi, useGetConnectorSummaryForStationQuery, useGetSummaryForStationQuery } from "services/stationsService";
import { emailToUsername } from "common/utils";
import Breadcrumb from "common/components/Breadcrumb/Breadcrumb";

const Stations: React.FC<{ shouldRefresh: boolean }> = ({ shouldRefresh }): React.ReactElement => {
	const { locationId,stations } = useParams();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { isLoggedIn } = useSelector((state: rootReducerType) => state.authenticationReducer);

	const data = useGetSummaryForStationQuery(
		{
			userName: emailToUsername(decodeJWT(isLoggedIn).email),
			locationId: locationId || ""
		})
		.data as SummaryResponse;

	const params = {
		userName: emailToUsername(decodeJWT(isLoggedIn).email),
	};

	const summary = formatData(useGetConnectorSummaryForStationQuery(
		{ ...params, locationId: locationId || "" }).data);

	const breadcrumbItems = {
		"simple":[
			{ label: i18n.t("breadcrumbs.station.stations"), route: "/stations" },
			{ label: locationId || "" },
		],
		"compare":[{ label: i18n.t("breadcrumbs.station.stations"), route: "/stations" },
			{ label: i18n.t("breadcrumbs.station.compareStations"),route:`/stations/compare/${stations}`},
			{ label: locationId || "" }]};

	useEffect(() => {
		dispatch(
			stationsApi.endpoints.getConnectorSummaryForStation.initiate(
				{ userName: params.userName, locationId: locationId || "" },
				{ subscribe: false, forceRefetch: true }
			)
		);
		dispatch(
			stationsApi.endpoints.getSummaryForStation.initiate(
				{ userName: params.userName, locationId: locationId || "" },
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [shouldRefresh, dispatch, params.userName, locationId]);

	return (
		<Container
			backButtonAction={() => stations?navigate(`/stations/compare/${stations}`):navigate("/stations")}
			loadingPageName={`Station ${locationId}`}
			renderCondition={data ? true : false}
			subTitle={<Breadcrumb items={stations?breadcrumbItems["compare"]:breadcrumbItems["simple"]} />}
		>
			<ContainerCharts
				summary={summary}
				summaryStats={data}
				locationId={locationId}
				shouldRefresh={shouldRefresh}
			/>
		</Container>
	);
};

export default Stations;
