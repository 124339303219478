/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse } from "antd";
import GrayButton from "common/components/StyledButtons/GrayButton/GrayButton";
import i18n from "internationalization/i18n";
import StationItem from "../stationItem/StationItem";
import { stations } from "pages/stations/utils/types";
import useWindowDimensions from "common/functions";
import { SelectedItems } from "pages/stations/utils/types";
import { canCompareStations,compareStations,noOfSelectedStations } from "pages/stations/utils/functions";
import "./StationsList.less";

const { Panel } = Collapse;

type Props = {
  data: stations[];
};

const StationsList: React.FC<Props> = ({data}): React.ReactElement => {
	const navigate= useNavigate();
	const { width } = useWindowDimensions();
	const [selectedItems, setSelectedItems] = useState<any>({});
	const [allChecked, setAllChecked] = useState(false);

	const handleCheck = (station: string) => {
		setSelectedItems({ ...selectedItems, [station]: !selectedItems[station] });
	};

	const changeAll = () => {
		const updatedState = {};
		if (allChecked)
			Object.keys(selectedItems).forEach((key) => {
				(updatedState as SelectedItems)[key] = false;
			});
		else
			Object.keys(selectedItems).forEach((key) => {
				(updatedState as SelectedItems)[key] = true;
			});
		setSelectedItems({ ...selectedItems, ...updatedState });
	};

	useEffect(() => {
		const updatedState = {};
		if (data) {
			data.forEach((element) => {
				(updatedState as SelectedItems)[element.id] = false;
			});
		}
		setSelectedItems(updatedState);
	}, [data]);

	useEffect(() => {
		//set allChecked state based on number of options selected at each step
		if (
			noOfSelectedStations(selectedItems) === Object.keys(selectedItems).length
		)
			setAllChecked(true);
		else setAllChecked(false);
	}, [selectedItems]);

	const DesktopView = () => (
		<>
			<div className="available-stations">
				<div className="title">
					<div>{i18n.t("stations.title")}</div>
				</div>
				<div className="stations-list">
					{data
						? Object.keys(selectedItems).length === Object.keys(data).length
							? data.map((object, index) => {
								return (
									<StationItem
										key={index.toString()}
										name={object.id}
										color={object.color}
										connectors={object.connectors}
										handleCheck={handleCheck}
										checked={selectedItems}
									/>
								);
							})
							: null
						: null}
				</div>
			</div>
			<div className="stations-actions">
				<GrayButton
					name={
						allChecked
							? i18n.t("stations.deselectAll")
							: i18n.t("stations.selectAll")
					}
					onClick={() => changeAll()}
				/>
				<GrayButton
					name={i18n.t("stations.compare")}
					className={
						canCompareStations(selectedItems)
							? "compare-active"
							: "compare-inactive"
					}
					onClick={() =>
						canCompareStations(selectedItems)
							? compareStations(selectedItems,navigate)
							: null
					}
					disabled={canCompareStations(selectedItems) ? false : true}
				/>
			</div>
		</>
	);

	const MobileView =() => (
		<>
			<Collapse>
				<Panel header="Compare stations" key={1}>
					<section className="stations-list">
						{data
							? Object.keys(selectedItems).length === Object.keys(data).length
								? data.map((object, index) => {
									return (
										<StationItem
											key={index.toString()}
											name={object.id}
											color={object.color}
											connectors={object.connectors}
											handleCheck={handleCheck}
											checked={selectedItems}
										/>
									);
								})
								: null
							: null}
					</section>
					<div className="stations-actions">
						<GrayButton
							name={
								allChecked
									? i18n.t("stations.deselectAll")
									: i18n.t("stations.selectAll")
							}
							onClick={() => changeAll()}
						/>
						<GrayButton
							name={i18n.t("stations.compare")}
							className={
								canCompareStations(selectedItems)
									? "compare-active"
									: "compare-inactive"
							}
							onClick={() =>
								canCompareStations(selectedItems)
									? compareStations(selectedItems,navigate)
									: null
							}
							disabled={canCompareStations(selectedItems) ? false : true}
						/>
					</div>
				</Panel>
			</Collapse>
		</>
	);

	return (
		<section className="station-list-container">
			{width > 768 ? DesktopView() : MobileView()}
		</section>
	);
};

export default StationsList;
