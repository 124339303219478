/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	combineProps,
	transResult,
	utilizationResult,
	revenueResult,
	reductionResult,
	combineList,
	powerResult,
} from "./types";
import { GenericResponse, GHGResponse, PowerResponse } from "services/utils/interfaces";

export const combineTransDetails = (props: combineProps) => {

	const { transactions, energy } = props;
	const result: combineList = {
		"": {
			transactions: 0,
			energy: 0,
			energyUnit: "kWh",
		},
	};

	if (transactions) {
		Object.keys(transactions).forEach((key) => {
			if (result[key]) {
				result[key] = { ...result[key], transactions: transactions[key] };
			} else
				result[key] = {
					transactions: transactions[key],
					energy: 0,
					energyUnit: "kWh",
				};
		});
	}

	if (energy) {
		Object.keys(energy).forEach((key) => {
			if (result[key]) {
				result[key] = {
					...result[key],
					energy: energy[key].energy,
					energyUnit: energy[key].energyUnit,
				};
			} else
				result[key] = {
					transactions: 0,
					energy: energy[key].energy,
					energyUnit: energy[key].energyUnit,
				};
		});
	}

	delete result[""];

	const final_result: Array<transResult> = [];

	Object.keys(result).forEach((key) => {
		final_result.push({
			time: key,
			"energy kWh": result[key].energy,
			transactions: result[key].transactions,
		});
	});

	return final_result;
};

export const formatData = (
	data: GenericResponse | GHGResponse | PowerResponse,
	type: "utilization" | "revenue" | "reduction" | "power",
) => {

	const isGenericResponse = (object: any): object is GenericResponse => {
		return true;
	};

	const isGHGResponse = (object: any): object is GHGResponse => {
		return typeof object[Object.keys(object)[0]] === "object";
	};

	const isPowerResponse = (object: any): object is PowerResponse => {
		return typeof object[Object.keys(object)[0]] === "object";
	};

	switch (type) {
		case "utilization":
			if (isGenericResponse(data)) {
				const utilization = [] as utilizationResult[];
				Object.keys(data).forEach((key) => {
					utilization.push({
						time: key,
						sessions: data[key],
					});
				});
				return utilization;
			}
			break;
		case "revenue":
			if (isGenericResponse(data)) {
				const revenue = [] as revenueResult[];
				Object.keys(data).forEach((key) => {
					revenue.push({
						time: key,
						revenue: data[key],
					});
				});
				return revenue;
			}
			break;
		case "reduction":
			if (isGHGResponse(data)) {
				const reduction = [] as reductionResult[];
				Object.keys(data).forEach((key) => {
					reduction.push({
						time: key,
						tons: data[key].GHG,
					});
				});
				return reduction;
			}
			break;
		case "power":
			if (isPowerResponse(data)) {
				const power = [] as powerResult[];
				Object.keys(data).forEach((key) => {
					power.push({
						time: key,
						power: data[key].power,
					});
				});
				return power;
			}
			break;
	}
};
