import React, { useEffect, useState } from "react";
import Container from "../../common/components/Container/Container";
import Breadcrumb from "../../common/components/Breadcrumb/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "../../internationalization/i18n";
import { Button, Modal } from "antd";
import {
	RightCircleOutlined,
	RedoOutlined,
	CloseOutlined
} from "@ant-design/icons";
import {
	useStartChargerMutation,
	useRebootChargerMutation,
	useStopChargerMutation,
	useGetChargerInfoQuery,
	useGetChargerTransactionsQuery, chargersApi,
} from "services/chargersService";
import { useDispatch, useSelector } from "react-redux";
import { rootReducerType } from "../../redux/combineReducers";
import useWindowDimensions, { counterCommonItemsArrays, decodeJWT, getDataToDisplay } from "../../common/functions";
import InfoSection from "./components/InfoSection/InfoSection";
import "./Charger.less";
import ChargerTransactionsTable
	from "./components/chargerTransactions/ChargerTransactionsTable";
import { capitalizeFirstLetter, emailToUsername } from "common/utils";
import Notification from "common/components/NotificationMessage/Notification";
import { ChargerTransactionItem } from "services/utils/interfaces";

type ChargerParams = {
	chargerId: string;
};

interface ILocation {
	locationIdStationId: string;
	status: string;
}

export type ChargerTransactions = Array<ChargerTransactionItem>;

const Chargers: React.FC<{ shouldRefresh: boolean }> = ({ shouldRefresh }): React.ReactElement => {
	const navigate = useNavigate();
	const location = useLocation();

	const { width } = useWindowDimensions();

	const { chargerId } = useParams<keyof ChargerParams>() as ChargerParams;
	const { status, locationIdStationId } = location.state as ILocation;

	const [startCharger, { isSuccess: startSuccess, data: startData }] = useStartChargerMutation();
	const [rebootCharger, { isSuccess: rebootSuccess, data: rebootData }] = useRebootChargerMutation();
	const [stopCharger, { isSuccess: stopSuccess, data: stopData }] = useStopChargerMutation();

	const { isLoggedIn } = useSelector((state: rootReducerType) => state.authenticationReducer);

	const [limit] = useState<number>(10);
	const [offset, setOffset] = useState<number>(0);
	const [previousOffset, setPreviousOffset] = useState<number>(offset);
	const [sort, setSort] = useState<string | null>(null);

	const [chargerTransactions, setChargerTransactions] = useState<ChargerTransactions>([]);

	const {data: chargerInfo, refetch: refetchChargerInfo} = useGetChargerInfoQuery({
		userName: decodeJWT(isLoggedIn).email,
		locationIdStationId,
		chargerId: chargerId
	}, { skip: locationIdStationId && chargerId ? false : true });

	let sortBy: string | undefined = undefined;
	const { data, refetch: refetchChargerTransactions } = useGetChargerTransactionsQuery({
		userName: decodeJWT(isLoggedIn).email,
		locationIdStationId,
		limit: String(limit),
		sortBy: sort || undefined,
		offset: String(offset < 0 ? 0 : offset),
		chargerId: chargerId
	}, { skip: locationIdStationId && chargerId ? false : true });

	const dispatch = useDispatch();

	useEffect(() => {
		if (offset < previousOffset) return;
		if (offset === previousOffset) {
			sortBy = sort || undefined;
			const tempOffset = offset < 0 ? 0 : offset;
			setOffset(tempOffset);
			setPreviousOffset(tempOffset);

			if (sort && locationIdStationId && chargerId) {
				dispatch(
					chargersApi.endpoints.getChargerTransactions.initiate(
						{
							userName: decodeJWT(isLoggedIn).email,
							locationIdStationId,
							limit: String(limit),
							sortBy,
							offset: String(tempOffset),
							chargerId: chargerId
						},
						{ subscribe: false, forceRefetch: true }
					)
				);
			}
		}
	}, [offset]);

	useEffect(() => {
		const tempOffset = offset <= 0 ? offset - 1 : 0;
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);
	}, [sort]);

	useEffect(() => {
		if (!data?.items) return;

		const tempOffset = offset < 0 ? 0 : offset;
		setOffset(tempOffset);
		setPreviousOffset(tempOffset);

		const counterCommonItems = counterCommonItemsArrays(data.items, chargerTransactions);

		const newItems = JSON.parse(JSON.stringify(
			offset <= 0
				? [...data.items]
				: (counterCommonItems === 0
					? [...chargerTransactions, ...data.items]
					: [...chargerTransactions]
				)));
		setChargerTransactions(newItems);
		setPreviousOffset(offset);
	}, [JSON.stringify(data?.items), offset]);

	useEffect(() => {
		if (locationIdStationId && chargerId) {
			refetchChargerInfo();
			refetchChargerTransactions();
		}
	}, [shouldRefresh]);
	const userName = decodeJWT(isLoggedIn).email;

	const breadcrumbItems = [
		{ label: i18n.t("breadcrumbs.charger.chargers"), route: "/chargers" },
		{ label: chargerId || "" },
	];

	const executeFunction = (action: string) => {
		if (!chargerId || !locationIdStationId) {
			return null;
		}

		switch (action) {
			case "start": {
				startCharger({
					userName: emailToUsername(userName),
					locationIdStationId,
					chargerId: chargerId
				});
				break;
			}
			case "reboot": {
				rebootCharger({
					userName: emailToUsername(userName),
					locationIdStationId,
					chargerId: chargerId
				});
				break;
			}
			case "stop": {
				stopCharger({
					userName: emailToUsername(userName),
					locationIdStationId,
					chargerId: chargerId
				});
				break;
			}
			default: break;
		}
	};

	const popUpModal = (action: string) => {

		const content = <div className="custom-content">
			{action === "reboot" ? <RedoOutlined style={{ color: "orange", transform: "scale(2.5)" }} /> :
				action === "start" ? <RightCircleOutlined style={{ color: "green", transform: "scale(2.5)" }} /> :
					action === "stop" ? <CloseOutlined style={{ color: "red", transform: "scale(2.5)" }} /> :
						null
			}
			<div className="content-title">{i18n.t("charger.popUpTitle", { action: action }).charAt(0).toUpperCase() + i18n.t("charger.popUpTitle", { action: action }).slice(1)}</div>
			<div className="content-description">{i18n.t("charger.popUpMessage", { action: action })}</div>
		</div>;

		Modal.confirm({
			className: "customModal",
			content: content,
			centered: true,
			okText: i18n.t("constants.confirm"),
			onOk: () => executeFunction(action),
			cancelText: i18n.t("constants.cancel")
		});
	};

	useEffect(() => {
		if (startSuccess)
			Notification({
				type: startData?.success ? "success" : "warning",
				message: i18n.t(startData?.success ? "charger.successModalTitle" : "charger.warningModalTitle"),
				description: startData?.success ? i18n.t("charger.successModalDesc", { action: "start" }) : capitalizeFirstLetter(startData?.message || "")
			});
	}, [startSuccess]);

	useEffect(() => {
		if (stopSuccess)
			Notification({
				type: stopData?.success ? "success" : "warning",
				message: i18n.t(stopData?.success ? "charger.successModalTitle" : "charger.warningModalTitle"),
				description: stopData?.success ? i18n.t("charger.successModalDesc", { action: "stop" }) : capitalizeFirstLetter(stopData?.message || "")
			});
	}, [stopSuccess]);

	useEffect(() => {
		if (rebootSuccess)
			Notification({
				type: rebootData?.success ? "success" : "warning",
				message: i18n.t(rebootData?.success ? "charger.successModalTitle" : "charger.warningModalTitle"),
				description: rebootData?.success ? i18n.t("charger.successModalDesc", { action: "reboot" }) : capitalizeFirstLetter(rebootData?.message || "")
			});
	}, [rebootSuccess]);

	return width > 768 ? (
		<Container
			backButtonAction={() => navigate("/chargers")}
			styleNoFlex={true}
			loadingPageName={`${i18n.t("charger.charger")} ${chargerId}`}
			subTitle={<Breadcrumb items={breadcrumbItems} />}
			actionsRight={<div style={{ marginTop: "16px" }}>
				<Button className="charger-button" onClick={() => popUpModal("start")}>
					<RightCircleOutlined style={{ color: "green" }} />{i18n.t("charger.startCharger")}
				</Button>
				<Button className="charger-button" onClick={() => popUpModal("reboot")}>
					<RedoOutlined style={{ color: "orange" }} />{i18n.t("charger.rebootCharger")}
				</Button>
				<Button className="charger-button" onClick={() => popUpModal("stop")}>
					<CloseOutlined style={{ color: "red" }} />{i18n.t("charger.stopCharger")}
				</Button>
			</div>}
		>
			<div style={{ height: "100%", width: "90vw" }}>
				{
					chargerInfo && chargerInfo && chargerId && status ? (
						<section className="charger-container-border">
							<InfoSection
								data={chargerInfo}
								shouldRefresh={shouldRefresh}
								chargerId={chargerId}
								status={status} />
						</section>
					)
						: null
				}
				{
					chargerTransactions && chargerId && status ? (
						<div className="charger-transactions-table" >
							<ChargerTransactionsTable
								limit={limit}
								offset={offset < 0 ? 0 : offset}
								setOffset={setOffset}
								length={chargerTransactions.length + ((data?.items.length || 0) < limit ? 0 : 1)}
								data={getDataToDisplay(chargerTransactions, limit, offset)}
								setNewSort={setSort}
							/>
						</div>
					)
						: null
				}
			</div>
		</Container>) : (
		<Container
			backButtonAction={() => navigate("/chargers")}
			loadingPageName={`Charger ${chargerId}`}
			subTitle={<Breadcrumb items={breadcrumbItems} />}
		>
			<div className="charger">
				<Button className="charger-button" onClick={() => popUpModal("start")}>
					<RightCircleOutlined style={{ color: "green" }} />{i18n.t("charger.startCharger")}
				</Button>
				<Button className="charger-button" onClick={() => popUpModal("reboot")}>
					<RedoOutlined style={{ color: "orange" }} />{i18n.t("charger.rebootCharger")}
				</Button>
				<Button className="charger-button" onClick={() => popUpModal("stop")}>
					<CloseOutlined style={{ color: "red" }} />{i18n.t("charger.stopCharger")}
				</Button>
				{
					chargerInfo && chargerInfo && chargerId && status ? (
						<div className="charger-container-border">
							<InfoSection
								data={chargerInfo}
								shouldRefresh={shouldRefresh}
								chargerId={chargerId}
								status={status} />
						</div>
					)
						: null
				}
				<div style={{ height: "100%", width: "90vw", marginTop: "-10px" }}>
					{
						chargerTransactions && chargerId && status ? (
							<div className="charger-transactions-table">
								<ChargerTransactionsTable
									limit={limit}
									offset={offset < 0 ? 0 : offset}
									setOffset={setOffset}
									length={chargerTransactions.length + ((data?.items.length || 0) < limit ? 0 : 1)}
									data={getDataToDisplay(chargerTransactions, limit, offset)}
									setNewSort={setSort}
								/>
							</div>
						)
							: null
					}
				</div>
			</div>
		</Container>
	);
};

export default Chargers;
