import React from "react";
import { useNavigate } from "react-router-dom";
import "./StationItem.less";

type Props = {
  name: string;
  connectors: number;
  color: string;
  handleCheck: (station:string)=>void;
  checked: { [key: string]: boolean };
};

const StationItem = ({
  name,
  connectors,
  color,
  handleCheck,
  checked,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div className="station-item">
      <div
        className="item-details"
        onClick={() => navigate(`/stations/${name}`)}
      >
        <div className="item-details-first-row">
          <div className="item-color" style={{ backgroundColor: color }}></div>
          <div className="item-title">{name}</div>
        </div>
        <div className="item-details-second-row">
          {`${connectors} Connectors`}
        </div>
      </div>
      <input
        type="radio"
        checked={checked[name]}
        onClick={() => {
          handleCheck(name);
        }}
        style={{ width: "15%" }}
      />
    </div>
  );
};

export default StationItem;
