import React, { useEffect } from "react";
// import Logo from "common/components/Logo/Logo";
import { ReactComponent as GreenTick } from "assets/green-tick.svg";
import i18n from "internationalization/i18n";
import DarkBlueButton from "common/components/StyledButtons/DarkBlueButton/DarkBlueButton";
import {ReactComponent as ExportSVG} from "assets/export-success.svg";
import useWindowDimensions from "common/functions";
import fileDownload from "js-file-download";
import "./ExportSuccess.less";

type Props = {
	format: string;
    exportLink: string;
	closeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExportSuccess: React.FC<Props> = ({ exportLink, closeModal, format }) => {

	const { width } = useWindowDimensions();

	// useEffect(()=> {
	// 	if(format ==="csv") fileDownload(exportLink, `swiftcharge-statistics-data.${format}`);
	// },[exportLink]);

	const getDesktopSuccess = () => (
		<div className="export-success-container">
			{/* <Logo /> */}
			<section className="export-success-details">
				<div className="export-success-details-left">
					<GreenTick />
					<div className="title">
						{i18n.t("exportModal.successTitle")}
					</div>
					<div className="subtitle">
						<div>All times are in UTC.</div>
						<a href={exportLink} target="_blank" rel="noreferrer">{`Click here to access your ${format} file.`}</a>
					</div>
					<DarkBlueButton name={i18n.t("exportModal.close")} onClick={() => closeModal(false)}/>
				</div>
				<ExportSVG width='60%' height='80%' />
			</section>
		</div>
	);

	const getMobileSuccess = () => (
		<div className="export-success-container">
			<section className="export-success-details">
				<GreenTick />
				<div className="title">
					{i18n.t("exportModal.successTitle")}
				</div>
				<div className="subtitle">
					<div>All times are in UTC.</div>
					<a href={exportLink} target="_blank" rel="noreferrer">{`Click here to access your ${format} file.`}</a>
				</div>
				<DarkBlueButton name={i18n.t("exportModal.close")} onClick={() => closeModal(false)}/>
			</section>
		</div>
	);

	return ( width > 767 ? getDesktopSuccess() : getMobileSuccess()
  );
};

export default ExportSuccess;
