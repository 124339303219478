/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Button } from "antd";
import React from "react";
import { errorDetails, errorType, formType } from "./types";

export const newError = (
	error: errorDetails,
	setState: React.Dispatch<React.SetStateAction<any>>,
	action?: string
) => {
	setState({
		error_details: error,
		error_action: action,
	});
};

export const renderAlert = (
	errorState: errorType,
	formType?: formType,
	setState?: (type: string) => void
) => {
	if (
		typeof errorState.error_details.error_type === "string" &&
    ["warning", "error"].includes(errorState.error_details.error_type) &&
    errorState.error_action === undefined
	) {
		return (
			<Alert
				message={errorState.error_details.error_message}
				type={errorState.error_details.error_type}
				style={{
					marginBottom: "2vh",
					height: "fit-content",
					borderRadius: "5px",
					fontWeight: "500",
				}}
			/>
		);
	} else if (setState) {
		return (
			<Alert
				message={errorState.error_details.error_message}
				type={errorState.error_details.error_type}
				style={{
					marginBottom: "2vh",
					height: "fit-content",
					borderRadius: "5px",
					fontWeight: "500",
				}}
				action={
					<Button type="ghost" onClick={() => { if(formType?.type) setState(formType?.type); }}>
						{errorState.error_action}
					</Button>
				}
			/>
		);
	}
};
