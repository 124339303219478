import { createApi } from "@reduxjs/toolkit/query/react";
import { cacheDurationSeconds } from "common/constants";
import { ISupportForm } from "redux/utils/interfaces";
import { baseQueryWithReauth } from "./utils/constants";

export const supportApi = createApi({
	reducerPath: "support",
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: cacheDurationSeconds,
	endpoints: (builder) => ({
		saveFromData: builder.mutation<void, ISupportForm>({
			query: (data) => ({
				url: "send-support-message",
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const { useSaveFromDataMutation } =
	supportApi;

