import { errorList, errorType, errorDetails, formList, formType } from "./types";
import i18n from "../../../internationalization/i18n";

export const formTypes: formList<string, formType> = {
	"sign-in": { type: "sign-in", title: "Log In", subtitle: "" },
	"send-reset-password": {
		type: "send-reset-password",
		title: i18n.t("passwordSecurity.resetPassword"),
		subtitle: i18n.t("constants.enterEmail"),
	}
};

export const initialErrorState: errorType = {
	error_details: {
		error_type: undefined,
		error_message: "",

	},
	error_action: ""

};

export const errorMessages: errorList<string, errorDetails> = {
	ExpiredCodeException: {
		error_type: "error",
		error_message: i18n.t("constants.codeExpired"),
	},
	InternalErrorException: {
		error_type: "error",
		error_message: i18n.t("constants.internalError"),
	},
	InvalidCredentials: {
		error_type: "error",
		error_message: i18n.t("constants.wrongEmailPassword"),
	},
	LimitExceededException: {
		error_type: "error",
		error_message: i18n.t("constants.limitExceeded")
	},
	LambdaValidation: {
		error_type: "error",
		error_message: i18n.t("constants.lambdaValidation"),
	},
	InvalidCode: {
		error_type: "error",
		error_message: i18n.t("constants.invalidVerifCode")
	},
	ExpiredCode: {
		error_type: "error",
		error_message: i18n.t("constants.expiredVerifCode")
	},
	UsernameExists: {
		error_type: "error",
		error_message: i18n.t("constants.usernameExists")
	}
};

export const warningMessages: errorList<string, errorDetails> = {
	CodeMismatchException: {
		error_type: "warning",
		error_message: i18n.t("constants.wrongCode"),
	},
	NoPassword: {
		error_type: "warning",
		error_message: i18n.t("constants.noPassword"),
	},
	NoEmail: {
		error_type: "warning",
		error_message: i18n.t("constants.validEmail")
	},
	NoConfirmPass: {
		error_type: "warning",
		error_message: i18n.t("constants.noConfirmedPassword"),
	},
	NoVerifCode: {
		error_type: "warning",
		error_message: i18n.t("constants.noCode"),
	},
	LoginFields: {
		error_type: "warning",
		error_message: i18n.t("constants.validEmailPassword"),
	},
	VerificationFields: {
		error_type: "warning",
		error_message: i18n.t("constants.validEmailVerifCode"),
	},
	ConfirmPassFields: {
		error_type: "warning",
		error_message: i18n.t("constants.validEmailCode"),
	},
	DiffPass: {
		error_type: "warning",
		error_message: i18n.t("constants.passwordNotMatched"),
	},
	VerifyFields: {
		error_type: "warning",
		error_message: i18n.t("constants.noMailAndCode")
	},
	ResetPasswordFields: {
		error_type: "warning",
		error_message: i18n.t("constants.resetPassword"),
	},
	NoOldPassword: {
		error_type: "warning",
		error_message: i18n.t("constants.noOldPassword"),
	},
	NoNewPassword: {
		error_type: "warning",
		error_message: i18n.t("constants.noNewPassword"),
	},
	NoConfirmationPassword: {
		error_type: "warning",
		error_message: i18n.t("constants.noConfirmationPassword"),
	},
	PasswordsNoMatch: {
		error_type: "warning",
		error_message: i18n.t("constants.passwordsNoMatch"),
	},
	PasswordFormatIncorect: {
		error_type: "warning",
		error_message: i18n.t("constants.incorrectPassFormat")
	},
	OldPasswordIncorrect: {
		error_type: "warning",
		error_message: i18n.t("constants.oldPasswordIncorrect")
	}
};
