import React from "react";
import "./DarkBlueButton.less";
import { Button, ButtonProps } from "antd";

const DarkBlueButton: React.FC<ButtonProps> = (props) => {
	return (
		<Button onClick={props.onClick} className={`dark-button ${props.className}`} icon={props.icon} style={props.style} htmlType={props.htmlType} disabled={props.disabled}>
			{props.name}
		</Button>
	);
};

export default DarkBlueButton;
