import React, {useEffect} from "react";
import { Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./ProfilePopup.less";
import { logout } from "services/authenticationService";
import { setLogout } from "redux/authentication/actions";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import {profileApi, useGetOperatorProfileQuery} from "services/profileService";
import { emailToUsername, usernameToEmail, getFullName } from "common/utils";
import { useTranslation } from "react-i18next";
import DarkBlueButton from "../StyledButtons/DarkBlueButton/DarkBlueButton";
import SmallGrayButton from "../StyledButtons/SmallGrayButton/SmallGrayButton";

interface IProfilePopup {
	isVisible: boolean;
	handleChangeVisibility: (visiblity: boolean) => void;
	setTabIndex: React.Dispatch<React.SetStateAction<string[] | undefined>>,
	showIcon?: boolean;
	shouldRefresh?: boolean;
	setTabProfile: (value: string) => void;
}

const ProfilePopup: React.FC<IProfilePopup> = ({
	isVisible,
	handleChangeVisibility,
	setTabIndex,
	showIcon = true,
	shouldRefresh,
	setTabProfile
}): React.ReactElement => {

	const { t } = useTranslation();
	const { email } = useSelector((state: rootReducerType) => state.authenticationReducer);
	const userName = emailToUsername(email);

	const { data, isSuccess } = useGetOperatorProfileQuery(userName);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			profileApi.endpoints.getOperatorProfile.initiate(
				userName,
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [ shouldRefresh, dispatch, userName ]);

	const navigate = useNavigate();

	const handleEditProfileClick = () => {
		setTabIndex(undefined);
		setTabProfile("1");
		handleChangeVisibility(false);
		navigate("/profile");
	};

	const handleSignOut = () => {
		logout();
		dispatch(setLogout());
		navigate("/");
	};

	const getContent = () => (
		<div className='profile-popup-content'>
			<DarkBlueButton
				icon={<UserOutlined style={{ fontSize: "2rem" }} />}
				style={{ height: "5rem", width: "5rem", fontSize: "5rem", borderRadius: "5rem" }}
			/>
			<div className='name'>{getFullName(data.firstName, data.lastName)}</div>
			<div className='email'>{usernameToEmail(data.email)}</div>
			<SmallGrayButton size='small' onClick={() => handleEditProfileClick()} name={t("profile.editProfile")} />
			<DarkBlueButton onClick={handleSignOut} name={t("profile.signOut")} />
		</div>
	);

	return (
		isSuccess ?
			<Popover
				content={getContent()}
				trigger="click"
				visible={isVisible}
				onVisibleChange={handleChangeVisibility}
				arrowContent=''
			>
				< DarkBlueButton
					icon={<UserOutlined />}
					style={{
						borderRadius: 25,
						border: "4px solid rgb(207, 207, 207)",
						visibility: showIcon ? "unset" : "hidden",
						position: showIcon ? "unset" : "absolute"
					}}
				/>
			</Popover>
			: <></>
	);
};

export default ProfilePopup;
