import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "common/components/Breadcrumb/Breadcrumb";
import Container from "common/components/Container/Container";
import i18n from "internationalization/i18n";
import { useGetStationsComparisonQuery } from "services/stationsService";
import CompareTable from "./components/compareTable/CompareTable";

type Props = {
    shouldRefresh: boolean,
};

const CompareStations: React.FC<Props> = ({ shouldRefresh }): React.ReactElement =>  {
	const {stations} = useParams();
	const navigate = useNavigate();

	const breadcrumbItems = [
		{ label: i18n.t("breadcrumbs.station.stations"), route: "/stations" },
		{ label: stations?i18n.t("breadcrumbs.station.compareStations"): "" },
	];

	const {data,refetch} = useGetStationsComparisonQuery({stations:stations||""});
	
	useEffect(()=>refetch(),[shouldRefresh]);

	return (
		<Container
			backButtonAction={() => navigate("/stations")}
			loadingPageName={i18n.t("compareStations.loadingPageName")}
			renderCondition={data ? true : false}
			subTitle={<Breadcrumb items={breadcrumbItems} />}
		>
			{data?<CompareTable data={data}/>:null}
		</Container>
	);
};

export default CompareStations;