import React from "react";
import { DatePicker } from "antd";
import useWindowDimensions from "common/functions";
import i18n from "internationalization/i18n";
import moment from "moment";
import "./ExportPeriod.less";
import { OneWeekInMilliseconds } from "common/constants";

const { RangePicker } = DatePicker;

type Props = {
    setExportDate: React.Dispatch<React.SetStateAction<{startDate:string,endDate:string;}>>
};

const ExportPeriod: React.FC<Props> = ({ setExportDate }) => {

    const { width } = useWindowDimensions();

    const changeDate = (dateString:[string,string]) => {
        setExportDate({ startDate: moment.utc(dateString[0]).startOf("day").format().toString(), endDate: moment.utc(dateString[1]).endOf("day").format().toString() });
    };

    const getExportPeriodDesktop = () => (
        <div className="export-modal-period">
          <h4>{i18n.t("exportModal.period")}</h4>
          <div className="export-section-container">
          <RangePicker
            className="export-range-picker"
            style={{ width: "30%", border: "0" }}
            format="YYYY-MM-DD"
            suffixIcon
            defaultValue={[
              moment(
                new Date(Date.now() - OneWeekInMilliseconds)
                  .toISOString()
                  .slice(0, 10),
                "YYYY-MM-DD"
              ),
              moment(new Date().toISOString().slice(0, 10), "YYYY-MM-DD"),
            ]}
            onChange={(value, dateString) => changeDate(dateString)}
          />
          </div>
        </div>
    );

    const getExportPeriodMobile = () => (
        <div className="export-modal-period">
          <h2>{i18n.t("exportModal.period")}</h2>
          <div className="export-section-container">
          <RangePicker
            className="export-range-picker"
            style={{ width: "30%", border: "0" }}
            format="YYYY-MM-DD"
            suffixIcon
            defaultValue={[
              moment(
                new Date(Date.now() - OneWeekInMilliseconds)
                  .toISOString()
                  .slice(0, 10),
                "YYYY-MM-DD"
              ),
              moment(new Date().toISOString().slice(0, 10), "YYYY-MM-DD"),
            ]}
            onChange={(value, dateString) => changeDate(dateString)}
          />
          </div>
        </div>
    );

    return (
        <>
          { width > 768 ? getExportPeriodDesktop() : getExportPeriodMobile() }
        </>
    );
};

export default ExportPeriod;
