/* eslint-disable @typescript-eslint/no-explicit-any */
import { authenticationReducerType, reduxActionType } from "../utils/types";

const initialState = {
	"current_form":"sign-in",
	"email":"",
	"otp":"",
	"new_password":"",
	"isLoggedIn": "true"
};

export const authenticationReducer = (
	state = initialState,
	action: reduxActionType
): authenticationReducerType => {
	switch (action.type) {
	case "SET_LOGIN": {
		return {
			...state,...action.payload
		};
	}
	case "SET_FORM_DATA": {
		return {
			...state,...action.payload
		};
	}
	case "SET_LOGOUT": {
		return {
			...initialState
		};
	}
	case "SET_FORM_STEP": {
		return {
			...state,...action.payload
		};
	}
	default:
		return state;
	}
};