import React from "react";
import Container from "../../common/components/Container/Container";
import i18n from "../../internationalization/i18n";
import CardList from "./components/cardList/CardList";
import SendMessageForm from "./components/sendMessageFrom/SendMessageFrom";
import "./Support.less";
import cardsObj from "./cards.json";
import useWindowDimensions from "../../common/functions";

const Support = () => {
	const {width} = useWindowDimensions();

	const cards = cardsObj.cards;

	return (
		<>
			{width > 768 ? (
				<Container
					backButton={false}
					loadingPageName={i18n.t("support.pageName")}
					subTitle={i18n.t("support.subtitle")}
					renderCondition={true}
					styleNoFlex={true}
				>
					<div className="support-no-flex">
						<section className="support-container">
							{/* <div className="support-cards-container">
								<CardList data={cards}/>
							</div> */}
							<div className="send-message-form">
								<SendMessageForm/>
							</div>
						</section>
					</div>
				</Container>
			) : (
				<Container
					backButton={false}
					loadingPageName={i18n.t("support.pageName")}
					subTitle={i18n.t("support.subtitle")}
					renderCondition={true}
					styleNoFlex={true}
				>
					<section className="support-container">
						{/* <div className="support-cards-container">
							<CardList data={cards}/>
						</div> */}
					</section>
					<div className="send-message-form">
						<SendMessageForm/>
					</div>
				</Container>
			)
			}
		</>
	);

};

export default Support;
