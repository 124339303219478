import React from "react";
import { IMarker } from "redux/utils/interfaces";
import { StationsResponse } from "services/utils/interfaces";
import { NavigateFunction } from "react-router-dom";
import { ComparisonResponse } from "services/utils/interfaces";
import { grayBackground, headerInitial } from "./constants";
import { ColumnType, SelectedItems } from "./types";

const parseLocation = (geolocation:string) => {
	return {
		latitude:parseFloat(geolocation.substr(0,geolocation.indexOf(","))),
		longitude: parseFloat(geolocation.substr(geolocation.indexOf(",")+2)),
	};
}; 

export const stationsGeoLocation = (data: StationsResponse) => {
	const stations: IMarker[] = [];
	if (data) 
		Object.keys(data).forEach((key) => {
			const randColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
			const location = parseLocation(data[key].geolocation);
			stations.push({
				id: key,
				connectors: data[key].connectorsNum,
				latitude: location.latitude,
				longitude: location.longitude,
				color: randColor,
			});
		});
	return {stations: stations};
};

export const noOfSelectedStations = (stations: SelectedItems) => {
	let number = 0;
	Object.keys(stations).forEach((key) => {
		if (stations[key]) number += 1;
	});

	return number;
};

export const canCompareStations = (stations: SelectedItems) => {
	if (noOfSelectedStations(stations) > 1) return true;
	return false;
};

export const compareStations = (stations: SelectedItems,navigate:NavigateFunction) => {
	let stationsToCompare = "";
	Object.keys(stations).forEach((key) => {
		if(stationsToCompare === "") 
			stationsToCompare += key;
		else stationsToCompare += ","+key;
	});
	navigate(`compare/${stationsToCompare}`);
};


export const computeRowsAndCols = (data:ComparisonResponse,navigate:NavigateFunction) => {
    
	const columns:ColumnType[]= [{title:"",dataIndex:"description",colSpan:2,fixed:"left",width:100, onCell: (_, index) => {
		if (index === 1) 
			return { rowSpan: 2,style:grayBackground };
		else if(index === 2) 
			return { rowSpan:0,style:grayBackground};
		return {rowSpan:1,colSpan:2,style:grayBackground};
	}},
	{title:"",width:100,fixed:"left",dataIndex:"subDescription", colSpan:0,onCell: (_, index) => {
		if(index === 1 || index ===2)
			return {colSpan:1,style:grayBackground};
		return {rowSpan:1,colSpan:0};
	}}];
	
	const rows = headerInitial;

	Object.keys(data).forEach((key)=> {
		columns.push({
			title:<div style={{color:"blue",cursor:"pointer"}} onClick={()=>navigate(`${key}`)}>{key}</div>,
			dataIndex:key,
			colSpan:1,
			width:200,
			align:"center",
			onCell:() => {
				return {rowSpan:1};
			},
		});
	});
	Object.keys(data).forEach((key)=> {
		const values = Object.values(data[key]);
		rows.map((row,index)=> {
			if(index === 0 ){
				row[key] = "$ " + values[index];
			}
			else if([5,6,8].includes(index)) {
				row[key] = values[index] + " " + values[index+1];
				values.splice(index,1);
			}
			else row[key] = values[index];
		});
	});

	return {
		columns:columns,
		rows:rows
	};
};
