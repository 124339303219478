export const resources = {
	en: {
		translation: {
			container: {
				edit: "Edit",
				save: "Save",
			},

			header: {
				dashboard: "Dashboard",
				stations: "Stations",
				chargers: "Chargers",
				transactions: "Transactions",
				refresh: "Refresh",
				export: "Export Data",
				support: "Support",
				email: "E-mail",
				profile: "Profile",
			},

			loadingScreen: {
				logoNotFound: "Logo not found",
				loading: "Loading the",
			},

			profile: {
				editProfile: "Edit profile",
				signOut: "Sign out",
				profileTab: "Profile",
				notificationTab: "Notifications",
				passSecurityTab: "Password & Security",
				toastTitle: "Saved successfully",
				toastProfileSave: "Your new profile data was saved.",
				toastPortalSave: "The new portal name was saved."
			},

			success: {
				accountCreated: "Your Swift Charge account was successfully created",
				start: "Let's get started",
			},

			confirmPassword: {
				yourPassword: "Your password",
				repeatPassword: "Repeat your password",
				signUp: "Sign Up",
			},

			logIn: {
				email: "E-mail",
				password: "Password",
				troubleLoggingIn: "Trouble logging in?",
				logIn: "Log in",
				signUp: "Sign up",
			},

			resetPassword: {
				forgotEmail: "Forgot email?",
				email: "E-mail",
				contactService: "Contact Customer Service",
				submit: "Submit",
				signIn: "Sign in",
			},

			verifyEmail: {
				email: "Email",
				verificationCode: "Verification Code",
				accessCode: "Access code",
				customerService: "Contact Customer Service.",
				verifyAccount: "Verify account",
				signUp: "Sign Up",
				signIn: "Sign In",
				formTitle: "Verify your email address",
				formSubtitle: "Please provide the verification code that you received on your e-mail address"
			},

			constants: {
				verificationCodeAndEmail:
					"Please enter your email and the verification code that you received from Swift Charge.",
				enterEmail: "Enter your email to receive instructions.",
				setPassword: "Please set up your password for Swift Charge Dashboard.",
				codeExpired:
					"The code has expired. Please contact the administrator to generate a new one or manually generate a new one in Reset password section.",
				expiredVerifCode: "The verification code has expired.",
				invalidVerifCode: "The verification code is invalid.",
				internalError: "There is an internal error. Please try again later.",
				wrongEmailPassword: "Your email or password is wrong.",
				limitExceeded: "Attempt limit exceeded, please try after some time.",
				wrongCode: "The provided code is not correct. Please try again.",
				validEmail: "Please enter a valid email address",
				noConfirmedPassword: "Please complete the confirm password field.",
				noCode:
					"Please provide the verification code that was sent to you by e-mail.",
				validEmailPassword: "Please enter a valid email address and password",
				validEmailCode:
					"Please enter a valid e-mail address, verification code and password",
				passwordNotMatched: "The passwords don't match.",
				noPassword: "Please complete the password field.",
				noMailAndCode: "Please complete all fields",
				troubleSignUp: "Trouble sign up?",
				resetPassword: "Please enter valid passwords",
				noOldPassword: "Please complete the old password field.",
				noNewPassword: "Please complete the new password field.",
				oldPasswordIncorrect: "Old password is incorrect.",
				noConfirmationPassword: "Please complete the confirm password field.",
				passwordsNoMatch: "New password does not match the one from the confirmation field.",
				lambdaValidation: "The access code is incorrect. Please contact the administrator to get a new one.",
				validEmailVerifCode: "Please insert a valid email address and verification code.",
				usernameExists: "There already exists an account associated with this e-mail address. Please try another one.",
				incorrectPassFormat: "Password must be at least 8 characters long and must contain at least a lowercase and an uppercase letter, a number and a special character.",
				passFormatHint: " Password must be at least 8 characters long and must contain at least a lowercase and an uppercase letter, a number and a special character.",
				yes: "Yes",
				no: "No",
				confirm: "Confirm",
				cancel: "Cancel"
			},
			dashboard: {
				subtitle: "Dashboard",
				ConnectorsTitle: "Connectors",
				customise: "Customise",
				activeStats: "Active Stats",
				inactiveStats: "Inactive Stats",
				save: "Save",
				toastTitle: "Saved successfully",
				toastCustomisationSave: "The summary stats list has been updated.",
			},
			stations: {
				title: "Stations",
				selectAll: "Select all",
				compare: "Compare",
				deselectAll: "Deselect all",
			},
			compareStations: {
				loadingPageName: "Compare Stations"
			},
			breadcrumbs: {
				station: {
					stations: "Stations",
					compareStations: "Compare Stations"
				},
				charger: {
					chargers: "Chargers"
				}
			},
			support: {
				subtitle: "Support",
				pageName: "Support"
			},
			sendMessageForm: {
				sendMessage: "Send us a message",
				subject: "Subject",
				yourMessage: "Your message",
				save: "Save",
				other: "Other",
				account: "Account",
				charger: "Charger",
				management: "Management",
				dashboardIssues: "Dashboard issues",
				maintenanceRequest: "Maintenance request",
				required: "This field is required",
				toastTitle: "Message sent",
				toastSendMessage: "Your message has been sent successfully.",
			},
			detailsCharts: {
				transactions: "Transactions & energy",
				utilization: "Utilization",
				revenue: "Revenue",
				reduction: "GHG Reduction",
				power: "Power"
			},
			chargers: {
				subtitle: "Chargers",
				pageName: "Chargers",
				filterBy: "Filter by",
				status: "Status",
				type: "Type",
				station: "Station",
				chargerId: "Charger ID",
				location: "Location",
				sessions: "Sessions ",
				energy: "Energy kWh ",
				revenue: "Revenue $ ",
				pricingPlan: "Pricing Plan",
				total: "Total",
				available: "Available",
				inUse: "In use",
				unavailable: "Unavailable",
				offline: "Offline",
				faulted: "Faulted",
				level2: "Level 2",
				dcfc: "DCFC",
			},
			charger: {
				charger: "Charger",
				vendor: "VENDOR",
				maxPower: "MAX POWER",
				serialNum: "SERIAL NUMBER",
				model: "MODEL",
				Type: "TYPE",
				lastHeartBeat: "LAST HEARTBEAT",
				connectorId: "Connector ID",
				transactionId: "Transaction ID",
				userId: "User ID",
				startTime: "Start time ",
				endTime: "End time ",
				length: "Session length ",
				energy: "Energy kWh ",
				startCharger: "Start charger",
				rebootCharger: "Reboot charger",
				stopCharger: "Stop charger",
				popUpTitle: "{{action}} charger?",
				popUpMessage: "This action will {{action}} the charger",
				successModalTitle: "Success",
				successModalDesc: "The charger has been successfully {{action}}ed",
				warningModalTitle: "Warning"
			},
			transactions: {
				subtitle: "Transactions",
				pageName: "Transactions",
				transactionId: "Transaction ID",
				userId: "User ID",
				station: "Station",
				chargerId: "Charger ID",
				type: "Type",
				startTime: "Start Time ",
				endTime: "End Time ",
				energy: "Energy kWh ",
				revenue: "Revenue ",
				pricingPlan: "Pricing Plan",
				resetFilters: "Reset filters",
				level2: "Level 2",
				dcfc: "DCFC",
			},
			exportModal: {
				title: "Export data from Swift Charge Dashboard",
				subtitle: "Please choose exporting format for data",
				exportType: "Export type",
				period: "Period",
				format: "Format",
				availableStations: "Available Stations",
				pdfFormat: "PDF Format (Coming Soon)",
				csvFormat: "CSV Format",
				transactionAllStations: "Transaction history of all stations",
				transactionSpecificStation: "Transaction history of a station",
				export: "Export",
				previousStep: "Previous Step",
				nextStep: "Next Step",
				close: "Close",
				successTitle: "Your data succesufully exported.",
				failTitle: "An error has occured while exporting your data."
			},
			notifications: {
				comingSoon: "Coming soon..."
			},
			passwordSecurity: {
				oldPassword: "Old password",
				newPassword: "New password",
				confirmPassword: "Confirm password",
				resetPassword: "Reset password",
				passwordChanged: "Your password was changed."
			}
		},
	},
};
