import React from "react";
import Logo from "common/components/Logo/Logo";
import "./AuthContainer.less";

interface IAuchTcontainer {
	title: string;
	subtitle?: string;
	children: React.ReactNode;
}

const AuthContainer: React.FC<IAuchTcontainer> = ({ title, subtitle, children }) => {
	return (
		<section className="authentication-container">
			<img
				alt="logo"
				src={require("../../../../assets/login-image.png")}
			/>
			<Logo />

			<section className="auth-details-container">

				<section className="auth-details">
					<div className="title">
						{title}</div>
					<div className="subtitle">{subtitle}</div>
					{children}
					<div className="credits">© 2024 By Swift Charge Inc.</div>
				</section>
			</section>
		</section>
	);
};

export default AuthContainer;
