import { createApi } from "@reduxjs/toolkit/query/react";
import { cacheDurationSeconds } from "common/constants";
import { baseQueryWithReauth } from "./utils/constants";
import {
	DashboardParams,
	EnergyResponse,
	GenericResponse,
	GHGResponse,
	PowerResponse,
	SummaryResponse,
} from "./utils/interfaces";

const getGenericURLParams = (args: DashboardParams) =>
	`userName=${args.userName}`;

const getExtendedURLParams = (args: DashboardParams) =>
	`${getGenericURLParams(args)}&startDate=${args.startDate}&endDate=${args.endDate
	}`;

export const dashboardApi = createApi({
	reducerPath: "dashboard",
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: cacheDurationSeconds,
	tagTypes: ["SummaryStats"],
	endpoints: (builder) => ({
		getConnectorSummary: builder.query<GenericResponse, DashboardParams>({
			query: (args) => ({
				url: `get-connector-summary-for-all?${getGenericURLParams(args)}`,
			}),
		}),
		getSummaryStats: builder.query<SummaryResponse, DashboardParams>({
			query: (args) => ({
				url: `get-summary-stats-for-all?${getGenericURLParams(args)}`,
			}),
			providesTags: ["SummaryStats"]
		}),
		getEnergyDetails: builder.query<EnergyResponse, DashboardParams>({
			query: (args) => ({
				url: `get-energy-for-all?${getExtendedURLParams(args)}`,
			}),
		}),
		getTransactionDetails: builder.query<GenericResponse, DashboardParams>({
			query: (args) => ({
				url: `get-transaction-for-all?${getExtendedURLParams(args)}`,
			}),
		}),
		getUtilizationDetails: builder.query<GenericResponse, DashboardParams>({
			query: (args) => ({
				url: `get-utilization-for-all?${getExtendedURLParams(args)}`,
			}),
		}),
		getRevenueDetails: builder.query<GenericResponse, DashboardParams>({
			query: (args) => ({
				url: `get-revenue-for-all?${getExtendedURLParams(args)}`,
			}),
		}),
		getGHGDetails: builder.query<GHGResponse, DashboardParams>({
			query: (args) => ({
				url: `get-ghg-reduction-for-all?${getExtendedURLParams(args)}`,
			}),
		}),
		getPowerDetails: builder.query<PowerResponse, DashboardParams>({
			query: (args) => ({
				url: `get-power-for-all?${getExtendedURLParams(args)}`,
			}),
		}),
		getActiveStats: builder.query<string[], DashboardParams>({
			query: (args) => ({
				url: `get-active-stats?${getGenericURLParams(args)}`,
			}),
		}),
		getInactiveStats: builder.query<string[], DashboardParams>({
			query: (args) => ({
				url: `get-inactive-stats?${getGenericURLParams(args)}`,
			}),
		}),
		saveStats: builder.mutation<void, DashboardParams>({
			query: (data) => {
				const { userName, activeStats, inactiveStats } = data;
				return {
					url: `save-stats?userName=${userName}`,
					method: "PUT",
					body: { activeStats: activeStats, inactiveStats: inactiveStats }
				};
			},
			invalidatesTags: ["SummaryStats"]
		})
	}),
});

export const {
	useGetEnergyDetailsQuery,
	useGetTransactionDetailsQuery,
	useGetGHGDetailsQuery,
	useGetRevenueDetailsQuery,
	useGetUtilizationDetailsQuery,
	useGetSummaryStatsQuery,
	useGetConnectorSummaryQuery,
	useGetActiveStatsQuery,
	useGetInactiveStatsQuery,
	useSaveStatsMutation,
	useGetPowerDetailsQuery
} = dashboardApi;
