import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "common/components/Container/Container";
import { emailToUsername, getTabItems} from "common/utils";
import { useGetOperatorProfileQuery } from "services/profileService";
import { RootState } from "redux/store";
import { Tabs } from "antd";
import { tabPanelsProfile } from "common/constants";
import TabProfile from "./components/TabProfile/TabProfile";
import TabNotifications from "./components/TabNotifications/TabNotifications";
import TabPasswordAndSecurity from "./components/TabPasswordAndSecurity/TabPasswordAndSecurity";
import "./Profile.less";
import { profileApi } from "services/profileService";

type Props = {
	shouldRefresh?: boolean;
	tabProfile: string;
	setTabProfile: (value: string) => void;
};

const handleGetTabContent = (tabProfile: string, shouldRefresh: boolean | undefined) => {
	switch (tabProfile) {
	case "1": return <TabProfile shouldRefresh={shouldRefresh} />;
	case "2": return <TabNotifications />;
	case "3": return <TabPasswordAndSecurity />;
	}
};

const Profile = ({shouldRefresh, tabProfile, setTabProfile}: Props) => {

	const { email } = useSelector((state: RootState) => state.authenticationReducer);
	const userName = emailToUsername(email);
	const {isSuccess } = useGetOperatorProfileQuery(userName);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			profileApi.endpoints.getOperatorProfile.initiate(
				userName,
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [ shouldRefresh, dispatch, userName ]);


	return (
		<Container
			renderCondition={isSuccess}
			loadingPageName='profile'
			backButton={false}
			editButton={true}
		>
			<div className='profile-container'>
				<Tabs
					tabPosition='bottom'
					onChange={setTabProfile}
					tabIndex={Number(tabProfile)}
					tabBarStyle={{ marginTop: 0 }}
					destroyInactiveTabPane
					style={{height:"10%"}}
					activeKey={tabProfile}
				>
					{getTabItems(tabPanelsProfile)}
				</Tabs>
				<div className='profile-main-section'>
					{handleGetTabContent(tabProfile, shouldRefresh)}
				</div>
			</div>

		</Container>
	);
};

export default Profile;
