/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Menu } from "antd";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const getFullName = (firstName: string, lastName: string) =>
	`${firstName} ${lastName}`;

export const emailToUsername = (email: string) => email.replace("@", "-at-");
export const usernameToEmail = (userName: string) => userName.replace("-at-", "@");

export const getMenuItems = (items: any[]) => (
	items.map(item =>
		<Menu.Item key={item.key} icon={item?.icon || undefined}>
			{item.name}
		</Menu.Item>
	)
);

export const getTabItems = (tabs: any[]) => (
	tabs.map(tab => <TabPane key={tab.key} tab={tab.name} />)
);

export const getUpperCase = (text: string) => {
	const splitText = text.split(" ");
	const upperCased = splitText
		.reduce((previous, current) => (
			previous +
			current[0].toUpperCase() +
			(current.length > 1 ? current.slice(1) : "") + " "
		), "");
	return upperCased.trim();
};

export const capitalizeFirstLetter = (text: string) =>
	text ? text[0].toUpperCase() + text.slice(1) : "";
