/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { dashboardApi, useGetUtilizationDetailsQuery } from "services/dashboardService";
import { formatData } from "./utils/functions";
import { chartConfig } from "../../constants";
import { GenericResponse } from "services/utils/interfaces";
import { utilizationResult } from "./utils/types";
import { useDispatch } from "react-redux";
import { stationsApi, useGetUtilizationForStationQuery } from "services/stationsService";
import { emailToUsername } from "common/utils";

type Props = {
  params: {
    userName: any;
    startDate: string;
    endDate: string;
    locationId?: string;
  };
  shouldRefresh?: boolean;
};

const Utilization = ({ params, shouldRefresh }: Props) => {
	const [data, setData] = useState<any[]>([]);
	const dispatch = useDispatch();
	const { locationId, endDate, userName } = params;

	const utilizationDashboard = useGetUtilizationDetailsQuery(params, { skip: locationId ? true : false }).data as GenericResponse;
	const utilizationStation = useGetUtilizationForStationQuery(
		{ ...params, userName: emailToUsername(params.userName), locationId: locationId || "" },
		{ skip: locationId ? false : true }
	).data as GenericResponse;

	useEffect(() => {
		if (locationId) {
			dispatch(
				stationsApi.endpoints.getUtilizationForStation.initiate(
					{ ...params, locationId: locationId || "" },
					{ subscribe: false, forceRefetch: true }
				)
			);
		} else {
			dispatch(
				dashboardApi.endpoints.getUtilizationDetails.initiate(
					{ userName: params.userName, startDate: params.startDate, endDate: params.endDate },
					{ subscribe: false, forceRefetch: true }
				)
			);
		}
	}, [shouldRefresh, dispatch, userName, locationId, endDate]);

	useEffect(() => {
		if (locationId) {
			if (utilizationStation)
				setData(formatData(utilizationStation, "utilization") as utilizationResult[]);
		} else {
			if (utilizationDashboard)
				setData(formatData(utilizationDashboard, "utilization") as utilizationResult[]);
		}
	}, [utilizationDashboard, utilizationStation, locationId]);
	return (
		<Column
			{...{
				...chartConfig["vertical-bar"],
				data: data,
				xField: "time",
				yField: "sessions",
			}}
		/>
	);
};

export default Utilization;
