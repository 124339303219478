import useWindowDimensions from "common/functions";
import i18n from "internationalization/i18n";
import React from "react";
import "./ExportType.less";

type Props = {
    exportTypeClass : {all:string,station:string};
    changeExportType: (type:string) => void;
};

const ExportType:React.FC<Props> = ({changeExportType, exportTypeClass}) => {

    const { width } = useWindowDimensions();

    const getExportTypeDesktop = () => (
        <div className="export-modal-type">
            <h4>{i18n.t("exportModal.exportType")}</h4>
            <div className="export-section-container">
                <div className={exportTypeClass.all}>
                    <label htmlFor="radio-type-1" onClick={()=> changeExportType("all")}>{i18n.t("exportModal.transactionAllStations")}</label>
                    <input id="radio-type-1" name="type" type="radio"/>
                </div>
                <div className={exportTypeClass.station} >
                    <label htmlFor="radio-type-2" onClick={()=> changeExportType("station")}>{i18n.t("exportModal.transactionSpecificStation")}</label>
                    <input id="radio-type-2" name="type" type="radio"/>
                </div>
            </div>
        </div>
    );

    const getExportTypeMobile = () => (
        <div className="export-modal-type">
            <h2>{i18n.t("exportModal.exportType")}</h2>
            <div className="export-section-container">
                <div className={exportTypeClass.all}>
                    <label htmlFor="radio-type-1" onClick={() => changeExportType("all")}>{i18n.t("exportModal.transactionAllStations")}</label>
                    <input id="radio-type-1" name="type" type="radio"/>
                </div>
                <div className={exportTypeClass.station} >
                    <label htmlFor="radio-type-2" onClick={() => changeExportType("station")}>{i18n.t("exportModal.transactionSpecificStation")}</label>
                    <input id="radio-type-2" name="type" type="radio"/>
                </div>
            </div>
        </div>
    );
  return <>
      { width > 768 ? getExportTypeDesktop() : getExportTypeMobile() }
      </>;
};

export default ExportType;