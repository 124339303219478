import {
	isRejectedWithValue,
	Middleware,
} from "@reduxjs/toolkit";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

export const rtkQueryErrorLogger: Middleware =
	() => (next) => (action) => {
		if (isRejectedWithValue(action)) {
			if (action.payload.data) {
				if (action.payload.status === 401) {
					const navigate = useNavigate();
					navigate("/");
				}
				notification.error({
					message: "Status: " + action.payload.status + " - " + action.payload.data.message,
					placement: "bottom",
					duration: 3,
				});
			} else {
				notification.error({
					message: "Status: " + action.payload.status + " - " + action.payload.error,
					placement: "bottom",
					duration: 3,
				});
			}
		}

		return next(action);
	};
