import { useState, useEffect } from "react";
import WebMercatorViewport, {
	Bounds,
	ViewportProps,
} from "viewport-mercator-project";
import { getBounds } from "../utils/mapUtils";
import { IMarker } from "redux/utils/interfaces";

interface UseMapProps {
  width: number;
  height: number;
  markers: IMarker[];
}
export const useMap = ({ width, height, markers }: UseMapProps) => {
	const [viewport, setViewport] = useState({
		width: width || 400,
		height: height || 400,
	});

	useEffect(() => {
		if (width && height && markers.length) {
			const MARKERS_BOUNDS = getBounds(markers);
			setViewport((viewport) => {
				const nextViewport = new WebMercatorViewport({
					...(viewport as WebMercatorViewport),
					width,
					height,
				}).fitBounds(MARKERS_BOUNDS as Bounds, {
					padding: 100,
				});

				return nextViewport;
			});
		}
	}, [width, height, markers]);

	const onViewportChange = (nextViewport: ViewportProps) =>
		setViewport(nextViewport);

	return {
		onViewportChange,
		viewport,
	};
};
