import React from "react";
import "./GrayButton.less";
import {Button, ButtonProps} from "antd";

const GrayButton: React.FC<ButtonProps> = (props) => {
	return (
		<Button
			onClick={props.onClick}
			className={`gray-button ${props.className}`}
			icon={props.icon}
			style={props.style}
			disabled={props.disabled}
		>
			{" "}
			{props.name}{" "}
		</Button>
	);
};

export default GrayButton;
