import { Pie } from "@ant-design/plots";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import { dashboardApi } from "services/dashboardService";
import { decodeJWT } from "common/functions";
import "./Connectors.less";
import { emailToUsername } from "common/utils";

type Props = {
  title?: string;
  connectorsData: { type: string, value: number }[];
  shouldRefresh?: boolean;
};

const Connectors: React.FC<Props> = ({ title, shouldRefresh, connectorsData }) => {

	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector(
		(state: rootReducerType) => state.authenticationReducer
	);
	const params = {
		userName: emailToUsername(decodeJWT(isLoggedIn).email),
	};

	useEffect(() => {
		dispatch(
			dashboardApi.endpoints.getConnectorSummary.initiate(
				{ userName: params.userName },
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [ shouldRefresh, dispatch, params.userName ]);

	const legendColors = [
		"inherit",
		"#9DCC78",
		"#E088FF",
		"#FED077",
		"#CECECE",
		"#FF7866",
	];

	const config = {
		appendPadding: 10,
		padding: 15,
		data: connectorsData.slice(1),
		angleField: "value",
		colorField: "type",
		color: legendColors.slice(1),
		label: {
			type: "inner",
			style: {
				fontSize: 0,
			},
		},
		radius: 1,
		interactions: [
			{
				type: "element-active",
			},
		],
	};

	return (
		<section className="pie-container">
			<div className="title">{title}</div>
			<section className="pie-details">
				<section className="pie-legend">
					{connectorsData.map((obj, index) => {
						return (
							<div className="legend-item" key={index}>
								<div
									className="item-color"
									style={{ backgroundColor: legendColors[index] }}
								></div>
								<div className="item-title">{obj.type}</div>
								<div className="item-value">{obj.value}</div>
							</div>
						);
					})}
				</section>
				<Pie {...config} style={{ width: "50%" }} legend={false} />
			</section>
		</section>
	);
};

export default Connectors;
