import { GenericResponse } from "services/utils/interfaces";

export const formatData = (data: GenericResponse | undefined) => {
	const result: { type: string; value: number }[] = [];

	if (data)
		Object.entries(data).forEach(([key, value]) => {
			if (key === "inUse") key = "In use";
			if(key ==="Total") {
				result.unshift({
					type: key.charAt(0).toUpperCase() + key.slice(1),
					value: value,
				});
			}
			else {
				result.push({
					type: key.charAt(0).toUpperCase() + key.slice(1),
					value: value,
				});
			}
		});
	return result;
};
