import React, { useCallback, useState } from "react";
import { rootReducerType } from "redux/combineReducers";
import { useSelector } from "react-redux";
import { Tabs, DatePicker } from "antd";
import { chartTabPanelsDetails, OneWeekInMilliseconds } from "common/constants";
import moment from "moment";
import { decodeJWT } from "common/functions";
import TransEnergy from "../Chart/TransEnergy";
import Utilization from "../Chart/Utilization";
// import Reductions from "../Chart/Reductions";
import "./Details.less";
import { emailToUsername } from "common/utils";
import Power from "../Chart/Power";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const getTabPanels = (target: string) => {
  if(target === "station")
    return chartTabPanelsDetails.map((tabPanel) => (
      <TabPane tab={tabPanel.name} tabKey={tabPanel.key} key={tabPanel.key} />
      ));
  else if(target === "all")
    return chartTabPanelsDetails.slice(0,-1).map((tabPanel) => (
      <TabPane tab={tabPanel.name} tabKey={tabPanel.key} key={tabPanel.key} />
    ));
    };

type Props = {
  locationId?: string;
  width: number;
  shouldRefresh?: boolean
};

const Details: React.FC<Props> = ({ locationId, width , shouldRefresh}) => {
  const { isLoggedIn } = useSelector(
    (state: rootReducerType) => state.authenticationReducer
  );

  const [params, setParams] = useState({
    userName: emailToUsername(decodeJWT(isLoggedIn).email),
    startDate: moment(
      new Date(Date.now() - OneWeekInMilliseconds)
        .toISOString(),
      "YYYY-MM-DD"
    ).startOf("day").format(),
    endDate: moment(new Date()).endOf("day").format(),
    locationId,
  });
  const [tabIndex, setTabIndex] = useState<string>("1");

  const handleChangeTab = (key: string) => {
    const panelDetails = chartTabPanelsDetails.find(
      (tabPanel) => tabPanel.key === key
    );
    if (panelDetails) {
      setTabIndex(key);
    }
  };

	const onChange = (newDates) => {
		const newStartDate = moment(newDates[0]).startOf("day").format().toString();
		const newEndDate =  moment(newDates[1]).endOf("day").format().toString();
		setParams(prevParams => ({
			...prevParams,
			startDate: prevParams.startDate === newStartDate ? prevParams.startDate : newStartDate,
			endDate: prevParams.endDate === newEndDate ? prevParams.endDate : newEndDate
		}));
	};

	const getChart = useCallback(() => {

		return !locationId ? (tabIndex === "1" ? (
			<TransEnergy params={params} shouldRefresh={shouldRefresh} />
		) : tabIndex === "2" ? (
			<Utilization params={params} shouldRefresh={shouldRefresh} />
		) : null)
			: (tabIndex === "1" ? (
				<TransEnergy params={params} shouldRefresh={shouldRefresh} />
			) : tabIndex === "2" ? (
				<Utilization params={params} shouldRefresh={shouldRefresh} />
			) : tabIndex === "3" ? (
				<Power params={params} shouldRefresh={shouldRefresh} />
			) : null);
	},[params.userName, params.endDate, params.locationId, tabIndex]);

  return (
    <section className="details-container">
      {width > 768 ? (
        <div className="details-header">
          <Tabs
            style={{ paddingTop: 5 }}
            tabPosition="top"
            onChange={handleChangeTab}
            tabIndex={Number(tabIndex)}
            tabBarStyle={{ marginTop: 0 }}
            size="small"
          >
            { locationId ? getTabPanels("station") : getTabPanels("all")}
          </Tabs>
          <RangePicker
            style={{ width: "30%", border: "0" }}
            format="YYYY-MM-DD"
            suffixIcon
            defaultValue={[
              moment(
                new Date(Date.now() - OneWeekInMilliseconds)
                  .toISOString()
                  .slice(0, 10),
                "YYYY-MM-DD"
              ),
              moment(new Date().toISOString().slice(0, 10), "YYYY-MM-DD"),
            ]}
            onChange={(value, dateString) => onChange(dateString)}
          />
        </div>
      ) : (
        <>
          <Tabs
            activeKey={tabIndex}
            tabPosition="bottom"
            onChange={handleChangeTab}
            tabIndex={Number(tabIndex)}
            tabBarStyle={{ marginTop: 0 }}
            destroyInactiveTabPane
            style={{ height: "10%" }}
          >
            { locationId ? getTabPanels("station") : getTabPanels("all")}
          </Tabs>
          <RangePicker
            format="YYYY-MM-DD"
            suffixIcon
            style={{
              width: "250px",
              alignSelf: "center",
              height: "5%",
              border: "0",
            }}
            defaultValue={[
              moment(
                new Date(Date.now() - OneWeekInMilliseconds)
                  .toISOString()
                  .slice(0, 10),
                "YYYY-MM-DD"
              ),
              moment(new Date().toISOString().slice(0, 10), "YYYY-MM-DD"),
            ]}
            onChange={onChange}
          />
        </>
      )}
      <div className="details-chart">
				{getChart()}
      </div>
    </section>
  );
};

export default Details;
