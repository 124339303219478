/* eslint-disable @typescript-eslint/no-explicit-any */
import Dashboard from "pages/dashboard/Dashboard";
import Authentication from "pages/authentication/Authentication";
import Success from "pages/authentication/components/Success/Success";
import Station from "pages/stations/Station";
import Stations from "pages/stations/Stations";
import Chargers from "pages/chargers/Chargers";
import Users from "pages/users/Users";
import Transactions from "pages/transactions/Transactions";
import Pricing from "pages/pricing/Pricing";
import Profile from "pages/profile/Profile";
import Support from "../pages/support/Support";
import CompareStations from "pages/stations/CompareStations";
import Charger from "pages/chargers/Charger";
import VerifyEmail from "pages/authentication/components/VerifyEmail/VerifyEmail";
import ResetPassword from "pages/authentication/components/ResetPassword/ResetPassword";

interface IRoute {
  path: string;
  name: string;
  element: any;
  private: boolean;
  disabled?: boolean;
}

export const routes: IRoute[] = [
	{
		path: "/",
		name: "authentication",
		element: Authentication,
		private: false,
	},
	{
		path: "/verify-email",
		name: "verifyEmail",
		element: VerifyEmail,
		private: false,
	},
	{
		path: "/reset-password",
		name: "resetPassword",
		element: ResetPassword,
		private: false,
	},
	{
		path: "/success",
		name: "success",
		element: Success,
		private: false,
	},
	{
		path: "/stations",
		name: "stations",
		element: Stations,
		private: true,
	},
	{
		path: "/stations/:locationId",
		name: "stations",
		element: Station,
		private: true,
	},
	{
		path: "/stations/compare/:stations",
		name: "stations",
		element: CompareStations,
		private: true,
	},
	{
		path: "/stations/compare/:stations/:locationId",
		name: "stations",
		element: Station,
		private: true,
	},
	{
		path: "/chargers",
		name: "chargers",
		element: Chargers,
		private: true,
	},
	{
		path: "/chargers/:chargerId",
		name: "chargers",
		element: Charger,
		private: true,
	},
	{
		path: "/users",
		name: "users",
		element: Users,
		private: true,
		disabled: true
	},
	{
		path: "/transactions",
		name: "transactions",
		element: Transactions,
		private: true,
	},
	{
		path: "/pricing",
		name: "pricing",
		element: Pricing,
		private: true,
		disabled: true
	},
	{
		path: "/dashboard",
		name: "dashboard",
		element: Dashboard,
		private: true,
	},
	{
		path: "/profile",
		name: "profile",
		element: Profile,
		private: true,
	},
	{
		path: "/support",
		name: "support",
		element: Support,
		private: true,
	},
];
