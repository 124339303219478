import React, { useState } from "react";
import { Table } from "antd";
import { computeRowsAndColsForTransactions } from "../../utils/functions";
import { ChargerTransactions } from "pages/chargers/Charger";

interface ITable {
	data: ChargerTransactions
	setNewSort: (value: string) => void;
	offset: number;
	setOffset: (value: number) => void;
	limit: number;
	length: number;
}

const ChargersTransactionsTable: React.FC<ITable> = ({
	data,
	setNewSort,
	offset,
	setOffset,
	limit,
	length
}) => {

	const [isAsc, setIsAsc] = useState({
		startTime: false,
		endTime: false,
		length: false,
		energy: false,
	});
	const [isDesc, setIsDesc] = useState({
		startTime: false,
		endTime: false,
		length: false,
		energy: false,
	});

	const { columns, rows } = computeRowsAndColsForTransactions(
		data,
		(value) => setNewSort(value),
		isAsc,
		isDesc,
		(value) => setIsAsc(value),
		(value) => setIsDesc(value),
	);

	return (
		<Table columns={columns} dataSource={rows} size='small'
			scroll={{ x: 500 }}
			pagination={{
				total: length,
				current: offset + 1,
				onChange: (page) => setOffset(page - 1),
				defaultPageSize: limit,
				showSizeChanger: false
			}}
			bordered />
	);
};

export default ChargersTransactionsTable;
