import React, { ReactElement, useEffect } from "react";
import { uniqueId } from "lodash";
import {ReactComponent as PlusButton} from "assets/plus-button.svg";
import InfoCard from "pages/dashboard/components/InfoCard/InfoCard";
import "./CustomiseDashboard.less";
import { Button } from "antd";
import { useSaveStatsMutation } from "services/dashboardService";
import { useSelector } from "react-redux";
import { rootReducerType } from "redux/combineReducers";
import { decodeJWT } from "common/functions";
import i18n from "internationalization/i18n";
import { emailToUsername } from "common/utils";
import Notification from "../NotificationMessage/Notification";

type Props = {
    activeStats?:string[];
    inactiveStats?:string[];
  expanded?: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
    setInactiveStats:React.Dispatch<React.SetStateAction<string[]|undefined>>
    setActiveStats:React.Dispatch<React.SetStateAction<string[]|undefined>>
};

const CustomiseDashboard:React.FC<Props> = ({activeStats, inactiveStats, setInactiveStats, setActiveStats, expanded, setExpanded}) :ReactElement => {

  const [saveStats, { isSuccess }] = useSaveStatsMutation();

  const { isLoggedIn } = useSelector(
    (state: rootReducerType) => state.authenticationReducer
  );

  const params = {
    userName: emailToUsername(decodeJWT(isLoggedIn).email),
    activeStats: activeStats,
    inactiveStats: inactiveStats
  };

  useEffect(() => {
  if(isSuccess)
  {
    Notification({ type: "success", message: i18n.t("dashboard.toastTitle"), description: i18n.t("dashboard.toastCustomisationSave")});
    if(setExpanded) setExpanded(!expanded);
  }
  },[isSuccess]);

  const addStat = (index:number) => {
    if(inactiveStats)
      setInactiveStats(inactiveStats.filter((_,i) => i !=index));
    if(activeStats && inactiveStats)
      setActiveStats([...activeStats,inactiveStats[index]]);
  };

  return (
    <section className="expanded-main-section">
      <div className="expanded-header">
        <h1>{i18n.t("dashboard.customise")}</h1>
        <Button
        size="small"
        onClick={ () => {
        saveStats(params);
        }}>{i18n.t("dashboard.save")}</Button>
      </div>
      <section className="expanded-inactive-stats">
        {inactiveStats && inactiveStats.map((stat, index) => {
          if (stat)
            return (
              <div className="summary-card-container" key={uniqueId()}>
                <div className="action-image" onClick={()=>addStat(index)}>
                  <PlusButton fill="white"/>
                </div>
                <InfoCard
                  description={stat}
                  simpleCard={true}
                />
              </div>
            );
          else return null;
        })}
      </section>
    </section>
  );
};

export default CustomiseDashboard;
