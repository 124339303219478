import React, {useEffect, useState} from "react";
import "./TabProfile.less";
import { Form, Input } from "antd";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "redux/store";
import {profileApi, useGetOperatorProfileQuery, useSaveOperatorProfileMutation} from "services/profileService";
import { emailToUsername } from "common/utils";
import { UserOutlined } from "@ant-design/icons";
import { Rule } from "antd/lib/form";
import GrayButton from "../../../../common/components/StyledButtons/GrayButton/GrayButton";
import DarkBlueButton from "../../../../common/components/StyledButtons/DarkBlueButton/DarkBlueButton";
import i18n from "internationalization/i18n";
import Notification from "common/components/NotificationMessage/Notification";

const inputsDetailsFirstRow = [
	{ label: "First Name", property: "firstName", rules: [{ type: "string", required: true, message: "First Name required." }] as Rule[] },
	{ label: "Last Name", property: "lastName", rules: [{ type: "string", required: true, message: "Last Name required." }] as Rule[] },
];

const inputsDetails = [
	{ label: "Email Address", property: "email", rules: [{ type: "email", required: true, message: "Invalid email." }] as Rule[] },
	{ label: "Contact Number", property: "contactNumber", rules: [] as Rule[] },
	{ label: "Address", property: "address", rules: [] as Rule[] },
];

const inputsDetailsLastRow = [
	{ label: "City", property: "city", rules: [] as Rule[] },
	{ label: "State", property: "state", rules: [] as Rule[] },
];

type Props = {
	shouldRefresh?: boolean;
};

const TabProfile: React.FC<Props> = ({ shouldRefresh }: Props): React.ReactElement => {

	const { email } = useSelector((state: RootState) => state.authenticationReducer);
	const userName = emailToUsername(email);
	const data = { ...useGetOperatorProfileQuery(emailToUsername(email)).data, email };
	const [saveOperatorProfile, {isSuccess}] = useSaveOperatorProfileMutation();

	const [profile, setProfile] = useState({ ...data, email });
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			profileApi.endpoints.getOperatorProfile.initiate(
				userName,
				{ subscribe: false, forceRefetch: true }
			)
		);
	}, [ shouldRefresh, dispatch, userName ]);

	useEffect(() => {
	if(isSuccess)
		Notification({ type: "success", message: i18n.t("profile.toastTitle"), description: i18n.t("profile.toastProfileSave")});
	},[isSuccess]);

	const handleSetProfile = (newText: string, property: string) => {
		setProfile((prevProfile: typeof profile) => {
			prevProfile[property] = newText;
			return { ...prevProfile };
		});
	};

	const getFormItems = (inputsDetails: { label: string, property: string, rules: Rule[] }[]) => (
		inputsDetails.map(input =>
			<Form.Item label={input.label} rules={input.rules} key={input.property} name={input.property} >
				<Input
					style={{fontSize:"12px"}}
					value={profile[input.property]}
					onChange={event => handleSetProfile(event.target.value, input.property)}
				/>
			</Form.Item>
		)
	);


	return (
		<div className='tab-profile'>
			<Form
				form={form}
				layout='vertical'
				fields={
					[...inputsDetailsFirstRow, ...inputsDetails, ...inputsDetailsLastRow]
						.map(input => ({ name: [input.property], value: profile[input.property] }))
				}
				onFinish={(profile) => saveOperatorProfile(profile)}
				onValuesChange={() => {
					form.validateFields().catch(err => setDisableSubmit(err.errorFields.length));
				}}
			>
				<div className='tab-profile-main-section'>
					<div className='photo-container'>
						<div className='profileImage'>
							<UserOutlined />							
						</div>
					</div>
					<div className='inputs-container'>
						<div className='inputs-row'>
							{getFormItems(inputsDetailsFirstRow)}
						</div>
						{getFormItems(inputsDetails)}
						<div className='inputs-row'>
							{getFormItems(inputsDetailsLastRow)}
						</div>
					</div>
				</div>
				<div className='tab-profile-buttons-section'>
					<GrayButton style={{ backgroundColor: "white" }} name={"Cancel"} />
					<DarkBlueButton
						style={{ margin: 16, marginLeft: 8 }}
						htmlType='submit'
						disabled={
							JSON.stringify(data) === JSON.stringify(profile)
							|| !form.isFieldsTouched() 
							|| disableSubmit
						}
						name={"Save"} />
				</div>
			</Form>
		</div>
	);
};

export default TabProfile;