import { createApi } from "@reduxjs/toolkit/query/react";
import { chargerActionType } from "redux/utils/types";
import { baseQueryWithReauth } from "./utils/constants";
import {
	ChargersParams,
	ChargersResponse,
	Charger,
	ChargerInfoResponse,
	ChargerTransactionResponse,
	ChargerTransaction
} from "./utils/interfaces";
import moment from "moment";

const getUrlParams = (args) => {
	if (!args.sortBy && !args.filterBy) {
		return `userName=${args.userName}&limit=${args.limit}&offset=${args.offset}`;
	} else if (args.sortBy && !args.filterBy) {
		return `userName=${args.userName}&limit=${args.limit}&sortBy=${args.sortBy}&offset=${args.offset}`;
	} else if (!args.sortBy && args.filterBy) {
		return `userName=${args.userName}&limit=${args.limit}&offset=${args.offset}&filterBy=${args.filterBy}`;
	} else if (args.sortBy && args.filterBy) {
		return `userName=${args.userName}&limit=${args.limit}&sortBy=${args.sortBy}&offset=${args.offset}&filterBy=${args.filterBy}`;
	}
	return `userName=${args.userName}&limit=${args.limit}&offset=${args.offset}`;
};

const getUrlParamsForTransactions = (args) => {
	if (!args.sortBy) {
		return `userName=${args.userName}&locationId.stationId=${args.locationIdStationId}&limit=${args.limit}&offset=${args.offset}&chargerId=${args.chargerId}`;
	}
	return `userName=${args.userName}&locationId.stationId=${args.locationIdStationId}&limit=${args.limit}&sortBy=${args.sortBy}&offset=${args.offset}&chargerId=${args.chargerId}`;
};

export const chargersApi = createApi({
	reducerPath: "chargers",
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		getChargers: builder.query<ChargersResponse, ChargersParams>({
			query: (args) => ({
				url: `get-chargers?${getUrlParams(args)}`,
				keepUnusedDataFor: 0
			}),
		}),
		getChargerInfo: builder.query<ChargerInfoResponse, Charger>({
			query: (args:Charger) => ({
				url: `get-charger-info?userName=${args.userName}&locationId.stationId=${args.locationIdStationId}&chargerId=${args.chargerId}&currentTime=${moment(new Date()).format()}`,
			}),
		}),
		getChargerTransactions: builder.query<ChargerTransactionResponse, ChargerTransaction>({
			query: (args:ChargerTransaction) => ({
				url: `get-charger-transactions?${getUrlParamsForTransactions(args)}&currentTime=${moment(new Date()).format()}`,
			}),
		}),
		startCharger: builder.mutation<chargerActionType, Charger>({
			query: (data) => {
				const {userName, locationIdStationId, chargerId} = data;
				return {
					url: "start-charger",
					method: "PUT",
					body: {"userName": userName, "locationId.stationId": locationIdStationId, "chargerId": chargerId}
				};
			}
		}),
		rebootCharger: builder.mutation<chargerActionType, Charger>({
			query: (data) => {
				const {userName, locationIdStationId, chargerId} = data;
				return {
					url: "restart-charger",
					method: "PUT",
					body: {"userName": userName, "locationId.stationId": locationIdStationId, "chargerId": chargerId}
				};
			}
		}),
		stopCharger: builder.mutation<chargerActionType, Charger>({
			query: (data) => {
				const {userName, locationIdStationId, chargerId} = data;
				return {
					url: "stop-charger",
					method: "PUT",
					body: {"userName": userName, "locationId.stationId": locationIdStationId, "chargerId": chargerId}
				};
			}
		})
	}),
});

export const {
	useGetChargersQuery,
	useGetChargerInfoQuery,
	useGetChargerTransactionsQuery,
	useStartChargerMutation,
	useRebootChargerMutation,
	useStopChargerMutation,
} = chargersApi;
