import React from "react";
import Map from "../Map/Map";
import "./StationMap.less";
import { stations } from "pages/stations/utils/types";

type Props = {
  data: stations[];
};

const StationMap = ({ data }: Props) => {
	return (
		<section className="station-map-container">
			<div className="station-map">
				<Map markers={data}></Map>
			</div>
		</section>
	);
};

export default StationMap;
